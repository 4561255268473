export const scrollContainerSx = {
  maxHeight: '420px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#e3e3e3',
    borderRadius: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#996c84',
    borderRadius: '6px',
  },
};

export const filterHeaderStyle = {
  fontWeight: 'bold',
  backgroundColor: '#88305F14',
  padding: '10px',
  borderBottom: '1px solid gray',
};
