import React from 'react';

import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

import DraftHeader from './DraftHeader';

const DraftLayout = () => {
  return (
    <Box>
      <DraftHeader canDownload={true} goBackUrl="/contracts" />
      <Box
        sx={{
          padding: '10px 15px 0',
          overflow: 'auto',
          height: '84vh',
        }}
      >
        <main>
          <Outlet />
        </main>
      </Box>
    </Box>
  );
};

export default DraftLayout;
