import React from 'react';

import { Box, Stack } from '@mui/material';

export default function BarLoader() {
  return (
    <Stack
      direction="row"
      sx={{ justifyContent: 'center', alignItems: 'center' }}
      className="bar-loader"
    >
      <Box className="loader-bar"></Box>
      <Box className="loader-bar"></Box>
      <Box className="loader-bar"></Box>
      <Box className="loader-bar"></Box>
      <Box className="loader-bar"></Box>
      <Box className="loader-bar"></Box>
      <Box className="loader-bar"></Box>
      <Box className="loader-bar"></Box>
    </Stack>
  );
}
