/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import TableChartIcon from '@mui/icons-material/TableChart';
import TableRowsIcon from '@mui/icons-material/TableRows';
import {
  Chip,
  LinearProgress,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import ClauseTypeFilter from './components/ClauseTypeFilter';
import { getClauseType, getInsightsES, getSearchResult } from '../../axiosClient/search';
import ListSkeleton from '../../components/ListSkeleton';
import FilterBg from '../../Icons/FilterBg';
import FilterCardView from '../Filter/CardView';
import DownloadExcel from '../Filter/components/DownloadExcel';
import FilterTableView from '../Filter/FilterTableView';
import { scrollContainerSx } from '../Filter/StaticData';

const Search = () => {
  const { query } = useParams();
  const navigate = useNavigate();
  const methods = useForm();
  const { control, watch, setValue } = methods;
  const [searchData, setSearchData] = useState<any>();
  const [view, setView] = useState('card');
  const [clauseTypeData, setClauseTypeData] = useState<any>();

  const clause_type = watch('clause_type');
  const lender_details = watch('lender_details') || null;
  const contract_type = watch('contract_type') || null;
  const groups = watch('groups') || null;
  const projects = watch('projects') || null;
  const borrower_details = watch('borrower_details') || null;

  const searchTerm = useMemo(
    () => (query ? JSON.parse(atob(query)) : { search: [] }),
    [query]
  );

  const { data: ClauseTypeMap } = useQuery({
    queryKey: ['clause_type_mapping'],
    queryFn: getClauseType,
    select: (response: any) => response.results,
  });

  const showSearchResult = useMemo(
    () =>
      searchTerm.search.length ||
      contract_type?.length ||
      groups?.length ||
      projects?.length ||
      borrower_details?.length ||
      lender_details?.length ||
      clause_type?.length,
    [
      searchTerm,
      contract_type,
      borrower_details,
      lender_details,
      groups,
      projects,
      clause_type,
    ]
  );

  const { data, isLoading, isFetching, isSuccess } = useQuery({
    queryKey: [
      'search-result',
      searchTerm,
      clause_type,
      borrower_details,
      lender_details,
      contract_type,
      groups,
      projects,
    ],
    queryFn: () => {
      const clauseTypes = clause_type?.map((data: any) => data.index) || [];

      return getSearchResult({
        ...searchTerm,
        ...(clauseTypes.length > 0 && {
          indexes: clauseTypes,
        }),
        ...(groups?.length > 0 && { groups: groups }),
        ...(projects?.length > 0 && { projects: projects }),
        ...(contract_type?.length > 0 && { contract_type: contract_type }),
        ...(borrower_details?.length > 0 && { borrower_details: borrower_details }),
        ...(lender_details?.length > 0 && { lender_details: lender_details }),
      });
    },
  });

  useEffect(() => {
    if (data && isSuccess) {
      setSearchData(data);
    }
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      setView('card');
      if (!showSearchResult) {
        navigate('/filter');
      }
    }
  }, [searchData]);

  useEffect(() => {
    if (isSuccess) {
      const clauses: any = searchData ? [] : null;
      searchData?.map((data: any) => {
        data.clause.map((clause: any) => {
          const index = clauses.findIndex(
            (item: any) =>
              item.name?.toLowerCase().trim() === clause.name?.toLowerCase().trim() &&
              item.display_name?.toLowerCase().trim() ===
                clause.display_name?.toLowerCase().trim()
          );
          if (index === -1) {
            const newClause = {
              name: clause.name,
              display_name: clause.display_name,
              parent: clause.parent,
              checked: false,
              parent_clause: clause.parent ? clause.name : clause.parent_clause,
              [data.id]: clause.data,
              count: 1,
            };
            clauses.push(newClause);
          } else {
            clauses[index] = {
              ...clauses[index],
              [data.id]: clause.data,
              count: clauses[index].count + 1,
            };
          }
        });
      });
      const sortedData = clauses?.sort((a: any, b: any) => {
        if (a.parent_clause < b.parent_clause) {
          return -1;
        }
        if (a.parent_clause > b.parent_clause) {
          return 1;
        }
        return 0;
      });
      setClauseTypeData(sortedData);
    }
  }, [searchData, isSuccess]);

  const { data: insightsData, isLoading: insightsLoading } = useQuery({
    queryKey: ['insights-es', searchData, searchTerm],
    queryFn: () => {
      const ids = searchData?.map((item: any) => item.id);
      const payload = {
        ...(ids.length > 0 && { ids: ids || [] }),
        ...(searchTerm.search.length > 0 && searchTerm),
      };
      return getInsightsES(payload);
    },
  });

  const handleSearchTermDelete = (term: string) => {
    let searchQuery = searchTerm;
    const filteredData = searchQuery.search.filter((item: any) => item !== term);
    searchQuery = {
      search: filteredData,
    };
    const url = `/search/${btoa(JSON.stringify(searchQuery))}`;
    navigate(url);
  };

  const handleClauseTypeDelete = (item: string) => {
    const filteredData = clause_type.filter((data: any) => data.clause_name !== item);
    setValue('clause_type', filteredData);
  };

  const handleFilterDelete = (list: any, item: string, clause: string) => {
    const filteredData = list.filter((data: string) => data !== item);
    setValue(clause, filteredData);
  };

  const shouldShowTabs = useMemo(
    () => clauseTypeData?.some((item: any) => item.checked) || false,
    [clauseTypeData]
  );

  const totalCount = useMemo(() => searchData?.length || 0, [searchData, isSuccess]);

  if (isLoading && !searchData) {
    return <ListSkeleton numberOfLines={10} />;
  }
  return (
    <Stack direction="row" gap={2}>
      <Stack
        minWidth="28vw"
        sx={{ ...scrollContainerSx, maxHeight: '83vh', paddingRight: '6px' }}
      >
        <FormProvider {...methods}>
          <form>
            <ClauseTypeFilter
              insightsData={insightsData}
              isLoading={insightsLoading}
              control={control}
              ClauseTypeMap={ClauseTypeMap}
            />
          </form>
        </FormProvider>
      </Stack>

      {showSearchResult ? (
        <Stack gap={2} flex={1}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography>
              <strong>{searchData?.length}</strong> results for
            </Typography>
            <DownloadExcel
              totalCount={totalCount}
              page="filter"
              setView={setView}
              clauseTypeData={clauseTypeData}
              setClauseTypeData={setClauseTypeData}
              isButtonEnabled={shouldShowTabs}
              filteredData={searchData}
            />
          </Stack>
          {isFetching && <LinearProgress variant="indeterminate" />}
          <Stack direction="row" gap={1} flexWrap="wrap">
            {searchTerm.search.map((term: string) => (
              <Chip
                key={term}
                label={term}
                variant="outlined"
                color="primary"
                sx={{ fontSize: '13px' }}
                deleteIcon={<CloseIcon fontSize="small" />}
                onDelete={() => handleSearchTermDelete(term)}
                icon={<SearchOutlinedIcon fontSize="small" />}
              />
            ))}
            {contract_type?.map((item: any, index: number) => (
              <Chip
                key={`contract-type-${index}`}
                label={item}
                variant="outlined"
                color="primary"
                sx={{ fontSize: '13px' }}
                onDelete={() => handleFilterDelete(contract_type, item, 'contract_type')}
                deleteIcon={<CloseIcon fontSize="small" />}
                icon={<FilterAltOutlinedIcon fontSize="small" />}
              />
            ))}
            {groups?.map((item: any, index: number) => (
              <Chip
                key={`groups-${index}`}
                label={item}
                variant="outlined"
                color="primary"
                sx={{ fontSize: '13px' }}
                onDelete={() => handleFilterDelete(groups, item, 'groups')}
                deleteIcon={<CloseIcon fontSize="small" />}
                icon={<FilterAltOutlinedIcon fontSize="small" />}
              />
            ))}
            {projects?.map((item: any, index: number) => (
              <Chip
                key={`projects-${index}`}
                label={item}
                variant="outlined"
                color="primary"
                sx={{ fontSize: '13px' }}
                onDelete={() => handleFilterDelete(projects, item, 'project')}
                deleteIcon={<CloseIcon fontSize="small" />}
                icon={<FilterAltOutlinedIcon fontSize="small" />}
              />
            ))}
            {clause_type?.map((clause: any, index: number) => (
              <Chip
                key={`clause-type-${index}`}
                label={clause.label}
                variant="outlined"
                color="primary"
                sx={{ fontSize: '13px' }}
                onDelete={() => handleClauseTypeDelete(clause.clause_name)}
                deleteIcon={<CloseIcon fontSize="small" />}
                icon={<FilterAltOutlinedIcon fontSize="small" />}
              />
            ))}
            {lender_details?.map((item: any, index: number) => (
              <Chip
                key={`lender_details-${index}`}
                label={item}
                variant="outlined"
                color="primary"
                sx={{ fontSize: '13px' }}
                onDelete={() =>
                  handleFilterDelete(lender_details, item, 'lender_details')
                }
                deleteIcon={<CloseIcon fontSize="small" />}
                icon={<FilterAltOutlinedIcon fontSize="small" />}
              />
            ))}
            {borrower_details?.map((item: any, index: number) => (
              <Chip
                key={`borrower_details-${index}`}
                label={item}
                variant="outlined"
                color="primary"
                sx={{ fontSize: '13px' }}
                onDelete={() =>
                  handleFilterDelete(borrower_details, item, 'borrower_details')
                }
                deleteIcon={<CloseIcon fontSize="small" />}
                icon={<FilterAltOutlinedIcon fontSize="small" />}
              />
            ))}
          </Stack>
          {shouldShowTabs && (
            <ToggleButtonGroup
              color="primary"
              value={view}
              exclusive
              onChange={(_, nextView) => setView(nextView)}
            >
              <ToggleButton value="card" aria-label="card-view" sx={{ padding: '5px' }}>
                <TableRowsIcon sx={{ fontSize: '18px' }} />
              </ToggleButton>
              <ToggleButton value="table" aria-label="table-view" sx={{ padding: '5px' }}>
                <TableChartIcon sx={{ fontSize: '18px' }} />
              </ToggleButton>
            </ToggleButtonGroup>
          )}
          {view === 'card' && <FilterCardView searchResultIds={searchData} />}
          {view === 'table' && (
            <FilterTableView clauseTypeData={clauseTypeData} filteredData={searchData} />
          )}
        </Stack>
      ) : (
        <Stack height="70vh" flex={1} alignItems="center" justifyContent="center">
          <FilterBg />
          <Typography>{`${searchData?.length} results found`}</Typography>
          <Typography>Start by searching/Filtering</Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default Search;
