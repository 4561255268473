/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';

import { Box, Stack } from '@mui/material';
import * as changesets from 'json-diff-ts';

import ClauseCard from './ClauseCard';
import ClauseHeader from './ClauseHeader';
import EditFeature from './EditFeature';
import { SentencesData } from '../State/DocumentState';
import { deleteClausePhrase } from '../Utils/ClauseTypeUtils';

interface IProps {
  clauseId: string;
  childData: any;
  clauseName: string;
  sentenceData: SentencesData;
  updateContract: any;
  rawContent: any;
  updatedClauseData: any;
  setActiveClause: Dispatch<
    SetStateAction<{ type: string; index: number } | null | undefined>
  >;
  setEditClause: Dispatch<
    SetStateAction<{ name: string; index: number | string } | null | undefined>
  >;
  onClickHighlight: (key: number, clause: any, childAlias: string) => void;
  handleEditClause: (
    toBeEdited: string,
    key: number | string,
    componentType: string
  ) => void;
  editClause?: { name: string; index: number | string } | null;
  activeClause?: { type: string; index: number } | null;
  sectionType: string;
  buttonLoading: boolean;
}

const ChildInsights: FC<IProps> = ({
  childData,
  clauseName,
  onClickHighlight,
  activeClause,
  setActiveClause,
  sentenceData,
  handleEditClause,
  clauseId,
  updateContract,
  editClause,
  setEditClause,
  rawContent,
  updatedClauseData,
  sectionType,
  buttonLoading,
}) => {
  const deleteClause = useCallback(
    (item: any) => {
      const clause = updatedClauseData[clauseName];
      const clauseData = updatedClauseData[clauseName]?.data?.slice() || [];
      const newData: any = deleteClausePhrase(clauseData, item);
      const updatedData = {
        ...updatedClauseData,
        [clauseName]: {
          ...clause,
          data: newData,
        },
      };
      const diff = changesets.diff(rawContent, updatedData, {
        children: '$index',
      });

      if (diff.length > -1) {
        updateContract({
          id: clauseId,
          diff,
          updatedClauseData: updatedData,
          type: sectionType,
        });
      }
    },
    [rawContent, updatedClauseData, clauseId, clauseName, sectionType]
  );

  const getSubtitle = useCallback((type: string, item: any) => {
    if (item.value === 'cannot_determine') return '';
    switch (type.toLowerCase()) {
      case 'date': {
        return `${item.value}`;
      }
      case 'amount': {
        return `${item.value} ${item.currency}`;
      }
      case 'duration': {
        return `${item.value} ${item.duration}`;
      }
      default: {
        return null;
      }
    }
  }, []);

  return (
    <Box>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
        }}
      >
        {editClause?.name === clauseName && editClause?.index === clauseId ? (
          <EditFeature
            clauseId={clauseId}
            type={childData.type}
            clauseName={clauseName}
            clauseDisplayName={childData.display_name}
            savedInsight={childData.data}
            onClose={() => setEditClause(null)}
            rawContent={rawContent}
            updatedClauseData={updatedClauseData}
            updateContract={updateContract}
            sectionType={sectionType}
            buttonLoading={buttonLoading}
          />
        ) : (
          <ClauseHeader
            title={childData.display_name}
            buttonText={childData.display_name}
            onClick={() => handleEditClause(clauseName, clauseId, childData.type)}
          />
        )}
      </Stack>
      <Box maxHeight="250px" sx={{ overflow: 'auto' }}>
        {childData.data
          ?.filter(
            (data: any) =>
              (data?.value && data.value !== 'cannot_determine') ||
              data.original_context !== ''
          )
          .map((item: any, index: number) => (
            <ClauseCard
              key={`${clauseName}-${index}`}
              onClick={() => {
                setActiveClause({ type: clauseName, index });
                onClickHighlight(index, item, clauseName[0]);
              }}
              subTitle={getSubtitle(childData.type, item)}
              index={index}
              clauseItem={item}
              sentenceData={sentenceData}
              isActive={
                activeClause?.type === clauseName && activeClause?.index === index
              }
              deleteClause={() => deleteClause(item)}
              phraseLevel
              sectionName={clauseName}
            />
          ))}
      </Box>
    </Box>
  );
};

export default ChildInsights;
