import React, { useMemo, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { getProfile } from '../axiosClient/authApi';
import LoadingAnim from '../components/Loader/LoadingAnim';
import { getLocalStorage } from '../hooks/authentication';
import { IUser } from '../interfaces/IUser';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const UserAuthChecker = (ComposedClass: any) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const AuthenticationCheck = (props: any) => {
    const [loading, setLoading] = useState(false);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const profile = useMemo(() => getLocalStorage('user_profile'), []);

    useQuery({
      queryKey: ['profile'],
      queryFn: async () => {
        setLoading(true);
        const response = await getProfile();

        if (response) {
          localStorage.setItem('user_profile', JSON.stringify(response));

          const profile = response as IUser;

          localStorage.setItem('user_id', profile.id);
          localStorage.setItem('user_name', profile.name);
          setLoading(false);
        }
      },
      enabled: !profile,
    });

    if (loading) {
      return <LoadingAnim />;
    } else return <ComposedClass {...props} />;
  };

  return AuthenticationCheck;
};

export default UserAuthChecker;
