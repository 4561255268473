/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { Box, IconButton, Stack } from '@mui/material';

import DocxViewerComponent from './DocxViewerComponent';

interface IProps {
  fileLink: string;
  instance: any;
  setInstance: any;
  setDocumentLoading?: any;
}

const PdfView: React.FC<IProps> = ({
  fileLink,
  instance,
  setInstance,
  setDocumentLoading,
}) => {
  const zoomOut = () => {
    const zoom = instance.UI.getZoomLevel() - 0.25;
    if (zoom > 0.25) {
      instance.UI.setZoomLevel(zoom);
    }
  };

  const zoomIn = () => {
    const zoom = instance.UI.getZoomLevel() + 0.25;
    instance.UI.setZoomLevel(zoom);
  };

  const search = useCallback(() => {
    const { UI } = instance;
    UI.searchText('', {
      caseSensitive: true,
      wholeWord: true,
    });
  }, [instance]);

  return (
    <React.Fragment>
      <Stack direction="row">
        <Stack
          sx={{
            background: '#88305F14',
            borderRadius: '25px',
            padding: '2px 10px',
            flex: 1,
          }}
          direction="row"
          justifyContent="space-between"
        >
          <IconButton
            color="primary"
            onClick={() => {
              search();
            }}
          >
            <SearchIcon /> Search
          </IconButton>
          <Stack direction="row">
            <IconButton
              color="primary"
              onClick={() => {
                zoomIn();
              }}
            >
              <ZoomInIcon />
            </IconButton>
            <IconButton
              color="primary"
              onClick={() => {
                zoomOut();
              }}
            >
              <ZoomOutIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
      <Box marginTop={1}>
        <DocxViewerComponent
          key={fileLink}
          fileLink={fileLink}
          setInstance={setInstance}
          setDocumentLoading={setDocumentLoading}
        />
      </Box>
    </React.Fragment>
  );
};

export default PdfView;
