import 'ag-grid-community/styles/ag-grid.css'; // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Theme

import React, { useCallback, useMemo, useRef, useState } from 'react';

import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  CellEditingStoppedEvent,
  ColDef,
  GetRowIdFunc,
  GetRowIdParams,
  RowClassParams,
  SelectionChangedEvent,
  ValueFormatterParams,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import {
  addDictionary,
  deleteSections,
  fetchContractById,
  fetchDictionaryByContractType,
  updateDictionary,
} from '../../axiosClient/dictionaryApi';
import GridSelectComponent from '../../components/dataGrid/GridSelectComponent';
import DeleteIcon from '../../Icons/DeleteIcon';
import { IObject } from '../../interfaces/IObject';

const DataGrid = () => {
  const gridRef = useRef<AgGridReact>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [inputRow, setInputRow] = useState<IObject>({});
  const [dataType, setDataType] = useState<IObject | null>();
  const [selectedRowIds, setSelectedRowIds] = useState<(string | undefined)[]>();

  const { id } = useParams();

  const queryClient = useQueryClient();

  const methods = useForm();

  const { data: dictionaryData } = useQuery({
    queryKey: ['data_dictionary', id],
    queryFn: () => fetchDictionaryByContractType(id || ''),
    enabled: !!id,
  });

  const { data: contract } = useQuery({
    queryKey: ['contract_by_id', id],
    queryFn: () => fetchContractById(id || ''),
    enabled: !!id,
  });

  const { mutate: addDictionaryMutation } = useMutation({
    mutationFn: addDictionary,
    onSuccess: () => {
      // Invalidate and refetch
      setInputRow({});
      setDataType(null);
      queryClient.invalidateQueries({
        queryKey: ['data_dictionary'],
      });
    },
  });

  const { mutate: updateDictionaryMutation, isPending: updatingData } = useMutation({
    mutationFn: updateDictionary,
    onSuccess: () => {
      // Invalidate and refetch
      setDataType(null);
      queryClient.invalidateQueries({
        queryKey: ['data_dictionary'],
      });
    },
  });

  const { mutate: deleteSectionsMutation } = useMutation({
    mutationFn: deleteSections,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: ['data_dictionary'],
      });
    },
  });

  const { results, contractName } = useMemo(() => {
    if (dictionaryData) {
      return {
        results: dictionaryData.results,
        contractName: contract?.name || '',
      };
    } else
      return {
        results: [],
        contractName: contract?.name || '',
      };
  }, [dictionaryData, contract]);

  // Column Definitions: Defines & controls grid columns.
  const [colDefs] = useState<ColDef[]>([
    {
      field: 'name',
      headerName: 'Section Name',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      headerCheckboxSelectionCurrentPageOnly: true,
    },
    {
      field: 'field_name',
      headerName: 'Field Name',
    },
    {
      field: 'data_type',
      headerName: 'Data Type',
      valueGetter: (params) => {
        return params?.data?.data_type?.name || '';
      },
      valueSetter: (params) => {
        if (!params.data.id && params.newValue.value) {
          setDataType(params.newValue);
        }
        return params?.data?.data_type?.name || params?.newValue.name;
      },
      cellEditorSelector: () => {
        return {
          component: GridSelectComponent,
          popup: true,
        };
      },
    },
    {
      field: 'boundary',
      headerName: 'Boundary',
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['Paragraph', 'Sentence', 'Phrase'],
      },
    },
    {
      field: 'clause_library',
      headerName: 'Clause Library',
      cellEditor: 'agLargeTextCellEditor',
      cellEditorPopup: true,
      cellEditorParams: {
        maxLength: 2000,
      },
    },
    { field: 'point_of_determination', headerName: 'Point Of Determination' },
    { field: 'risk_weightage', headerName: 'Risk Weighage' },
    {
      field: 'search',
      headerName: 'Search',
      cellEditor: 'agLargeTextCellEditor',
      cellEditorPopup: true,
      cellEditorParams: {
        maxLength: 2000,
      },
    },
    {
      field: 'key_question_1',
      headerName: 'Section / Field Value',
      cellEditor: 'agLargeTextCellEditor',
      cellEditorPopup: true,
      cellEditorParams: {
        maxLength: 2000,
      },
    },
    {
      field: 'key_question_2',
      headerName: 'Findings',
      cellEditor: 'agLargeTextCellEditor',
      cellEditorPopup: true,
      cellEditorParams: {
        maxLength: 2000,
      },
    },
    {
      field: 'key_question_3',
      headerName: 'RAG Analysis',
      cellEditor: 'agLargeTextCellEditor',
      cellEditorPopup: true,
      cellEditorParams: {
        maxLength: 2000,
      },
    },
  ]);

  const getValue = useCallback(
    (params: ValueFormatterParams) => {
      if (params.colDef.field === 'data_type') {
        if (params.data.id) {
          return params.data.data_type?.name || '';
        } else if (params.node?.rowPinned === 'top') {
          return dataType?.name || '';
        }
      } else {
        return params.value;
      }
    },
    [dataType]
  );

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      filter: true,
      editable: true,
      valueFormatter: (params) => {
        return isEmptyPinnedCell(params)
          ? createPinnedCellPlaceholder(params)
          : getValue(params);
      },
      suppressKeyboardEvent: (params) => {
        if (params.event.code === 'Enter') {
          params.api.stopEditing();
        }
        return params.editing;
      },
    };
  }, [dataType]);

  const getRowId = useMemo<GetRowIdFunc>(() => {
    return (params: GetRowIdParams) => params.data.id;
  }, []);

  const getRowStyle = useCallback(({ node }: RowClassParams) => {
    if (node.rowPinned) {
      return { fontWeight: 600, fontStyle: 'italic' };
    }
  }, []);

  function isEmptyPinnedCell(params: ValueFormatterParams) {
    if (params.colDef.field === 'data_type') {
      return (
        (params.node?.rowPinned === 'top' && dataType == null) ||
        (params.node?.rowPinned === 'top' && dataType?.value === '')
      );
    } else {
      return (
        (params.node?.rowPinned === 'top' && params.value == null) ||
        (params.node?.rowPinned === 'top' && params.value === '')
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function createPinnedCellPlaceholder({ colDef }: any) {
    return colDef.headerName[0].toUpperCase() + colDef.headerName.slice(1) + '...';
  }

  const onCellEditingStopped = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (params: CellEditingStoppedEvent) => {
      if (params?.data?.id) {
        delete params.data.crated_by;
        delete params.data.crated_on;
        delete params.data.last_modified_by;
        delete params.data.last_modified_on;
        const data = {
          id: params?.data?.id,
          payload: {
            ...params.data,
            contract_type: params.data.contract_type.id,
            data_type: params.newValue.value || params?.data?.data_type?.id,
          },
        };
        updateDictionaryMutation(data);
      } else {
        if (params.data.name && params.data.field_name && params.newValue.value) {
          const data = {
            ...params.data,
            data_type: params.newValue.value,
            contract_type: id,
          };
          addDictionaryMutation(data);
        }
      }
    },
    [inputRow]
  );

  const handleDelete = React.useCallback(() => {
    if (selectedRowIds && selectedRowIds.length > 0) {
      deleteSectionsMutation(selectedRowIds as string[]);
    }
  }, [deleteSectionsMutation, selectedRowIds]);

  const onSelectionChanged = useCallback((event: SelectionChangedEvent) => {
    const rowIds = event.api.getSelectedNodes().map((data) => data.id);
    setSelectedRowIds(rowIds);
  }, []);

  return (
    <Box className="ag-theme-quartz" style={{ height: 500 }}>
      <Stack direction="row" alignItems="center">
        <Typography
          sx={{
            textAlign: 'center',
            textTransform: 'capitalize',
            width: '95%',
          }}
        >
          {contractName as string}
        </Typography>
        {selectedRowIds?.length && (
          <IconButton aria-label="delete" color="primary" onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        )}
      </Stack>
      <FormProvider {...methods}>
        <AgGridReact
          ref={gridRef}
          loadingCellRenderer={updatingData}
          rowHeight={50}
          rowData={results}
          columnDefs={colDefs}
          suppressRowClickSelection={true}
          defaultColDef={defaultColDef}
          pagination={true}
          rowSelection="multiple"
          getRowStyle={getRowStyle}
          onCellEditingStopped={onCellEditingStopped}
          getRowId={getRowId}
          onSelectionChanged={onSelectionChanged}
          pinnedTopRowData={[inputRow]}
        />
      </FormProvider>
    </Box>
  );
};

export default DataGrid;
