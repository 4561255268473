/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  LinearProgress,
  linearProgressClasses,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';

import { addContract, deleteContract } from '../../../axiosClient/contractsApi';
import CustomModal from '../../../components/CustomModal';
import ReusableConfirmationModal from '../../../components/ReusableConfirmationModal';
import RISelectComponent from '../../../components/SelectComponent';
import { contractApi } from '../../../redux/services/contractApi';
import { useAppDispatch } from '../../../redux/store';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  handleRefresh: VoidFunction;
  allContracts: any;
  type: string;
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#6D264C' : '#308fe8',
  },
}));

const UploadSupportFile: React.FC<Props> = ({
  open,
  onClose,
  handleRefresh,
  allContracts,
  type,
}) => {
  const { control, watch, setValue } = useForm();

  const refcontract_id = watch('refcontract_id') || '';

  const queryClient = useQueryClient();
  const disabledUploadFileField = useMemo(() => {
    if (refcontract_id?.length > 0) {
      return false;
    } else {
      return true;
    }
  }, [refcontract_id]);

  const dispatch = useAppDispatch();

  const [isShowUploadField, setIsShowUploadField] = useState<boolean>(true);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [deleteDocId, setDeleteDocId] = useState<string>('');

  const handleOpenDeleteModal = (id: string) => {
    setOpenDeleteModal(true);
    setDeleteDocId(id);
  };

  const handleCloseDeleteModal = () => {
    setDeleteDocId('');
    setOpenDeleteModal(false);
  };

  useEffect(() => {
    if (type === 'Amendment') {
      if (allContracts.amendment?.length === 0) {
        setIsShowUploadField(true);
      }
    } else {
      if (allContracts.support_document?.length === 0) {
        setIsShowUploadField(true);
      }
    }
  }, [allContracts]);

  const { mutate: uploadSupportDoc, isPending: isLoadingUploadDoc } = useMutation({
    mutationKey: ['add_draft'],
    mutationFn: addContract,
    onSuccess: () => {
      dispatch(
        contractApi.endpoints.getContract.initiate(allContracts.id, {
          forceRefetch: true,
        })
      );
      queryClient.invalidateQueries({ queryKey: ['contracts'] });
    },
    onError: () => {
      enqueueSnackbar('Failed to add Contract!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: deleteBulkUploadedDocs, isPending: isLoadingBulkDelete } = useMutation({
    mutationKey: ['delete_bulk_support_docs'],
    mutationFn: deleteContract,
    onSuccess: () => {
      dispatch(
        contractApi.endpoints.getContract.initiate(allContracts.id, {
          forceRefetch: true,
        })
      );
      queryClient.invalidateQueries({ queryKey: ['contracts'] });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to delete all documents!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const handleFileUploadChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile: any = event.target.files;
    Object.entries(uploadedFile)?.map(([key]) => {
      const file = uploadedFile[key];
      const fileData = file?.name?.split('.');
      const blob = new Blob([file], { type: 'octet' });
      const newFile = new File([blob], file.name, {
        type: fileData[fileData.length - 1],
      });
      if (file) {
        const payload = {
          file_name: file?.name,
          groups: JSON.stringify(allContracts.groups.map((data: any) => data.id)),
          projects: JSON.stringify(allContracts.projects.map((data: any) => data.id)),
          file: newFile,
          contract_type: allContracts.contract_type.id,
          uploaded_type: type,
          reference_contract_id: refcontract_id,
        };
        uploadSupportDoc(payload);
      }
    });
  };

  const handleBulkDelete = () => {
    if (type === 'Amendment') {
      if (allContracts.amendment?.length > 0) {
        const ids = allContracts.amendment?.map((data: any) => data.id);
        deleteBulkUploadedDocs(ids);
      } else {
        onClose();
      }
    } else {
      if (allContracts.support_document?.length > 0) {
        const ids = allContracts.support_document?.map((data: any) => data.id);
        deleteBulkUploadedDocs(ids);
      } else {
        onClose();
      }
    }
  };

  const deleteUploadedDocById = (id: string) => {
    deleteBulkUploadedDocs([id]);
  };

  const handleSaveAndClose = () => {
    onClose();
    handleRefresh();
  };

  const handleCloseModal = () => {
    if (isLoadingBulkDelete || isLoadingUploadDoc) {
      enqueueSnackbar("Don't close modal because process is going on!", {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    } else {
      handleBulkDelete();
    }
  };

  useEffect(() => {
    if (allContracts) {
      setValue('refcontract_id', allContracts.id);
    }
  }, [allContracts]);

  return (
    <React.Fragment>
      <CustomModal
        maxWidth="md"
        title={`Upload ${type === 'Amendment' ? type : 'Support'} Document`}
        open={open}
        handleClose={handleCloseModal}
      >
        <Stack spacing={2} mt="25px" width="550px">
          <RISelectComponent
            readOnly={true}
            name="refcontract_id"
            control={control}
            label="Link master contracts"
            valueKey="id"
            labelKey="title"
            options={[allContracts]}
          />

          {isShowUploadField && (
            <Controller
              name="upload_contract"
              control={control}
              render={() => (
                <TextField
                  variant="outlined"
                  label={`Browse ${type === 'Amendment' ? 'amendment' : 'support'} document to upload`}
                  fullWidth
                  disabled
                  helperText="Supported file formats: PDF, DOCX"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <label htmlFor={`upload-doc`}>
                          <input
                            disabled={disabledUploadFileField}
                            type="file"
                            multiple
                            id={`upload-doc`}
                            style={{ display: 'none' }}
                            accept="pdf, docx"
                            onChange={(e) => handleFileUploadChanges(e)}
                          />
                          <IconButton component="span" disabled={disabledUploadFileField}>
                            <FileUploadOutlinedIcon />
                          </IconButton>
                        </label>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          )}

          {isLoadingUploadDoc && (
            <Box sx={{ width: '100%', padding: '15px 0' }}>
              <LinearProgress />
            </Box>
          )}

          {type === 'Amendment' &&
            allContracts.amendment?.length > 0 &&
            allContracts.amendment?.map((item: any, index: number) => (
              <Stack key={index} direction="column" spacing={1} paddingBottom="15px">
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography fontSize="17px" fontWeight={600}>
                    {item?.file_name}
                  </Typography>
                  <LoadingButton
                    disabled={isLoadingBulkDelete}
                    size="small"
                    onClick={() => handleOpenDeleteModal(item?.id)}
                    loading={isLoadingBulkDelete}
                  >
                    <DeleteOutlineIcon />
                  </LoadingButton>
                </Stack>
                <BorderLinearProgress variant="determinate" value={100} />
              </Stack>
            ))}

          {type === 'Support Document' &&
            allContracts.support_document?.length > 0 &&
            allContracts.support_document?.map((item: any, index: number) => (
              <Stack key={index} direction="column" spacing={1} paddingBottom="15px">
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography fontSize="17px" fontWeight={600}>
                    {item?.file_name}
                  </Typography>
                  <LoadingButton
                    disabled={isLoadingBulkDelete}
                    size="small"
                    onClick={() => handleOpenDeleteModal(item?.id)}
                    loading={isLoadingBulkDelete}
                  >
                    <DeleteOutlineIcon />
                  </LoadingButton>
                </Stack>
                <BorderLinearProgress variant="determinate" value={100} />
              </Stack>
            ))}
          <Stack direction="row" spacing={2} pt="15px" pb="10px">
            {((type === 'Support Document' &&
              allContracts.support_document?.length === 0) ||
              (type === 'Amendment' && allContracts.amendment?.length === 0)) && (
              <Button variant="outlined" onClick={handleCloseModal}>
                Close
              </Button>
            )}
            {((type === 'Support Document' &&
              allContracts.support_document?.length > 0) ||
              (type === 'Amendment' && allContracts.amendment?.length > 0)) && (
              <Stack direction="row" spacing={2}>
                <LoadingButton
                  disabled={isLoadingBulkDelete}
                  variant="contained"
                  loading={isLoadingUploadDoc}
                  onClick={handleSaveAndClose}
                  style={{ fontSize: '15px', padding: '3px 15px' }}
                >
                  Save and close
                </LoadingButton>
                {!isShowUploadField && (
                  <Button
                    startIcon={<AddIcon />}
                    onClick={() => setIsShowUploadField(true)}
                  >
                    Upload more
                  </Button>
                )}
                <LoadingButton
                  onClick={handleBulkDelete}
                  disabled={isLoadingUploadDoc || isLoadingBulkDelete}
                  startIcon={<CloseIcon />}
                  loading={isLoadingBulkDelete}
                  loadingPosition="end"
                >
                  Cancel
                </LoadingButton>
              </Stack>
            )}
          </Stack>
        </Stack>
      </CustomModal>
      {openDeleteModal && (
        <ReusableConfirmationModal
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          title="Delete Document"
          cancelBtnText="No, Go Back"
          confirmBtnText="Yes, Delete"
          onConfirm={() => deleteUploadedDocById(deleteDocId)}
        >
          <Stack spacing={2}>
            <Typography>Are you sure?</Typography>
            <Typography>The selected document will be removed permanently.</Typography>
            <Typography>Note: This action is not reversible.</Typography>
          </Stack>
        </ReusableConfirmationModal>
      )}
    </React.Fragment>
  );
};

export default UploadSupportFile;
