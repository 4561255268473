/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Stack,
  Typography,
} from '@mui/material';

import { IContract } from '../../../interfaces/IContract';
import { IOptions } from '../../../interfaces/IOptions';

interface Props {
  contractData: IContract;
}

const ContractDetails: React.FC<Props> = ({ contractData }) => {
  const [accordionExpanded, setAccordionExpanded] = useState<boolean>(true);

  return (
    <React.Fragment>
      <Accordion
        sx={{
          padding: 0,
        }}
        className="according-class"
        expanded={accordionExpanded}
        onChange={() => setAccordionExpanded(!accordionExpanded)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Contract Details
        </AccordionSummary>
        <AccordionDetails>
          <Stack width="100%" spacing={2}>
            <Stack
              spacing={1}
              style={{
                background: '#FFECF1',
                borderRadius: '10px',
                padding: '10px',
              }}
            >
              <Typography fontWeight={600}>Contract Type</Typography>
              <Box>
                <Chip
                  label={contractData?.contract_type?.display_name}
                  style={{ background: '#C4DBFF', cursor: 'pointer' }}
                />
              </Box>
            </Stack>

            <Stack
              spacing={1}
              sx={{
                background: '#FFECF1',
                borderRadius: '10px',
                padding: '10px',
              }}
            >
              <Typography fontWeight={600}>Group</Typography>
              <Stack direction="row" spacing={2}>
                {contractData?.groups?.map((item: IOptions, index: number) => (
                  <Chip
                    key={index}
                    label={item.name}
                    style={{ background: '#DCBDE7', cursor: 'pointer' }}
                  />
                ))}
              </Stack>
            </Stack>
            <Stack
              spacing={1}
              style={{
                background: '#FFECF1',
                borderRadius: '10px',
                padding: '10px',
              }}
            >
              <Typography fontWeight={600}>Project</Typography>

              <Stack direction="row" spacing={2}>
                {contractData?.projects?.map((item: IOptions, index: number) => (
                  <Chip
                    key={index}
                    label={item?.name}
                    style={{ background: '#CDE7BD', cursor: 'pointer' }}
                  />
                ))}
              </Stack>
            </Stack>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
};

export default ContractDetails;
