/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';

import { status_bg_color } from './ReminderStaticData';
import CustomChip from '../../components/CustomChip';
import NameAvatar from '../../components/dataGrid/NameAvatar';
import ListSkeleton from '../../components/ListSkeleton';
import { getLocalStorage } from '../../hooks/authentication';
import { iconColor, status_icon } from '../Contracts/Components/Reminders/ReminderStatic';

interface Props {
  isLoading: boolean;
  reminderList: any;
}

const ReminderColumn: React.FC<Props> = ({ reminderList, isLoading }) => {
  const user_data = React.useMemo(() => getLocalStorage('user_profile'), []);

  const handleRouting = (item: any) => {
    const is_owner = item?.created_by?.id === user_data?.id;
    const isAssignee = item?.users?.find((item: any) => item?.id === user_data?.id);
    if (is_owner || isAssignee) {
      window.open(
        `/contracts/view-contract/${item?.contract?.id}?isReminder=true&reminderId=${item?.id}`,
        '_blank'
      );
    } else {
      enqueueSnackbar('You are not assigned any role for this reminder!', {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  return (
    <Stack
      spacing={1}
      sx={{
        minHeight: '100%',
        backgroundColor: 'riTertiary.50',
        borderRadius: '20px',
        margin: '16px 0',
        padding: '16px',
        gap: '16px',
      }}
    >
      {isLoading && (
        <Box px={2}>
          <ListSkeleton />
        </Box>
      )}

      {reminderList?.length === 0 && (
        <Typography textAlign="center" pt={2}>
          <InfoOutlinedIcon /> No Obligation found
        </Typography>
      )}

      {reminderList?.map((item: any, index: number) => {
        const isPastDue = dayjs(item?.due_date).isBefore(dayjs(), 'day');
        return (
          <Stack
            key={index}
            className="according-class"
            padding="16px"
            spacing={0}
            alignItems="start"
            sx={{
              cursor: 'pointer',
              mt: '0 !important',
              gap: '8px',
            }}
            onClick={() => {
              if (item?.app === 'Umbrella') {
                handleRouting(item);
              }
            }}
          >
            <Typography>{item?.name}</Typography>
            <Stack
              direction="row"
              spacing={1}
              width="100%"
              sx={{
                flexFlow: 'wrap',
                gap: '8px',
              }}
            >
              <CustomChip
                label={item?.app}
                sx={{
                  background: '#CFACBF',
                  padding: '6px 16px',
                  borderRadius: '5px',
                  border: '1px solid #79747E',
                  '& .MuiChip-label': {
                    padding: '0',
                  },
                  '&:hover': {
                    background: '#fff',
                  },
                }}
              />
              <CustomChip
                sx={{
                  background: status_bg_color?.[item?.status],
                  padding: '6px 8px',
                  borderRadius: '5px',
                  border: '1px solid #79747E',
                  marginLeft: '0 !important',
                  '& .MuiChip-label': {
                    paddingRight: '0',
                    paddingLeft: '4px',
                    fontSize: '12px',
                  },
                  '& .MuiChip-icon': {
                    width: '14px',
                    height: '14px',
                    margin: '0',
                    color: iconColor[item?.status],
                  },
                  '&:hover': {
                    background: '#fff',
                  },
                }}
                icon={status_icon[item?.status]}
                label={item?.status}
              />
            </Stack>
            <CustomChip
              sx={{
                background: isPastDue ? '#F9DEDC' : '#FFECF1',
                padding: '6px 8px',
                borderRadius: '5px',
                border: isPastDue ? '1px solid #B3261E' : '',
                '& .MuiChip-label': {
                  paddingRight: '0',
                  paddingLeft: '4px',
                  fontSize: '12px',
                },
                '& .MuiChip-icon': {
                  width: '14px',
                  height: '14px',
                  margin: '0',
                  color: isPastDue ? '#7B0D0D' : '',
                },
                '&:hover': {
                  background: isPastDue ? '#e8c0bd' : '#ebe8e8',
                },
              }}
              icon={<AccessTimeIcon />}
              label={dayjs(item?.due_date).format('MMMM D, YYYY')}
            />
            {item?.contract?.file_name && (
              <Typography fontSize="13px">{item?.contract?.file_name}</Typography>
            )}
            {item?.support_documents?.length > 0 && (
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography fontWeight={600} fontSize="14px">
                  Support documents:
                </Typography>
                <Typography fontSize="13px">{item?.support_documents?.length}</Typography>
              </Stack>
            )}
            <Stack direction="row" spacing={1} sx={{ mt: '6px !important' }}>
              {item?.external_user?.length > 0
                ? item.external_user.map((userItem: any) => {
                    const fullName: string[] = userItem?.name?.split(' ');
                    return (
                      <NameAvatar
                        key={userItem?.id}
                        firstName={fullName?.[0]}
                        lastName={fullName?.[1]}
                        sx={{
                          color: '#A0597F',
                          border: '3px solid #A0597F',
                          backgroundColor: 'unset',
                          width: 32,
                          height: 32,
                          fontSize: '12px',
                        }}
                      />
                    );
                  })
                : item?.users?.map((userItem: any) => (
                    <NameAvatar
                      key={userItem?.id}
                      firstName={userItem?.first_name}
                      lastName={userItem?.last_name}
                    />
                  ))}
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default ReminderColumn;
