/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction, useState } from 'react';

import { LaunchOutlined, Undo } from '@mui/icons-material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Box, Button, IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Control, Controller } from 'react-hook-form';

import colors from './Theme/colors';

interface Props {
  allowedFileTypes: string[];
  label: string;
  canViewDraft?: boolean;
  control?: Control;
  name: string;
  files: any[];
  setFiles: Dispatch<SetStateAction<any>>;
  multipleUpload?: boolean;
  required?: boolean;
}

const UploadDocComponent: React.FC<Props> = ({
  allowedFileTypes,
  label,
  canViewDraft = false,
  control,
  name,
  files,
  setFiles,
  multipleUpload = false,
  required = false,
}) => {
  const [fileFields, setFileFields] = useState<(any | null)[]>([0]);
  const [deletedFileName, setDeletedFileName] = useState<string | null>(null);
  const [isAnotherFileField, setIsAnotherFileField] = useState(false);
  const [deletedFields, setDeletedFields] = useState<any[]>([]);

  // upload file
  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldIndex: number
  ) => {
    const selectedFile = event.target.files && event.target.files?.[0];
    if (selectedFile) {
      const updatedFiles = [...files];
      updatedFiles[fieldIndex] = selectedFile;
      setFiles(updatedFiles);
    }
  };

  // delete field
  const handleDeleteField = (fieldIndex: number) => {
    const deletedFile = files[fieldIndex];
    const deletedFieldContent = fileFields[fieldIndex];
    const updatedFields = [...fileFields];
    const updatedFiles = [...files];

    if (deletedFile) {
      updatedFields[fieldIndex] = {
        deleted: true,
        message: `${files[fieldIndex]?.name} has been deleted.`,
      };
      setFileFields(updatedFields);
      setDeletedFields((prevDeletedFields) => [
        ...prevDeletedFields,
        {
          index: fieldIndex,
          content: deletedFieldContent,
          fileName: deletedFile,
        },
      ]);
      updatedFiles[fieldIndex] = null;
      setFiles(updatedFiles);
    } else {
      updatedFields.splice(fieldIndex, 1);
      setFileFields(updatedFields);
      updatedFiles.splice(fieldIndex, 1);
      setFiles(updatedFiles);
    }
  };

  // undo delete
  const handleUndoDelete = (fieldIndex: number) => {
    const restoredField = deletedFields.find((field) => field.index === fieldIndex);

    if (restoredField) {
      const updatedFields = [...fileFields];
      updatedFields[fieldIndex] = restoredField.content;
      setFileFields(updatedFields);

      const updatedFiles = [...files];
      updatedFiles[fieldIndex] = restoredField.fileName;
      setFiles(updatedFiles);

      // Remove the restored field from deletedFields state
      setDeletedFields((prevDeletedFields) =>
        prevDeletedFields.filter((field) => field.index !== fieldIndex)
      );
    }
  };

  //view uploaded file
  const handleViewFile = (fieldIndex: number) => {
    if (files[fieldIndex]) {
      const fileURL = URL.createObjectURL(files[fieldIndex]);
      window.open(fileURL, '_blank');
    }
  };

  //add another field
  const handleAddFileField = () => {
    setIsAnotherFileField(true);
    setFileFields((prev) => [...prev, prev.length]);
    setDeletedFileName(null);
  };

  // remove file
  const handleClearFile = (fieldIndex: number) => {
    const updatedFiles = [...files];
    updatedFiles[fieldIndex] = null;
    setFiles(updatedFiles);
    setDeletedFileName(deletedFileName);
  };

  return (
    <Stack spacing={2} sx={{ marginBottom: '15px', alignItems: 'start' }}>
      {fileFields
        .map((field, fieldIndex) => (
          <Box key={fieldIndex} display="flex" width="100%" marginTop={1}>
            {field.deleted ? (
              <Grid container spacing={1}>
                <Grid
                  size={{ sm: 7 }}
                  sx={{
                    marginTop: 2,
                    fontSize: '14px',
                    fontWeight: '500',
                  }}
                >
                  {field.message}
                </Grid>
                <Grid size={{ xs: 5 }}>
                  <Button
                    size={'small'}
                    style={{ color: colors.riNeutralVariant[700] }}
                    onClick={() => handleUndoDelete(fieldIndex)}
                  >
                    <Undo />
                    Undo
                  </Button>
                  <Button onClick={() => handleDeleteField(fieldIndex)}>Delete</Button>
                </Grid>
              </Grid>
            ) : (
              <Controller
                name={`${name}[${fieldIndex}]`}
                control={control}
                render={() => (
                  <TextField
                    variant="outlined"
                    label={label}
                    fullWidth
                    disabled
                    required={required}
                    helperText={`Supported file formats:${allowedFileTypes
                      .join(', ')
                      .replace(/,([^,]*)$/, ' and$1')}`}
                    value={files?.[fieldIndex] ? files[fieldIndex]?.name : ''}
                    InputProps={{
                      startAdornment: files?.[fieldIndex] && (
                        <InputAdornment position="start">
                          <AttachFileOutlinedIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          {files?.[fieldIndex] ? (
                            <IconButton onClick={() => handleClearFile(fieldIndex)}>
                              <HighlightOffIcon />
                            </IconButton>
                          ) : (
                            <label htmlFor={`upload-file-${fieldIndex}`}>
                              <input
                                type="file"
                                id={`upload-file-${fieldIndex}`}
                                style={{ display: 'none' }}
                                accept={allowedFileTypes.join(', ')}
                                onChange={(e) => handleFileChange(e, fieldIndex)}
                              />
                              <IconButton component="span">
                                <FileUploadOutlinedIcon />
                              </IconButton>
                            </label>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            )}

            {canViewDraft && !field.deleted && files[fieldIndex] && (
              <Box marginTop={1} marginLeft={2}>
                <IconButton onClick={() => handleViewFile(fieldIndex)}>
                  <LaunchOutlined />
                </IconButton>
              </Box>
            )}

            {isAnotherFileField && !field.deleted && (
              <Box marginTop={1} marginLeft={2}>
                <IconButton onClick={() => handleDeleteField(fieldIndex)}>
                  <DeleteOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        ))
        .reverse()}

      {multipleUpload && (
        <Button onClick={handleAddFileField}>
          <AddOutlinedIcon />
          <Box marginLeft={1}> Add Another File</Box>
        </Button>
      )}
    </Stack>
  );
};

export default UploadDocComponent;
