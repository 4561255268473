/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';

import { useQueries } from '@tanstack/react-query';
import * as changesets from 'json-diff-ts';

import { getInsights } from '../../../axiosClient/contractsApi';
import { propertyExists } from '../../../components/utils';

interface IProps {
  sections?: any;
  id?: string;
  tableTopInfo: any;
}

const RenderTableTopInfo = ({ tableTopInfo }: any) => {
  return (
    <>
      {tableTopInfo.map((info: any, index: number) => {
        return (
          <tr
            key={index}
            style={{
              background: '#D8E4BC',
              fontSize: '16px',
            }}
          >
            <td></td>
            <th
              style={{
                color: info.color ? info.color : 'black',
                borderWidth: 1,
                borderColor: 'black',
                borderStyle: 'solid',
              }}
            >
              {info.key}
            </th>
            <td
              style={{
                color: info.color ? info.color : 'black',
                borderWidth: 1,
                borderColor: 'black',
                borderStyle: 'solid',
              }}
              colSpan={2}
            >
              {info.value}
            </td>
          </tr>
        );
      })}
    </>
  );
};

const RenderList: FC<{ sections: any; id?: string }> = ({ id, sections }) => {
  const combinedQueries = useQueries({
    queries:
      sections && id
        ? sections.map((data: any) => {
            return {
              queryKey: ['insights-list-excel', data.id],
              queryFn: () => getInsights(data.id),
            };
          })
        : [],
    combine: (results) => {
      return {
        data: results.map((result) => {
          let changeList;
          const contractData: any = result?.data;
          if (contractData) {
            const diff = JSON.parse(JSON.stringify(contractData.edited_content));

            const orig = JSON.parse(JSON.stringify(contractData.raw_data || {}));
            if (diff && diff?.update) {
              const unFlattenChanges = changesets.unflattenChanges(diff.update);
              changeList = JSON.parse(
                JSON.stringify(
                  changesets.applyChangeset(
                    JSON.parse(JSON.stringify(orig)),
                    unFlattenChanges
                  )
                )
              );
            } else {
              changeList = JSON.parse(
                JSON.stringify(
                  changesets.applyChangeset(JSON.parse(JSON.stringify(orig)), diff)
                )
              );
            }
            contractData.changeList = changeList;
          }
          return contractData;
        }),
        pending: results.some((result) => result.isPending),
      };
    },
  });

  return (
    <td
      colSpan={2}
      style={{
        width: '2000px',
      }}
    >
      {!combinedQueries?.pending &&
        combinedQueries?.data?.map((data: any, index) => {
          const parentClauseData = propertyExists(data.changeList, data.section_type)
            ? data.changeList[data.section_type]
            : null;
          return (
            <React.Fragment key={`excel-${index}`}>
              <table key={`excel-${index}`}>
                <thead>
                  <tr
                    style={{
                      background: '#8DB4E2',
                      fontSize: '14px',
                    }}
                  >
                    <th colSpan={2}>{data.clause}</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(parentClauseData) &&
                    parentClauseData?.map((clause: any, clauseIndex: number) => (
                      <tr key={`excel-${clauseIndex}`}>
                        <td
                          style={{
                            width: '250px',
                          }}
                        >
                          Clause Text
                        </td>
                        <td
                          style={{
                            padding: '5px',
                            width: '1750px',
                            wordBreak: 'break-word',
                          }}
                        >
                          {`${clauseIndex + 1}). ${clause.sentence}`} {}
                        </td>
                      </tr>
                    ))}
                  {Object.entries(data.changeList || {})?.map(
                    (childClauseData: any) =>
                      typeof childClauseData[1] === 'object' &&
                      !Array.isArray(childClauseData[1]) && (
                        <tr key={`excel-${childClauseData?.[1].display_name}-${index}`}>
                          <td>{childClauseData?.[1].display_name}</td>
                          <td
                            style={{
                              padding: '5px',
                              width: '500px',
                              wordBreak: 'break-word',
                            }}
                          >
                            <ol type="A">
                              {childClauseData?.[1]?.data?.map(
                                (childData: any, index: number) =>
                                  childData?.value &&
                                  childData?.value !== 'cannot_determine' && (
                                    <li key={`excel-${index}`}>{childData?.value}</li>
                                  )
                              )}
                            </ol>
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </React.Fragment>
          );
        })}
    </td>
  );
};

const TableExcel = (props: IProps) => {
  const { sections, id, tableTopInfo } = props;

  return (
    <table
      id="table-to-xls-insights"
      style={{ display: 'none', fontFamily: 'Cambria Math' }}
    >
      <thead>
        <RenderTableTopInfo tableTopInfo={tableTopInfo} />
        <tr>
          <td style={{ width: '50px' }}></td>
          <td style={{ width: '450px' }}></td>
          <td style={{ width: '2000px' }}></td>
        </tr>
        <tr
          style={{
            background: '#8DB4E2',
            fontSize: '14px',
          }}
        >
          <th>SNo.</th>
          <th>Section Name</th>
          <th colSpan={2}>Clause</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(sections || {})?.map((section: any, key) => (
          <tr key={`excel-${key}`}>
            <td valign="top">{key + 1}</td>
            <td valign="top">{section[0]}</td>
            <RenderList id={id} sections={section[1]} />
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TableExcel;
