/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import * as changesets from 'json-diff-ts';
import { Controller, useForm } from 'react-hook-form';

import ControlledTextField from '../../../components/ControlledTextField';
import { RootState, useAppDispatch, useTypedSelector } from '../../../redux/store';
import {
  saveAddPhrase,
  saveDeletePhrase,
  saveHighlightedComponentType,
  saveHighlightedPhrases,
  saveHighlightedTableCells,
  savePhraseEditOption,
} from '../ContractSlice';
import {
  DurationType,
  editedPhrases,
  LinkPhraseRequest,
  phraseInfo,
  tableInfo,
} from '../State/DocumentState';
import { DurationList } from '../StaticData';
import { getClauseDataFormat } from '../Utils/ClauseTypeUtils';
import { isTableCell } from '../Utils/docUtils';

interface LinkDurationPhrasesProps {
  clauseName: string;
  clauseDisplayName: string;
  savedInsight: any;
  onClose: VoidFunction;
  id: string;
  updateContract: any;
  updatedClauseData: any;
  clauseData?: any;
  parentClauseType?: any;
  rawContent: any;
  sectionType: string;
  buttonLoading: boolean;
}

const EditLinkDurationPhrase: React.FC<LinkDurationPhrasesProps> = (props) => {
  const {
    rawContent,
    id,
    onClose,
    clauseDisplayName,
    updateContract,
    clauseName,
    updatedClauseData,
    sectionType,
    buttonLoading,
  } = props;
  const dispatch = useAppDispatch();

  const { sentenceData, componentType, phraseInAddMode, phraseEditOptionSelected } =
    useTypedSelector((state: RootState) => state.contract);

  const [isAddingPhrase, setIsAddingPhrase] = useState<boolean>(false);
  const [currentEditingPhrase, setCurrentEditingPhrase] = useState<phraseInfo | null>(
    null
  );
  const [selectedOption, setSelectedOption] = useState<DurationType>({
    duration: '',
    id: -1,
  });

  const { control, watch, reset } = useForm();

  const durationValue = watch('value') || -1;

  useEffect(() => {
    if (phraseInAddMode) {
      setIsAddingPhrase(false);
    }
  }, [phraseInAddMode]);

  const setDuration = (duration: DurationType) => {
    setSelectedOption(duration);
  };

  const getSaveStatus = () => {
    let saveStatus = false;
    if (
      phraseInAddMode &&
      phraseInAddMode.paraId !== null &&
      phraseInAddMode.paraId !== -1 &&
      durationValue !== -1 &&
      selectedOption.id !== -1
    ) {
      saveStatus = true;
    }
    return saveStatus;
  };

  const linkToPhrase = () => {
    dispatch(savePhraseEditOption({ phraseEditOptionSelected: true }));
    setIsAddingPhrase(true);
    setCurrentEditingPhrase(null);
    dispatch(saveDeletePhrase({ phraseDeleteStatus: false, phraseInDeleteMode: null }));
    dispatch(saveHighlightedTableCells({ savedHighlightedTableCells: null }));
    dispatch(
      saveHighlightedComponentType({
        componentType: componentType,
        editOptionSelected: true,
      })
    );
  };

  const editLinkedPhraseOnDoc = (phraseInfo: phraseInfo) => {
    let deletePhraseElement = '';
    if (isTableCell(phraseInfo)) {
      const tempTableCell: tableInfo = {
        paraId: phraseInfo.paraId,
        rowId: phraseInfo.rowId !== null ? phraseInfo.rowId : -1,
        columnId: phraseInfo.columnId !== null ? phraseInfo.columnId : -1,
      };
      deletePhraseElement =
        'p' + phraseInfo.paraId + ';r' + phraseInfo.rowId + ';c' + phraseInfo.columnId;
      dispatch(
        saveHighlightedTableCells({ savedHighlightedTableCells: [tempTableCell] })
      );
    } else {
      deletePhraseElement = 'p' + phraseInfo.paraId + ';w' + phraseInfo.startWordId;
      dispatch(saveHighlightedTableCells({ savedHighlightedTableCells: null }));
    }
    const phraseElement = document.getElementById(deletePhraseElement);
    phraseElement !== undefined &&
      phraseElement !== null &&
      phraseElement.scrollIntoView({ block: 'center' }); //scroll to linked phrase
    document.documentElement.style.scrollBehavior = 'smooth';

    dispatch(
      saveDeletePhrase({ phraseDeleteStatus: true, phraseInDeleteMode: phraseInfo })
    );
    setIsAddingPhrase(false);
    setCurrentEditingPhrase(phraseInfo);
    dispatch(
      saveHighlightedComponentType({
        componentType: componentType,
        editOptionSelected: true,
      })
    );
    dispatch(savePhraseEditOption({ phraseEditOptionSelected: true }));
  };

  const onCancel = () => {
    onClose();
    dispatch(saveDeletePhrase({ phraseDeleteStatus: false, phraseInDeleteMode: null }));
    dispatch(saveAddPhrase({ phraseAddStatus: false, phraseInAddMode: null }));
    dispatch(savePhraseEditOption({ phraseEditOptionSelected: false }));
    dispatch(saveHighlightedPhrases({ savedHighlightedPhrases: null }));

    dispatch(
      saveHighlightedComponentType({
        componentType: '',
        editOptionSelected: false,
      })
    );
    dispatch(saveHighlightedTableCells({ savedHighlightedTableCells: null }));
    setIsAddingPhrase(false);
    setCurrentEditingPhrase(null);
    setSelectedOption({
      duration: '',
      id: -1,
    });
    reset();
  };

  const getTempPhrase = () => {
    let tempPhraseRequest: LinkPhraseRequest = {
      mode: '',
      editedPhrases: { upsert: [], deleted: [], bi: '' },
    };
    let addedDeletedPhrases: editedPhrases = {
      upsert: [],
      deleted: [],
      bi: '',
    };
    const newPhrase: phraseInfo =
      phraseInAddMode === null
        ? {
            paraId: -1,
            startWordId: -1,
            endWordId: -1,
            startSentenceId: -1,
            endSentenceId: -1,
            rowId: -1,
            columnId: -1,
            phrase: '',
            value: -1,
            durationId: -1,
            duration: '',
          }
        : phraseInAddMode;

    addedDeletedPhrases = {
      upsert: [
        {
          paraId: newPhrase.paraId,
          startWordId: newPhrase.startWordId,
          endWordId: newPhrase.endWordId,
          startSentenceId: newPhrase.startSentenceId,
          endSentenceId: newPhrase.endSentenceId,
          rowId: newPhrase.rowId,
          columnId: newPhrase.columnId,
          phrase: newPhrase.phrase,
          value: durationValue,
          durationId: selectedOption.id,
          duration: selectedOption.duration,
        },
      ],
      deleted: [],
      bi: clauseName,
    };

    tempPhraseRequest = {
      editedPhrases: addedDeletedPhrases,
      mode: 'manual',
    };
    return tempPhraseRequest;
  };

  const onSave = () => {
    const tempPhraseRequest = getTempPhrase();
    const updatedData = updatedClauseData;
    let newData = updatedData;
    const addedData = tempPhraseRequest.editedPhrases.upsert;

    for (let i = 0; i < addedData.length; i++) {
      if (addedData[i].startSentenceId === addedData[i].endSentenceId) {
        newData = getClauseDataFormat(
          'add',
          componentType,
          addedData[i],
          newData,
          clauseName,
          sentenceData
        );
      }
    }

    const diff = changesets.diff(rawContent, newData, {
      children: '$index',
    });
    if (diff.length > -1) {
      updateContract({ id, diff, updatedClauseData: newData, type: sectionType });
    }

    dispatch(saveDeletePhrase({ phraseDeleteStatus: false, phraseInDeleteMode: null }));
    dispatch(saveAddPhrase({ phraseAddStatus: false, phraseInAddMode: null }));
    dispatch(savePhraseEditOption({ phraseEditOptionSelected: false }));
    dispatch(saveHighlightedPhrases({ savedHighlightedPhrases: null }));
    dispatch(
      saveHighlightedComponentType({
        componentType: '',
        editOptionSelected: false,
      })
    );
    dispatch(saveHighlightedTableCells({ savedHighlightedTableCells: null }));
    setIsAddingPhrase(false);
    setCurrentEditingPhrase(null);
    setSelectedOption({
      duration: '',
      id: -1,
    });
    onClose();
    reset();
  };

  const linkPhraseOnDoc = (phraseInfo: phraseInfo | null) => {
    if (phraseInfo == null) {
      return (
        <Stack alignItems="start" width="100%">
          <Button
            variant="text"
            sx={{
              color:
                isAddingPhrase !== true
                  ? '#88305F'
                  : phraseEditOptionSelected === true
                    ? '#C1C1C1'
                    : '#88305F',
              padding: 0,
            }}
            onClick={() => linkToPhrase()}
          >
            Link phrase
          </Button>
        </Stack>
      );
    } else {
      return (
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Button
            variant="text"
            sx={{
              color: currentEditingPhrase === phraseInfo ? '#C1C1C1' : '#88305F',
              padding: 0,
            }}
            onClick={() => editLinkedPhraseOnDoc(phraseInfo)}
          >
            Edit linked phrase
          </Button>
          <Typography fontSize="14px">{phraseInfo === null ? 0 : 1}</Typography>
        </Stack>
      );
    }
  };

  return (
    <Box
      sx={{
        background: '#FFECF1',
        boxShadow: 'none',
        borderRadius: '15px',
        padding: '10px 16px',
      }}
    >
      <Typography fontWeight={600} pb={1}>
        Add / Edit {clauseDisplayName}
      </Typography>

      <Stack className="edit-clause-select" spacing={2}>
        <Stack direction="row" width="100%" spacing={1}>
          <ControlledTextField
            name="value"
            type="number"
            control={control}
            label="Value"
            fullWidth
            onKeyPress={(event) => {
              if (event?.key === '-' || event?.key === '+') {
                event.preventDefault();
              }
            }}
          />
          <Controller
            name="duration"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel htmlFor="Select-Duration">Select Duration</InputLabel>
                <Select
                  {...field}
                  required
                  label="Select Duration"
                  variant="outlined"
                  inputProps={{
                    id: 'Select-Duration',
                  }}
                  value={selectedOption}
                >
                  {DurationList.map((durationItem: any, i: number) => (
                    <MenuItem
                      key={i}
                      value={durationItem}
                      onClick={() => setDuration(durationItem)}
                    >
                      {durationItem.duration}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Stack>
        {linkPhraseOnDoc(phraseInAddMode)}

        <Stack width="100%">
          <Typography fontSize="14px" fontWeight={700}>
            How to add a {clauseDisplayName}?
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            1). Input the duration using the field above.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            2). Click `&quot;`Link Phrase`&quot;` button to link a text from the contract.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            3). Hover over the text in the contract on left.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            4). Click on the desired paragraph that contains {clauseDisplayName}.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            5). Confirm your selection using the Save button below.
          </Typography>
        </Stack>
        <Stack direction="row">
          <LoadingButton
            variant="contained"
            startIcon={<CheckIcon />}
            onClick={onSave}
            disabled={!getSaveStatus()}
            loading={buttonLoading}
          >
            Save
          </LoadingButton>
          <Button variant="outlined" onClick={onCancel} startIcon={<CloseIcon />}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default EditLinkDurationPhrase;
