/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { GridColDef, GridRowId, GridSortModel } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { isArray } from 'lodash-es';
import { useDebouncedCallback } from 'use-debounce';

import { fetchGroups, fetchUsers } from '../../axiosClient/authApi';
import { getUmbrellaProjects } from '../../axiosClient/contractsApi';
import { getDictionary } from '../../axiosClient/dictionaryApi';
import DataGridTable from '../../components/dataGrid/DataGridTable';
import { GridCellExpand } from '../../components/dataGrid/GridCellExpand';
import TableAsyncSelectInput from '../../components/dataGrid/TableAsyncSelectInput';
import TableChipList from '../../components/dataGrid/TableChipList';
import TableHeaderWithSearch from '../../components/dataGrid/TableHeaderWIthSearch';
import TableSearchInputComponent from '../../components/dataGrid/TableSearchInputComponent';
import { IObject } from '../../interfaces/IObject';

interface IProps {
  setSelectedRow: Dispatch<SetStateAction<GridRowId[]>>;
  selectedRow: GridRowId[];
  data: any;
  isLoading: boolean;
  pageNumber: number;
  setPageNumberChange: Dispatch<SetStateAction<number>>;
  setFilters: Dispatch<SetStateAction<IObject>>;
  setSorting?: Dispatch<SetStateAction<GridSortModel>>;
  filters?: any;
}

const ContractTable = (props: IProps) => {
  const {
    setSelectedRow,
    pageNumber,
    setPageNumberChange,
    setFilters,
    setSorting,
    filters,
    isLoading,
    data,
  } = props;

  const [searchContract, setSearchContract] = useState<boolean>(false);
  const [searchType, setSearchType] = useState<boolean>(false);
  const [searchGroups, setSearchGroups] = useState<boolean>(false);
  const [searchProjects, setSearchProjects] = useState<boolean>(false);

  const { data: groupData } = useQuery({
    queryKey: ['groups'],
    queryFn: async () => {
      const response = await fetchGroups();
      return response.results;
    },
  });

  const { data: contractData } = useQuery({
    queryKey: ['contracts-options'],
    queryFn: async () => {
      const response = await getDictionary('');
      return response.results;
    },
  });

  const { data: projects } = useQuery({
    queryKey: ['projects'],
    queryFn: getUmbrellaProjects,
    select: (response) => {
      return response?.results;
    },
  });

  const { data: users } = useQuery({
    queryKey: ['users'],
    queryFn: async () => {
      const response = await fetchUsers();
      return response.results;
    },
  });

  const handleFilterChange = useDebouncedCallback(
    (label: string, value: string | string[]) => {
      setFilters((prev: IObject) => ({ ...prev, [label]: value }));
    },
    1000
  );

  const handleGroupClick = React.useCallback(
    (groups: any) => {
      const names = groups.map((data: any) => data?.name);
      handleFilterChange('groups', names);
      setSearchGroups(true);
    },
    [handleFilterChange]
  );

  const columns = useMemo<GridColDef<any>[]>(
    () => [
      {
        field: 'title',
        headerName: 'Contract Name',
        minWidth: 180,
        flex: 1,
        sortable: !searchContract,
        renderHeader: () => {
          return searchContract ? (
            <TableSearchInputComponent
              key="title"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, name: null }));
                setSearchContract(false);
              }}
              placeholder="Search Title"
              handleChange={(e: React.BaseSyntheticEvent<HTMLInputElement>) =>
                handleFilterChange('title', e.target.value)
              }
            />
          ) : (
            <TableHeaderWithSearch
              title="Contract Name"
              setIsSearch={setSearchContract}
            />
          );
        },
        renderCell: (Params: any) => {
          return (
            <GridCellExpand
              value={Params?.row?.title}
              width={Params.colDef.computedWidth}
              onClick={() => window.open(`/contracts/view-contract/${Params?.row?.id}`)}
              cellStyle={{
                cursor: 'pointer',
              }}
            />
          );
        },
      },
      {
        field: 'projects',
        headerName: 'Project',
        minWidth: 250,
        flex: 1,
        sortable: false,
        renderHeader: () => {
          return searchProjects ? (
            <TableSearchInputComponent
              key="projects"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, projects: null }));
                setSearchProjects(false);
              }}
              placeholder="Search Projects"
              renderCustomInput={() => (
                <TableAsyncSelectInput
                  key={
                    isArray(filters?.projects)
                      ? (filters?.projects?.[0] as string)
                      : 'projects'
                  }
                  options={projects}
                  label="Select Project"
                  valueKey="name"
                  value={filters?.groups ? filters.groups : []}
                  onChange={(value: string[]) => handleFilterChange('projects', value)}
                />
              )}
            />
          ) : (
            <TableHeaderWithSearch
              title="Projects"
              setIsSearch={setSearchProjects}
              width="160px"
            />
          );
        },
        renderCell: (Params: any) => {
          return Params.row.projects.map((data: any, index: number) => (
            <GridCellExpand
              key={index}
              value={data?.name}
              width={Params.colDef.computedWidth}
              cellStyle={{
                cursor: 'pointer',
              }}
            />
          ));
        },
      },
      {
        field: 'contract_type',
        headerName: 'Contract Type',
        minWidth: 200,
        flex: 1,
        sortable: false,
        renderHeader: () => {
          return searchType ? (
            <TableSearchInputComponent
              key="contract_type"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, contract_type: null }));
                setSearchType(false);
              }}
              placeholder="Search Contract Type"
              renderCustomInput={() => (
                <TableAsyncSelectInput
                  key={
                    isArray(filters?.contract_type)
                      ? (filters?.contract_type?.[0] as string)
                      : 'contract_type'
                  }
                  options={contractData}
                  label="Select Contract Type"
                  valueKey="id"
                  value={filters?.groups ? filters.groups : []}
                  onChange={(value: string[]) =>
                    handleFilterChange('contract_type', value)
                  }
                />
              )}
            />
          ) : (
            <TableHeaderWithSearch title="Contract Type" setIsSearch={setSearchType} />
          );
        },
        renderCell: (Params: any) => {
          return (
            <GridCellExpand
              value={Params?.row?.contract_type?.display_name}
              width={Params.colDef.computedWidth}
              cellStyle={{
                cursor: 'pointer',
              }}
            />
          );
        },
      },

      {
        field: 'groups',
        headerName: 'Groups',
        maxWidth: 300,
        width: 250,
        sortable: false,
        minWidth: 150,
        renderCell: (params) => (
          <TableChipList
            id={`groups-${params?.row?.id}`}
            labelKey="name"
            list={params?.row?.groups || []}
            onClick={() => handleGroupClick(params?.row?.groups)}
          />
        ),
        renderHeader: () => {
          return searchGroups ? (
            <TableSearchInputComponent
              key="groups"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, groups: null }));
                setSearchGroups(false);
              }}
              placeholder="Search Groups"
              renderCustomInput={() => (
                <TableAsyncSelectInput
                  key={
                    isArray(filters?.groups) ? (filters?.groups?.[0] as string) : 'groups'
                  }
                  options={groupData}
                  label="Select Project Groups"
                  valueKey="name"
                  value={filters?.groups ? filters.groups : []}
                  onChange={(value: string[]) => handleFilterChange('groups', value)}
                />
              )}
            />
          ) : (
            <TableHeaderWithSearch title=" Groups" setIsSearch={setSearchGroups} />
          );
        },
      },
    ],
    [
      handleFilterChange,
      searchContract,
      setFilters,
      searchType,
      searchGroups,
      users,
      groupData,
      searchProjects,
    ]
  );

  return (
    <Box>
      <DataGridTable
        rows={data?.results || []}
        columns={columns}
        rowCount={data?.count}
        isLoading={isLoading}
        setSelectedRow={setSelectedRow}
        pageNumber={pageNumber}
        setPageNumberChange={setPageNumberChange}
        setSorting={setSorting}
      />
    </Box>
  );
};

export default ContractTable;
