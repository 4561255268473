/* eslint-disable @typescript-eslint/no-explicit-any */

import { authApi } from './authApi';

export const fetchReminders = async (params: string): Promise<any> => {
  return await authApi.get(`reminder/?${params}`);
};

export const fetchReminderById = async (id: string): Promise<any> => {
  return await authApi.get(`reminder/${id}/`);
};

export const addContractReminderComments = async (payload: any) => {
  return await authApi.post(`reminder/reminder-comments/`, payload);
};

export const createBulkReminder = async (payload: any) => {
  return await authApi.post('reminder/bulk-reminders/', payload);
};

export const editBulkReminder = async (payload: any) => {
  return await authApi.post('reminder/reminder-update/', payload);
};

export const fetchReminderDraftItem = async (id: string): Promise<any> => {
  return await authApi.get(`reminder/?draft_item=${id}`);
};
export const addContractReminderBulkComments = async (payload: any) => {
  return await authApi.post(`reminder/reminder-comments/comments-bulk-update/`, payload);
};

export const deleteReminder = async (id: string) => {
  return await authApi.delete(`reminder/${id}/`);
};

export const fetchReminder = async (id: string): Promise<any> => {
  return await authApi.get(`reminder/?contract=${id}`);
};

export const fetchReminderChecklist = async (id: string): Promise<any> => {
  return await authApi.get(`reminder/?checklist=${id}`);
};

export const deleteComment = async (id: string) => {
  return await authApi.delete(`reminder/reminder-comments/${id}/`);
};

export const editReminder = async (payload: any) => {
  const { id, body } = payload;
  return await authApi.patch(`reminder/${id}/`, body);
};

export const createReminder = async (payload: any) => {
  return await authApi.post('reminder/', payload);
};

export const fetchReminderStatistic = async (params: string): Promise<any> => {
  return await authApi.get(`reminder/statistics/${params}`);
};

export const fetchLenderDetailsOptions = async () => {
  return await authApi.get('contracts/lender_details/');
};
