import React, { forwardRef, useImperativeHandle, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { ICellEditorParams } from 'ag-grid-community';
import { useFormContext } from 'react-hook-form';

import { getDataType } from '../../axiosClient/dictionaryApi';
import { IOptions } from '../../interfaces/IOptions';
import RISelectComponent from '../SelectComponent';

const GridSelectComponent = forwardRef((props: ICellEditorParams, ref) => {
  const { control, watch } = useFormContext();
  const createInitialState = () => props?.data?.data_type?.id || '';

  const initialState = createInitialState();

  const { data } = useQuery({
    queryKey: ['data_type'],
    queryFn: async () => {
      const response = await getDataType();
      return response.results;
    },
  });

  const data_type = watch('data_type');

  const data_type_name = useMemo(() => {
    const filtered: IOptions[] =
      data?.filter((value: IOptions) => value.id === data_type) || [];
    return filtered[0]?.name;
  }, [data, data_type]);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return {
          value: data_type,
          name: data_type_name,
        };
      },
    };
  });

  return (
    <RISelectComponent
      key={props?.data?.id || 'new-data-type'}
      label=""
      name="data_type"
      control={control}
      options={data}
      value={initialState}
      autoCompleteStyle={{ width: 200, background: 'white' }}
    />
  );
});

GridSelectComponent.displayName = 'GridSelectComponent';

export default GridSelectComponent;
