import React, { Dispatch, SetStateAction } from 'react';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Box, IconButton, Typography } from '@mui/material';

interface IProps {
  title: string;
  setIsSearch: Dispatch<SetStateAction<boolean>>;
  width?: string;
}

export default function TableHeaderWithSearch(props: IProps) {
  const { title, setIsSearch, width } = props;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography sx={{ width: width || '120px' }}>{title}</Typography>
      <IconButton size="small" onClick={() => setIsSearch(true)}>
        <SearchOutlinedIcon aria-label="search" />
      </IconButton>
    </Box>
  );
}
