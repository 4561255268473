/* eslint-disable @typescript-eslint/no-explicit-any */
import { api } from './api';
import { IDraft } from '../../interfaces/IDraft';
import { IObject } from '../../interfaces/IObject';

export const contractApi = api.injectEndpoints({
  endpoints: (build) => ({
    getContract: build.query<IDraft, string>({
      query: (id) => `contracts/${id}/`,
      providesTags: (_result, _err, id) => {
        return [{ type: 'Contract' as const, id }];
      },
    }),
    getDocument: build.query({
      query: (body: IObject) => ({
        url: 'azure/presigned-url/',
        method: 'POST',
        body,
      }),
    }),
    getExtractedData: build.query<any, string>({
      query: (id) => `contracts/${id}/extracted/`,
      providesTags: (_result, _err, id: string) => {
        return [{ type: 'sentence_data' as const, id }];
      },
    }),
    getCurrencyList: build.query<any, any>({
      query: () => 'admin/currency/',
    }),
    getInsightsSection: build.query<any, string>({
      query: (id) => `contracts/clause_types/${id}/`,
      providesTags: (_result, _err, id) => {
        return [{ type: 'insights_section' as const, id }];
      },
    }),
    getErrorProne: build.query<{ success: boolean }, void>({
      query: () => 'error-prone',
    }),
  }),
});

export const {
  useGetContractQuery,
  useGetErrorProneQuery,
  useGetDocumentQuery,
  useGetExtractedDataQuery,
  useGetCurrencyListQuery,
  useGetInsightsSectionQuery,
} = contractApi;

export const {
  endpoints: {
    getContract,
    getDocument,
    getExtractedData,
    getCurrencyList,
    getInsightsSection,
  },
} = contractApi;
