/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useMemo, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {
  Box,
  Card,
  IconButton,
  InputAdornment,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import { Control, useFormContext } from 'react-hook-form';
import { useDebounce } from 'use-debounce';

import CheckboxButtonGroup from '../../../components/CheckboxButtonGroup';
import ControlledTextField from '../../../components/ControlledTextField';
import { propertyExists } from '../../../components/utils';
import CheckBoxFilled from '../../../Icons/CheckFilled';
import { filterHeaderStyle, scrollContainerSx } from '../../Filter/StaticData';

interface IProps {
  label: string;
  control: Control;
  filterData: any;
  clause: string;
  propertyKey: string;
  canSearch?: boolean;
}

const FilterWithSearch: FC<IProps> = ({
  label,
  control,
  filterData,
  clause,
  canSearch = false,
  propertyKey,
}) => {
  const [data, setData] = useState<any>([]);
  const [searchData, setSearchData] = useState<any>();

  const { watch, setValue } = useFormContext();

  const search_value = watch(`search_${clause}`) || null;

  const [searchValue] = useDebounce(search_value, 1000);

  const isExist = useMemo(() => {
    let exist = false;
    if (propertyExists(filterData, propertyKey)) {
      exist = true;
    }
    return exist;
  }, [filterData, propertyKey]);

  const clause_data = useMemo(() => {
    const clauseData: any = filterData ? [] : null;

    if (isExist) {
      const clauseProperty = filterData[propertyKey];
      for (const key in clauseProperty) {
        if (key !== 'cannot_determine') {
          const newObj = {
            label: key,
            count: clauseProperty[key],
          };
          clauseData.push(newObj);
        }
      }
    }

    const sortedData = clauseData?.sort(function (a: any, b: any) {
      return b.count - a.count;
    });
    return sortedData;
  }, [filterData, propertyKey]);

  useEffect(() => {
    if (canSearch) {
      if (searchValue) {
        const clauseData = [...clause_data];
        const filteredData = clauseData.filter((data: any) =>
          data.label.includes(searchValue)
        );
        setData(filteredData);
      } else {
        setData(clause_data);
      }
    }
  }, [filterData, searchValue, canSearch, clause_data]);

  if (isExist && clause_data.length) {
    return (
      <Card>
        {canSearch ? (
          <Box sx={filterHeaderStyle}>
            {searchData ? (
              <ControlledTextField
                label={`Search ${label}`}
                name={`search_${clause}`}
                fullWidth
                control={control}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{ color: '#88305F' }}
                        onClick={() => {
                          setSearchData(false);
                          setValue(`search_${clause}`, '');
                        }}
                        edge="end"
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography fontWeight={600}>{label}</Typography>
                <IconButton onClick={() => setSearchData(true)}>
                  <SearchOutlinedIcon />
                </IconButton>
              </Stack>
            )}
          </Box>
        ) : (
          <Typography sx={filterHeaderStyle}>{label}</Typography>
        )}

        <Stack gap={1} sx={scrollContainerSx}>
          <CheckboxButtonGroup
            name={clause}
            control={control}
            options={canSearch ? data || [] : clause_data || []}
            labelKey="label"
            valueKey="label"
            checkBoxProps={{
              checkedIcon: (
                <CheckBoxFilled style={{ fill: 'none !important' }} color="#88305F" />
              ),
            }}
            renderCustomComponent={(value: number) => (
              <LinearProgress
                variant="determinate"
                value={(value / filterData.total) * 100}
                sx={{
                  background: 'transparent',
                  borderBottom: '1px solid #D1D3D4',
                }}
              />
            )}
            labelProps={{
              sx: {
                padding: '2px 2px 2px 10px',
              },
            }}
          />
        </Stack>
      </Card>
    );
  } else {
    return null;
  }
};

export default FilterWithSearch;
