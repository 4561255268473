export const currencyList = [
  {
    id: 1,
    name: 'SR Saudi riyal',
    type: 'SR',
  },
  {
    id: 2,
    name: '£ Saint Helenian Pound',
    type: 'Saint',
  },
  {
    id: 3,
    name: '₹ Indian Rupee',
    type: 'Rupee',
  },
  {
    id: 4,
    name: '£ Pound Sterling',
    type: 'Pound',
  },
  {
    id: 5,
    name: '$ Dollar',
    type: 'USD',
  },
  {
    id: 6,
    name: '￥ Japanese yen',
    type: 'yen',
  },
  {
    id: 7,
    name: '€ Euro',
    type: 'Euro',
  },
];

export const periodList = ['Years', 'Months', 'Days'];

export const DurationList = [
  {
    id: 1,
    duration: 'Days',
  },
  {
    id: 2,
    duration: 'Months',
  },
  {
    id: 3,
    duration: 'Years',
  },
];
