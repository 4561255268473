const chipTheme = {
  styleOverrides: {
    root: {
      padding: '4px 12px',
      height: '24px',
    },
    label: {
      fontWeight: '500',
      fontSize: '11px',
    },
  },
};

export default chipTheme;
