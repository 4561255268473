import React, { FC, useCallback, useState } from 'react';

import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';
import { Button, Stack, StackProps, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';

import CustomModal from '../../../components/CustomModal';
import { getLocalStorage } from '../../../hooks/authentication';
import { handleSeeMoreAndLess } from '../../../hooks/Helper';
import { IClauseItem } from '../../../interfaces/IClauseItem';
import { IContract } from '../../../interfaces/IContract';
import { IUser } from '../../../interfaces/IUser';
import ReminderForm from '../Components/Reminders/ReminderForm';
import { SentencesData } from '../State/DocumentState';
import { getPara } from '../Utils/ClauseTypeUtils';

interface IProps extends StackProps {
  clauseItem: IClauseItem;
  onClick: VoidFunction;
  index: number;
  sentenceData: SentencesData;
  isActive: boolean;
  deleteClause?: VoidFunction;
  subTitle?: string | null;
  sentenceLevel?: boolean;
  phraseLevel?: boolean;
  para?: string;
  contractData?: IContract;
  sectionName?: string;
}

const ClauseCard: FC<IProps> = ({
  clauseItem,
  index,
  sentenceData,
  isActive,
  deleteClause,
  subTitle,
  sentenceLevel,
  phraseLevel,
  para,
  contractData,
  sectionName,
  ...rest
}) => {
  const user_data = React.useMemo(() => getLocalStorage('user_profile'), []);
  const [showFullText, setShowFullText] = useState<{ id: number | null; show: boolean }>({
    id: null,
    show: false,
  });
  const [openForm, setOpenForm] = useState<boolean>(false);

  const handleToggle = (id: number) => {
    setShowFullText({
      id: id,
      show: !showFullText?.show,
    });
  };

  const getText = useCallback(() => {
    if (para) {
      return para;
    } else if (sentenceLevel) {
      return (
        clauseItem.sentence ||
        getPara(
          sentenceData,
          clauseItem.para_id,
          clauseItem.sentence_id,
          clauseItem.table && clauseItem.table[0]?.row,
          clauseItem.table && clauseItem.table[0]?.column
        )
      );
    } else if (phraseLevel) {
      return clauseItem.original_context || '';
    } else {
      return (
        clauseItem?.para ||
        getPara(
          sentenceData,
          clauseItem.para_id,
          clauseItem.sentence_id,
          clauseItem.table && clauseItem.table[0]?.row,
          clauseItem.table && clauseItem.table[0]?.column
        )
      );
    }
  }, [clauseItem, phraseLevel, sentenceData, sentenceLevel, para]);

  const userIsOwner = contractData?.owners?.some(
    (ownerItem: IUser) => ownerItem?.id === user_data?.id
  );

  const { enqueueSnackbar } = useSnackbar();

  const handleObligationClick = () => {
    if (!userIsOwner) {
      enqueueSnackbar(
        'You are not the owner of the contract. Please contact owner to mark an Obligation. ',
        {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    } else {
      setOpenForm(true);
    }
  };

  return (
    <>
      <Stack
        sx={{
          background: isActive ? '#FFFAFA' : '#FFF7FA',
          padding: '8px 16px',
          margin: '8px 0',
          borderRadius: '12px',
          border: isActive ? '1px solid #88305F' : 'none',
          cursor: 'pointer',
        }}
        gap="8px"
        {...rest}
      >
        <Stack
          direction="row"
          sx={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Typography fontWeight="600" fontSize="14px">{`#${index + 1}`}</Typography>
          <Stack direction="row" gap={1} sx={{ alignItems: 'center' }}>
            <Button
              sx={{ padding: 0 }}
              onClick={(e) => {
                e.stopPropagation();
                handleObligationClick();
              }}
              startIcon={<DateRangeOutlinedIcon />}
            >
              Mark an Obligation
            </Button>
            <Button
              sx={{ padding: 0 }}
              onClick={(e) => {
                e.stopPropagation();
                deleteClause?.();
              }}
              startIcon={<DeleteSweepOutlinedIcon />}
            >
              Remove
            </Button>
          </Stack>
        </Stack>
        {subTitle && (
          <Typography variant="body1" fontWeight={600}>
            {subTitle}
          </Typography>
        )}
        <Typography variant="body1" sx={{ overflowWrap: 'break-word' }}>
          {showFullText?.id === clauseItem?.para_id && showFullText?.show
            ? getText()
            : handleSeeMoreAndLess(getText())}
          {getText()?.split(/\s+/)?.length > 20 && (
            <span
              onClick={() => handleToggle(clauseItem?.para_id)}
              style={{ cursor: 'pointer' }}
            >
              {showFullText?.id === clauseItem?.para_id && showFullText?.show
                ? ' See less'
                : 'See more'}
            </span>
          )}
        </Typography>
      </Stack>
      {openForm && (
        <CustomModal
          maxWidth="md"
          title="Mark an Obligation"
          open={openForm}
          handleClose={() => setOpenForm(false)}
        >
          <ReminderForm
            onClose={() => setOpenForm(false)}
            contractData={contractData}
            user_data={user_data}
            sectionName={sectionName}
            clauseItem={clauseItem}
          />
        </CustomModal>
      )}
    </>
  );
};

export default ClauseCard;
