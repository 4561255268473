import React, { useCallback, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';

import { fetchGroups } from '../../axiosClient/authApi';
import {
  addContract,
  createUmbrellaProject,
  getContractType,
  getUmbrellaProjects,
} from '../../axiosClient/contractsApi';
import CustomChip from '../../components/CustomChip';
import CustomModal from '../../components/CustomModal';
import RISelectComponent from '../../components/SelectComponent';
import UploadDocComponent from '../../components/UploadDocComponent';
import { IContractType } from '../../interfaces/IContractType';
import { IOptions } from '../../interfaces/IOptions';

interface IProps {
  open: boolean;
  handleClose: VoidFunction;
}

const CreateDraft: React.FC<IProps> = ({ open, handleClose }) => {
  const methods = useForm({
    defaultValues: {
      groups: [1],
    },
  });

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = methods;

  const [uploadedFile, setUploadedFile] = useState<File[]>([]);

  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const { data: groupListData, isLoading: groupLoading } = useQuery({
    queryKey: ['GroupList'],
    queryFn: fetchGroups,
    select: (response) => response?.results,
  });

  const { data: contractTypes, isLoading: contractLoading } = useQuery({
    queryKey: ['contract_types'],
    queryFn: getContractType,
    select: (response) =>
      response?.results?.map((data: IContractType) => {
        const item = { id: data.id, name: data.name };
        return item;
      }),
  });

  const { data: projects, isLoading: projectLoading } = useQuery({
    queryKey: ['projects'],
    queryFn: getUmbrellaProjects,
    select: (response) => {
      return response?.results;
    },
  });

  const { mutate: createProjectMutation } = useMutation({
    mutationKey: ['add_project'],
    mutationFn: createUmbrellaProject,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['projects'] });
    },
  });

  const { mutate, status } = useMutation({
    mutationKey: ['add_draft'],
    mutationFn: addContract,
    onSuccess: () => {
      enqueueSnackbar('Contract added successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({
        queryKey: ['contracts'],
      });
      handleClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to add Contract!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const onSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data: any) => {
      const file = uploadedFile[0];
      const fileData = file?.name?.split('.');
      data.file_name = file?.name;
      data.groups = `[${data.groups}]`;
      data.projects = `["${data.projects}"]`;
      const blob = new Blob([file], { type: 'octet' });
      const newFile = new File([blob], data.file_name, {
        type: fileData[fileData.length - 1],
      });

      data.file = newFile;
      mutate(data);
    },
    [uploadedFile]
  );

  return (
    <CustomModal
      title="Add new Contract"
      open={open}
      maxWidth="md"
      handleClose={handleClose}
    >
      <FormProvider {...methods}>
        <Stack
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          mt={2}
          spacing={2}
          sx={{ width: '500px', padding: '30px 10px' }}
        >
          <RISelectComponent
            control={control}
            name="projects"
            required
            options={projects}
            label="Project Name"
            loading={projectLoading}
            canCreateNew
            addNewValue={(value) => createProjectMutation({ name: value })}
          />
          <UploadDocComponent
            label="Upload draft"
            name="file"
            required
            allowedFileTypes={['.docx', '.pdf']}
            files={uploadedFile}
            setFiles={setUploadedFile}
          />
          <RISelectComponent
            name="contract_type"
            control={control}
            label="Document Type"
            required
            options={(contractTypes as IOptions[]) || []}
            loading={contractLoading}
          />
          <RISelectComponent
            name="groups"
            control={control}
            // required
            label="Prescribing Department"
            options={groupListData || []}
            loading={groupLoading}
            isMultiselect={true}
            renderCustomComponent={(value: IOptions | string | number) => (
              <CustomChip
                key={(value as IOptions)?.id}
                label={(value as IOptions)?.name}
              />
            )}
          />
          <LoadingButton
            type="submit"
            variant="contained"
            loading={status === 'pending'}
            fullWidth
            disabled={!isValid || uploadedFile.length === 0}
          >
            Add
          </LoadingButton>
        </Stack>
      </FormProvider>
    </CustomModal>
  );
};

export default CreateDraft;
