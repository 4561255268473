import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, InputBase } from '@mui/material';

interface IProps {
  setIsSearch: VoidFunction;
  renderCustomInput?: () => React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleChange?: any;
  placeholder?: string;
}

const TableSearchInputComponent = (props: IProps) => {
  const { setIsSearch, renderCustomInput, handleChange, placeholder } = props;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {renderCustomInput ? (
        <Box
          sx={{
            minWidth: '180px',
            maxWidth: '250px',
            display: 'flex',
            overflowX: 'scroll',
          }}
        >
          {renderCustomInput()}
        </Box>
      ) : (
        <InputBase
          sx={{ width: '80%' }}
          placeholder={placeholder}
          inputProps={{ 'aria-label': placeholder }}
          onChange={handleChange}
        />
      )}
      <IconButton
        type="button"
        sx={{ p: '1px' }}
        aria-label="search"
        onClick={setIsSearch}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

export default TableSearchInputComponent;
