import React from 'react';

import { Chip, Stack, Typography } from '@mui/material';

import { IOptions } from '../../interfaces/IOptions';
import OverflowTip from '../OverflowTip';
import PopoverPopupState from '../Popups/PopoverPopupState';

interface IProps {
  list: IOptions[];
  chipColor?: string;
  labelKey?: string;
  onClick?: VoidFunction;
  id: string;
}

const TableChipList = ({ list, chipColor, labelKey = 'name', onClick, id }: IProps) => {
  const chipLabel = labelKey ? list?.[0]?.[labelKey] : list[0];

  return (
    <Stack direction="row" spacing={0.25} sx={{ alignItems: 'center' }}>
      <Chip
        key={chipLabel as string}
        sx={{
          background: chipColor || '#C4DBFF',
          color: 'riSecondary.900',
        }}
        label={<OverflowTip value={chipLabel as string} />}
        onClick={onClick}
      />
      {list.length > 1 && (
        <PopoverPopupState
          id={id}
          renderIcon={() => (
            <Typography key="2" sx={{ color: 'riNeutralVariant.700', fontSize: '11px' }}>
              {list.length - 1}+
            </Typography>
          )}
        >
          <Stack>
            {list?.map((item: IOptions | string | number, index: number) => {
              const chipLabel = labelKey
                ? ((item as IOptions)[labelKey] as string)
                : (item as string);
              return index !== 0 && <Typography key={index}>{chipLabel}</Typography>;
            })}
          </Stack>
        </PopoverPopupState>
      )}
    </Stack>
  );
};

export default TableChipList;
