/* eslint-disable @typescript-eslint/no-explicit-any */
import { WebViewerInstance } from '@pdftron/webviewer';

export const convertPDF = async (Core: any, fileURL: string) => {
  // perform the conversion with no optional parameters
  let file;
  try {
    await Core.PDFNet.initialize();
    const buf = await Core.officeToPDFBuffer(fileURL);

    //optionally save the blob to a file or upload to a server
    const blob = new Blob([buf], { type: 'application/pdf' });
    file = blob;
  } catch (error) {
    console.error('Error', error);
    file = undefined;
  }
  return file;
};

export const download_file = (data: any, file_name?: string) => {
  const url = data.presigned_url;
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.href = url;
  a.download = `${file_name}`;
  a.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }, 0);
};

export const downloadFile = (blob: any, fileName: string, fileExt: string) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = `${fileName}.${fileExt}`;
  a.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }, 0);
};

export const highlightedSentence = (
  sentence: string,
  instance: WebViewerInstance
): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    if (!instance) {
      reject('PDFTron instance not available');
      return;
    }

    const { Core } = instance;
    const { documentViewer, Annotations, annotationManager } = Core;
    const annotations = annotationManager.getAnnotationsList();
    const searchMode =
      instance.Core.Search.Mode.PAGE_STOP | instance.Core.Search.Mode.HIGHLIGHT;

    const searchOptions = {
      fullSearch: true,
      onResult: (result: any) => {
        if (result.resultCode === instance.Core.Search.ResultCode.FOUND) {
          const highlight = new Annotations.TextHighlightAnnotation();
          result.quads.forEach((quad: any) => {
            highlight.Quads.push(quad.getPoints());
          });

          highlight.PageNumber = result.pageNum;
          annotationManager.addAnnotation(highlight);
          annotationManager.redrawAnnotation(highlight);
          annotationManager.drawAnnotations({ pageNumber: result.pageNum });

          if (documentViewer.getCurrentPage() !== result.pageNum) {
            documentViewer.setCurrentPage(result.pageNum, true);
          }
        }
      },
      onDocumentEnd: () => {
        resolve(); // Resolve the promise once highlighting is completed
      },
    };

    documentViewer.textSearchInit(sentence, searchMode, searchOptions);
    annotationManager.deleteAnnotations(annotations);
  });
};

export const handleSeeMoreAndLess = (text: string, length = 17) => {
  if (text) {
    const words = text.split(/\s+/);
    if (words?.length > length) {
      const sentence = words?.slice(0, length).join(' ');
      return `${sentence}...`;
    } else {
      return words.join(' ');
    }
  }
  return text;
};

export const formatPayloadDate = (date: Date | null): string => {
  if (!date) return '';
  const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  return localDate.toISOString().split('T')[0];
};
