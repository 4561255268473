import 'react-quill/dist/quill.snow.css';

import React from 'react';

import { FormControl } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import ReactQuill from 'react-quill';

interface Props {
  name: string;
  control: Control;
  disabled?: boolean;
  readOnly?: boolean;
}

const NotepadComponent: React.FC<Props> = ({
  name,
  control,
  disabled = false,
  readOnly = false,
}) => {
  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['bold', 'italic', 'underline'],
      [{ align: [] }],
      ['link'],
    ],
  };

  return (
    <Controller
      disabled={disabled}
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl fullWidth>
          <ReactQuill {...field} modules={modules} readOnly={readOnly} />
        </FormControl>
      )}
    />
  );
};

export default NotepadComponent;
