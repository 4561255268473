/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import * as changesets from 'json-diff-ts';
import { Controller, useForm } from 'react-hook-form';

import ControlledTextField from '../../../components/ControlledTextField';
import { RootState, useAppDispatch, useTypedSelector } from '../../../redux/store';
import {
  saveAddPhrase,
  saveDeletePhrase,
  saveHighlightedComponentType,
  saveHighlightedPhrases,
  saveHighlightedTableCells,
  savePhraseEditOption,
} from '../ContractSlice';
import {
  CurrencyType,
  editedPhrases,
  LinkPhraseRequest,
  phraseInfo,
  tableInfo,
} from '../State/DocumentState';
import { getClauseDataFormat } from '../Utils/ClauseTypeUtils';
import { isTableCell } from '../Utils/docUtils';

interface Props {
  clauseName: string;
  clauseDisplayName: string;
  savedInsight: any;
  onClose: VoidFunction;
  id: string;
  updateContract: any;
  updatedClauseData: any;
  clauseData?: any;
  parentClauseType?: any;
  rawContent: any;
  sectionType: string;
  buttonLoading: boolean;
}

interface State {
  isAddingPhrase: boolean;
  currentEditingPhrase: phraseInfo | null;
  selectedOption: CurrencyType;
  currencyValue: number;
}

const EditLinkAmountPhrase: React.FC<Props> = (props) => {
  const {
    id,
    rawContent,
    onClose,
    clauseDisplayName,
    clauseName,
    updatedClauseData,
    updateContract,
    sectionType,
    buttonLoading,
  } = props;
  const dispatch = useAppDispatch();

  const {
    sentenceData,
    componentType,
    phraseInAddMode,
    phraseEditOptionSelected,
    currencyList,
  } = useTypedSelector((state: RootState) => state.contract);

  const [state, setState] = useState<State>({
    isAddingPhrase: false,
    currentEditingPhrase: null,
    selectedOption: {
      name: '',
      type: '',
      id: -1,
    },
    currencyValue: -1,
  });

  const { control } = useForm();

  useEffect(() => {
    if (phraseInAddMode) {
      setState((prevState) => ({ ...prevState, isAddingPhrase: false }));
    }
  }, [phraseInAddMode]);

  const setCurrencyValue = (event: any) => {
    const { currencyValue } = state;
    const value = event.target.value;
    let currency: number = currencyValue;
    if (value.length === 0) {
      currency = -1;
    } else {
      if (!isNaN(value) && value.length > 0) {
        currency = Number(value);
      }
    }
    setState((prevState) => ({ ...prevState, currencyValue: currency }));
  };

  const setCurrency = (currency: CurrencyType) => {
    setState((prevState) => ({
      ...prevState,
      selectedOption: currency,
    }));
  };

  const linkToPhrase = () => {
    dispatch(savePhraseEditOption({ phraseEditOptionSelected: true }));
    setState((prevState) => ({
      ...prevState,
      isAddingPhrase: true,
      currentEditingPhrase: null,
    }));
    dispatch(saveDeletePhrase({ phraseDeleteStatus: false, phraseInDeleteMode: null }));
    dispatch(saveHighlightedTableCells({ savedHighlightedTableCells: null }));
    dispatch(
      saveHighlightedComponentType({
        componentType: componentType,
        editOptionSelected: true,
      })
    );
  };

  const editLinkedPhraseOnDoc = (phraseInfo: phraseInfo) => {
    let deletePhraseElement = '';
    if (phraseInfo && isTableCell(phraseInfo)) {
      const tempTableCell: tableInfo = {
        paraId: phraseInfo.paraId,
        rowId: phraseInfo.rowId !== null ? phraseInfo.rowId : -1,
        columnId: phraseInfo.columnId !== null ? phraseInfo.columnId : -1,
      };
      deletePhraseElement =
        'p' + phraseInfo.paraId + ';r' + phraseInfo.rowId + ';c' + phraseInfo.columnId;
      dispatch(
        saveHighlightedTableCells({ savedHighlightedTableCells: [tempTableCell] })
      );
    } else {
      deletePhraseElement = 'p' + phraseInfo?.paraId + ';w' + phraseInfo?.startWordId;
      dispatch(saveHighlightedTableCells({ savedHighlightedTableCells: null }));
    }
    const phraseElement = document.getElementById(deletePhraseElement);
    phraseElement !== undefined &&
      phraseElement !== null &&
      phraseElement.scrollIntoView({ block: 'center' }); //scroll to linked phrase
    document.documentElement.style.scrollBehavior = 'smooth';

    dispatch(
      saveDeletePhrase({ phraseDeleteStatus: true, phraseInDeleteMode: phraseInfo })
    );
    setState((prevState) => ({
      ...prevState,
      isAddingPhrase: false,
      currentEditingPhrase: phraseInfo,
    }));
    dispatch(
      saveHighlightedComponentType({
        componentType: componentType,
        editOptionSelected: true,
      })
    );
  };

  const linkPhraseOnDoc = (phraseInfo: phraseInfo | null) => {
    if (phraseInfo == null) {
      return (
        <Stack alignItems="start" width="100%">
          <Button
            variant="text"
            sx={{
              color:
                state.isAddingPhrase !== true
                  ? '#88305F'
                  : phraseEditOptionSelected === true
                    ? '#C1C1C1'
                    : '#88305F',
              padding: 0,
            }}
            onClick={() => linkToPhrase()}
          >
            Link phrase
          </Button>
        </Stack>
      );
    } else {
      return (
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Button
            variant="text"
            sx={{
              color: state.currentEditingPhrase === phraseInfo ? '#C1C1C1' : '#88305F',
              padding: 0,
            }}
            onClick={() => editLinkedPhraseOnDoc(phraseInfo)}
          >
            Edit linked phrase
          </Button>
          <Typography fontSize="14px">{phraseInfo === null ? 0 : 1}</Typography>
        </Stack>
      );
    }
  };

  const onCancel = () => {
    onClose();
    dispatch(saveDeletePhrase({ phraseDeleteStatus: false, phraseInDeleteMode: null }));
    dispatch(saveAddPhrase({ phraseAddStatus: false, phraseInAddMode: null }));
    dispatch(savePhraseEditOption({ phraseEditOptionSelected: false }));
    dispatch(saveHighlightedPhrases({ savedHighlightedPhrases: null }));

    dispatch(
      saveHighlightedComponentType({
        componentType: '',
        editOptionSelected: false,
      })
    );
    dispatch(saveHighlightedTableCells({ savedHighlightedTableCells: null }));
    setState({
      isAddingPhrase: false,
      currentEditingPhrase: null,
      selectedOption: {
        name: '',
        type: '',
        id: -1,
      },
      currencyValue: -1,
    });
  };

  const getSaveStatus = () => {
    const { currencyValue, selectedOption } = state;
    let saveStatus = false;
    if (phraseInAddMode) {
      if (
        phraseInAddMode.paraId &&
        phraseInAddMode.paraId !== -1 &&
        currencyValue !== -1 &&
        selectedOption.id !== -1
      ) {
        saveStatus = true;
      }
    }
    return saveStatus;
  };

  const getTempPhrase = () => {
    const { selectedOption, currencyValue } = state;

    let tempPhraseRequest: LinkPhraseRequest = {
      mode: '',
      editedPhrases: { upsert: [], deleted: [], bi: '' },
    };
    let addedDeletedPhrases: editedPhrases = {
      upsert: [],
      deleted: [],
      bi: '',
    };
    const newPhrase: phraseInfo = !phraseInAddMode
      ? {
          paraId: -1,
          startWordId: -1,
          endWordId: -1,
          startSentenceId: -1,
          endSentenceId: -1,
          rowId: -1,
          columnId: -1,
          phrase: '',
          total: -1,
          currencyId: -1,
        }
      : phraseInAddMode;
    addedDeletedPhrases = {
      upsert: [
        {
          paraId: newPhrase.paraId,
          startWordId: newPhrase.startWordId,
          endWordId: newPhrase.endWordId,
          startSentenceId: newPhrase.startSentenceId,
          endSentenceId: newPhrase.endSentenceId,
          rowId: newPhrase.rowId,
          columnId: newPhrase.columnId,
          phrase: newPhrase.phrase,
          total: currencyValue,
          currencyId: selectedOption.id,
          currency: selectedOption.name,
        },
      ],
      deleted: [],
      bi: clauseName,
    };

    tempPhraseRequest = {
      editedPhrases: addedDeletedPhrases,
      mode: 'manual',
    };

    return tempPhraseRequest;
  };

  const onSave = () => {
    let newData = updatedClauseData;
    const tempPhraseRequest = getTempPhrase();

    const addedData = tempPhraseRequest.editedPhrases.upsert;

    for (let i = 0; i < addedData.length; i++) {
      if (addedData[i]?.startSentenceId === addedData[i]?.endSentenceId) {
        newData = getClauseDataFormat(
          'add',
          componentType,
          addedData[i],
          newData,
          clauseName,
          sentenceData
        );
      }
    }

    const diff = changesets.diff(rawContent, newData, {
      children: '$index',
    });

    if (diff.length > -1) {
      updateContract({ id, diff, updatedClauseData: newData, type: sectionType });
    }

    dispatch(saveDeletePhrase({ phraseDeleteStatus: false, phraseInDeleteMode: null }));
    dispatch(saveAddPhrase({ phraseAddStatus: false, phraseInAddMode: null }));
    dispatch(savePhraseEditOption({ phraseEditOptionSelected: false }));
    dispatch(saveHighlightedPhrases({ savedHighlightedPhrases: null }));

    dispatch(
      saveHighlightedComponentType({
        componentType: '',
        editOptionSelected: false,
      })
    );
    dispatch(saveHighlightedTableCells({ savedHighlightedTableCells: null }));
    onClose();
    setState({
      isAddingPhrase: false,
      currentEditingPhrase: null,
      selectedOption: {
        name: '',
        type: '',
        id: -1,
      },
      currencyValue: -1,
    });
  };

  return (
    <Box
      sx={{
        background: '#FFECF1',
        boxShadow: 'none',
        borderRadius: '15px',
        padding: '10px 16px',
      }}
    >
      <Typography fontWeight={600} mb={1}>
        Add / Edit {clauseDisplayName}
      </Typography>
      <Stack width="100%" className="edit-clause-select" spacing={2}>
        <Stack direction="row" width="100%" spacing={1}>
          <ControlledTextField
            name="value"
            type="number"
            control={control}
            label="Value"
            value={state.currencyValue === -1 ? '' : state.currencyValue}
            onChange={setCurrencyValue}
            fullWidth
            onKeyPress={(event) => {
              if (event?.key === '-' || event?.key === '+') {
                event.preventDefault();
              }
            }}
          />
          <Controller
            name="currency"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel htmlFor="Select-Currency">Currency</InputLabel>
                <Select
                  {...field}
                  required
                  label="Currency"
                  variant="outlined"
                  inputProps={{
                    id: 'Select-currency',
                  }}
                  value={state.selectedOption}
                >
                  {currencyList.map((currencyItem: any, i: number) => (
                    <MenuItem
                      key={i}
                      value={currencyItem}
                      onClick={() => setCurrency(currencyItem)}
                    >
                      {currencyItem.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Stack>

        {linkPhraseOnDoc(phraseInAddMode)}

        <Stack width="100%">
          <Typography fontSize="14px" fontWeight={700}>
            How to link a phrase ?
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            1). Click on `&quot;`Link Phrase`&quot;`.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            2). Hover over the text in the contract on left.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            3). Select and copy the desired phrase.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            4). Click on the copy icon.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            5). Confirm your selection by clicking on the Save button below.
          </Typography>
        </Stack>
        <Stack direction="row">
          <LoadingButton
            variant="contained"
            startIcon={<CheckIcon />}
            onClick={onSave}
            disabled={!getSaveStatus()}
            loading={buttonLoading}
          >
            Save
          </LoadingButton>
          <Button variant="outlined" onClick={onCancel} startIcon={<CloseIcon />}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default EditLinkAmountPhrase;
