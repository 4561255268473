import React, { lazy } from 'react';

import { createBrowserRouter } from 'react-router-dom';

import DraftLayout from '../components/layout/DraftLayout';
import UserAuthChecker from '../HOC/auth';
import Contracts from '../Pages/Contracts';
import ViewContract from '../Pages/Contracts/ViewContract';
import Dictionary from '../Pages/Dictionary';
import Filter from '../Pages/Filter';
import Reminder from '../Pages/Reminder/ReminderComponent';
import Search from '../Pages/Search';
import Root from '../Root';

const DataGrid = lazy(() => import('../Pages/Dictionary/DataGrid'));

export const privateRouter = createBrowserRouter([
  {
    path: '*',
    Component: UserAuthChecker(Root),
    children: [
      { path: 'contracts', element: <Contracts /> },
      { path: 'dictionary', element: <Dictionary /> },
      { path: 'dictionary/:id', element: <DataGrid /> },
      { path: 'reminder', element: <Reminder /> },
      {
        path: 'filter',
        element: <Filter />,
      },
      {
        path: 'contracts',
        element: <DraftLayout />,
        children: [{ path: 'view-contract/:id', element: <ViewContract /> }],
      },
      {
        path: 'search/:query',
        element: <Search />,
      },
    ],
  },
]);
