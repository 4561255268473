/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useMemo } from 'react';

import { Card, LinearProgress, Stack, Typography } from '@mui/material';
import { Control } from 'react-hook-form';

import FilterWithSearch from './FilterWithSearch';
import CustomChip from '../../../components/CustomChip';
import RISelectComponent from '../../../components/SelectComponent';
import { propertyExists } from '../../../components/utils';
import { filterHeaderStyle } from '../StaticData';

interface IProps {
  clause_type: string[];
  clause_type_absent: string[];
  control: Control;
  insightsData: any;
  isLoading: boolean;
  ClauseTypeMap: any;
}

const ClauseTypeFilter: FC<IProps> = ({
  clause_type,
  clause_type_absent,
  control,
  insightsData,
  isLoading,
  ClauseTypeMap,
}) => {
  const clauseTypes = useMemo(() => {
    const clauses: any = [];
    if (insightsData) {
      if (propertyExists(insightsData, 'clause_type') && ClauseTypeMap) {
        const data: any = [];

        for (const key in insightsData['clause_type']) {
          const newObj = {
            label: key,
            presentCount: insightsData['clause_type'][key].present,
            absentCount: insightsData['clause_type'][key].absent,
          };
          data.push(newObj);
        }
        data?.map((item: any) => {
          const updatedData = {
            ...item,
            label: ClauseTypeMap[item.label] || item.label,
            index: item.label,
          };
          clauses.push(updatedData);
        });
      }
    }
    return clauses;
  }, [insightsData, ClauseTypeMap]);

  const clauseTypesPresent = useMemo(() => {
    let clauses = clauseTypes.filter((item: any) => item.presentCount > 0);

    if (clause_type_absent) {
      clauses = [];
      clauseTypes.map((data: any) => {
        const index = clause_type_absent?.findIndex(
          (clause: string) => clause === data.index
        );
        if (index === -1) {
          clauses.push(data);
        }
      });
    }
    return clauses;
  }, [clauseTypes, clause_type_absent]);

  const clauseTypesAbsent = useMemo(() => {
    let clauses = clauseTypes.filter((item: any) => item.absentCount > 0);
    if (clause_type) {
      clauses = [];
      clauseTypes.map((data: any) => {
        const index = clause_type?.findIndex((clause: string) => clause === data.index);
        if (index === -1) {
          clauses.push(data);
        }
      });
    }
    return clauses;
  }, [clauseTypes, clause_type]);

  return (
    <Stack gap={2}>
      <Card>
        <Typography sx={filterHeaderStyle}>Clause Type</Typography>
        <Stack gap={2} padding={2}>
          <RISelectComponent
            name="clause_type"
            label="Clause Present"
            loading={isLoading}
            control={control}
            options={clauseTypesPresent || []}
            valueKey="index"
            labelKey="label"
            countKey="presentCount"
            isMultiselect
            renderCustomComponent={(value: any) => (
              <CustomChip key={value?.index} label={value?.label} />
            )}
            renderProgress={(value) => (
              <LinearProgress
                variant="determinate"
                value={(value / insightsData.total) * 100}
                sx={{
                  background: 'transparent',
                  borderBottom: '1px solid #D1D3D4',
                }}
              />
            )}
          />
          <RISelectComponent
            name="clause_type_absent"
            label="Clause Absent"
            loading={isLoading}
            control={control}
            options={clauseTypesAbsent || []}
            valueKey="index"
            labelKey="label"
            countKey="absentCount"
            isMultiselect
            renderCustomComponent={(value: any) => (
              <CustomChip key={value?.index} label={value?.label} />
            )}
            renderProgress={(value) => (
              <LinearProgress
                variant="determinate"
                value={(value / insightsData.total) * 100}
                sx={{
                  background: 'transparent',
                  borderBottom: '1px solid #D1D3D4',
                }}
              />
            )}
          />
        </Stack>
      </Card>
      <FilterWithSearch
        label="Contract Type"
        control={control}
        filterData={insightsData}
        clause="contract_type"
        propertyKey="contract_type_counts"
      />
      <FilterWithSearch
        label="Groups"
        control={control}
        filterData={insightsData}
        clause="groups"
        propertyKey="groups_counts"
      />

      <FilterWithSearch
        label="Projects"
        control={control}
        filterData={insightsData}
        clause="projects"
        propertyKey="projects_counts"
      />
      <FilterWithSearch
        label="Lender Details"
        control={control}
        filterData={insightsData}
        clause="lender_details"
        propertyKey="lender_details_count"
      />
      <FilterWithSearch
        label="Borrower Details"
        control={control}
        filterData={insightsData}
        clause="borrower_details"
        propertyKey="borrower_details_counts"
      />
    </Stack>
  );
};

export default ClauseTypeFilter;
