/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Drawer, IconButton, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { getDownloadUrl } from '../../../axiosClient/contractsApi';
import ListSkeleton from '../../../components/ListSkeleton';
import PdfView from '../../../components/PDFView/PdfView';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  documentId?: string;
  documentLink?: string;
}

const ViewDocumentDrawer: React.FC<Props> = ({
  open,
  onClose,
  documentId = '',
  documentLink,
}) => {
  const [accessUrl, setAccessUrl] = useState<string>('');
  const [instance, setInstance] = useState<any>();

  const { data, isLoading: viewLoading } = useQuery({
    queryKey: ['get_access_url', documentId],
    queryFn: async () => await getDownloadUrl({ contract_id: documentId }),
    select: (response: any) => {
      return response;
    },
    enabled: !!documentId,
  });

  useEffect(() => {
    if (data) {
      setAccessUrl(data.presigned_url);
    }
  }, [data]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': {
          width: '960px',
        },
      }}
    >
      {viewLoading ? (
        <Box px={2}>
          <ListSkeleton />
        </Box>
      ) : (
        <Stack spacing={2} width="900px" marginX="15px">
          <Box display="flex" alignItems="center" width="900px">
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
            <Typography>View Document</Typography>
          </Box>
          <PdfView
            fileLink={documentLink ? documentLink : accessUrl}
            instance={instance}
            setInstance={setInstance}
          />
        </Stack>
      )}
    </Drawer>
  );
};

export default ViewDocumentDrawer;
