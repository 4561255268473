/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';

import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { Box, IconButton, Stack, Tab, Tabs } from '@mui/material';
import Grid from '@mui/material/Grid2';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';

import AboutThisContract from './Components/AboutThisContract';
import ExcelTable from './Components/ExcelTable';
import ReminderTab from './Components/Reminders';
import Sections from './Components/Sections';
import SentenceRenderer from './Components/SentenceRenderer';
import { a11yProps } from '../../components/CustomTabPanel';
import { riPrimary } from '../../components/Theme/colors';
import { IOptions } from '../../interfaces/IOptions';
import {
  useGetContractQuery,
  useGetCurrencyListQuery,
  useGetExtractedDataQuery,
  useGetInsightsSectionQuery,
} from '../../redux/services/contractApi';

const innerTabStyle = {
  backgroundColor: riPrimary[10],
  borderRadius: '20px',
  paddingX: '15px',
};

const contractTabs = [
  {
    name: 'About this contract',
    id: 'contract',
  },
  {
    name: 'Insights',
    id: 'insights',
  },
  { name: 'Obligation', id: 'reminders' },
];

const ViewContract = () => {
  const { id } = useParams();

  const [activeTab, setActiveTab] = useState<string>('contract');
  const [tableTopInfo, setTableTopInfo] = useState<any>([]);
  const userName = localStorage.getItem('user_name');

  const { data: sections, isLoading } = useGetInsightsSectionQuery(id || '', {
    skip: !id,
  });

  useGetCurrencyListQuery(undefined);

  const { data: contract } = useGetContractQuery(id || '', {
    skip: !id,
  });

  useGetExtractedDataQuery(id || '', {
    skip: !id,
  });

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  const fileName = useMemo(() => {
    const file_name_array = contract?.title?.split('.');
    return file_name_array?.[0] || '';
  }, [contract]);

  useEffect(() => {
    const date = new Date().toISOString();

    setTableTopInfo([
      {
        key: 'Downloaded On:',
        value: `${dayjs(date).format('DD/MM/YYYY, h:mm A')} IST`,
      },
      { key: 'Downloaded By:', value: userName ? userName : '' },
      { key: 'File Name:', value: `${fileName}-insights` },
    ]);
  }, [fileName]);

  return (
    <Grid container spacing={2}>
      <Grid size={{ sm: 6 }}>
        <Box sx={innerTabStyle}>
          <Stack spacing={1} direction="row" height="48px">
            <IconButton>
              <ZoomInIcon />
            </IconButton>
            <IconButton>
              <ZoomOutIcon />
            </IconButton>
          </Stack>
        </Box>
      </Grid>
      <Grid size={{ sm: 6 }} sx={{ zIndex: 3 }}>
        <Tabs
          value={activeTab}
          onChange={handleChangeTab}
          aria-label="Contracts tabs"
          variant="scrollable"
          scrollButtons={false}
          sx={innerTabStyle}
        >
          {contractTabs?.map((tab: IOptions, index: number) => (
            <Tab key={index} label={tab.name} value={tab.id} {...a11yProps(tab.id)} />
          ))}
        </Tabs>
      </Grid>
      <Grid size={{ sm: 6 }}>
        <Box maxHeight="76vh" overflow="auto">
          <SentenceRenderer />
        </Box>
      </Grid>
      <Grid size={{ sm: 6 }}>
        <Stack
          spacing={1}
          sx={{
            backgroundColor: 'riTertiary.50',
            borderRadius: '20px',
            padding: '10px 16px 20px',
          }}
        >
          {activeTab === 'contract' && <AboutThisContract />}
          {activeTab === 'insights' && (
            <Box maxHeight="76vh" overflow="auto">
              <Sections isLoading={isLoading} />
            </Box>
          )}
          {activeTab === 'reminders' && <ReminderTab fileId={id || ''} />}
        </Stack>
      </Grid>
      {sections && (
        <ExcelTable id={id || ''} sections={sections} tableTopInfo={tableTopInfo} />
      )}
    </Grid>
  );
};

export default ViewContract;
