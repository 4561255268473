/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { Box, Table, TableBody, TableCell, TableRow } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Tooltip from '@mui/material/Tooltip';

import SentenceLoader from './SentenceLoader';
import { RootState, useAppDispatch, useTypedSelector } from '../../../redux/store';
import {
  saveAddPhrase,
  saveDateInAddEditMode,
  saveDeletePhrase,
  saveEventInAddEdit,
  saveHighlightedEvents,
  saveHighlightedId,
  saveHighlightedPhrases,
  saveHighlightedSentences,
  saveHighlightedTableCells,
  savePhraseEditOption,
} from '../ContractSlice';
import {
  eventInfo,
  Table as ITable,
  phraseInfo,
  sentenceInfo,
  tableInfo,
} from '../State/DocumentState';
import {
  deletePhraseFromEventArray,
  deletePhraseFromPhraseArray,
  deleteSentence,
  deleteTableCell,
  deleteTableCellFromPhraseArray,
  hasSentence,
  hasTableCell,
  isEndWordInPhrase,
  isWordInPhrase,
  replaceEventInEventArray,
} from '../Utils/docUtils';

const SentenceRenderer = () => {
  const [startWordId, setStartWordId] = useState<number>(-1);
  const [endWordId, setEndWordId] = useState<number>(-1);
  const [startWordParaId, setStartWordParaId] = useState<number>(-1);
  const [endWordParaId, setEndWordParaId] = useState<number>(-1);
  const [startSentenceId, setStartSentenceId] = useState<number>(-1);
  const [endSentenceId, setEndSentenceId] = useState<number>(-1);

  const dispatch = useAppDispatch();
  const {
    sentenceData,
    sentenceLoader,
    componentType,
    highlightedId,
    savedHighlightedSentences,
    savedHighlightedPhrases,
    phraseEditOptionSelected,
    editOptionSelected,
    phraseDeleteStatus,
    phraseInDeleteMode,
    phraseInAddMode,
    savedHighlightedTableCells,
    savedHighlightedDates,
    dateInAddEditMode,
    dateEditingStatus,
    savedHighlightedEvents,
    eventInAddEdit,
    eventEditingStatus,
  } = useTypedSelector((state: RootState) => state.contract);

  const [highlightedIdLocal, setHighlightedIdLocal] = useState<number | null>(null);

  const [highlightedSentenceIdLocal, setHighlightedSentenceIdLocal] = useState<
    number | null
  >(null);
  const [highlightedColumnIdLocal, setHighlightedColumnIdLocal] = useState<number | null>(
    null
  );
  const [highlightedRowIdLocal, setHighlightedRowIdLocal] = useState<number | null>(null);
  const [linkPhraseOn, setLinkPhraseOn] = useState<boolean>(false);

  const fetchContractsWord = (word: any) => {
    if (word.includes('https://') || word.includes('http://')) {
      if (word.length > 60) {
        return (
          <>
            <span>{word.substring(0, 60)}</span>
            <br />
            <span>{word.substring(60)}</span>
          </>
        );
      }
    } else {
      return word + ' ';
    }
  };

  const linkOrDelete = (action: string, paraIndex: number) => {
    if (action === 'link') {
      const hoveredPara = document.getElementById('p' + paraIndex);
      if (highlightedIdLocal === paraIndex) {
        if (hoveredPara !== null) {
          hoveredPara.style.outline = '3px solid #FBCE2F';
        }
      } else {
        if (hoveredPara !== null) {
          hoveredPara.style.outline = 'none';
        }
      }
      return (
        <Tooltip title="Link to datapoint" placement="right-end">
          <span
            style={{
              top: '-20px',
              right: '-20px',
              position: 'absolute',
              cursor: 'pointer',
              display: highlightedIdLocal === paraIndex ? 'block' : 'none',
            }}
            onClick={() => {
              // getHighlightedDiv(paraIndex)
            }}
          >
            <span className="annotate-icn-container">
              <InsertLinkIcon />
            </span>
          </span>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="Delete linked phrase from the datapoint" placement="right-end">
          <span
            style={{
              top: '-20px',
              right: '-20px',
              position: 'absolute',
              cursor: 'pointer',
              display: 'block',
            }}
            onClick={() => {
              getHighlightedDiv(null);
            }}
          >
            <span className="annotate-icn-container">
              <DeleteOutlineRoundedIcon />
            </span>
          </span>
        </Tooltip>
      );
    }
  };

  const linkOrDeleteSentence = (
    action: string,
    paraIndex: number,
    sentenceIndex: number
  ) => {
    if (action === 'link') {
      const hoveredSentence = document.getElementById(
        'p' + paraIndex + ';s' + sentenceIndex
      );
      if (
        highlightedSentenceIdLocal === sentenceIndex &&
        highlightedIdLocal === paraIndex
      ) {
        if (hoveredSentence !== null) {
          hoveredSentence.style.borderBottom = '3px solid #FBCE2F';
        }
      } else {
        if (hoveredSentence !== null) {
          hoveredSentence.style.borderBottom = 'none';
        }
      }
      return (
        <Tooltip title="Link to datapoint" placement="right-end">
          <span
            style={{
              top: '-8px',
              right: '11px',
              position: 'relative',
              cursor: 'pointer',
              display:
                highlightedSentenceIdLocal === sentenceIndex &&
                highlightedIdLocal === paraIndex
                  ? 'inline-block'
                  : 'none',
            }}
            onClick={() => getHighlightedSpan('link', paraIndex, sentenceIndex)}
          >
            <span className="annotate-small-icn-container">
              <InsertLinkIcon />
            </span>
          </span>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="Delete linked phrase from the datapoint" placement="right-end">
          <span
            style={{
              top: '-8px',
              right: '11px',
              position: 'relative',
              cursor: 'pointer',
              display: 'inline-block',
            }}
            onClick={() => getHighlightedSpan('delete', paraIndex, sentenceIndex)}
          >
            <span className="annotate-small-icn-container">
              <DeleteOutlineRoundedIcon />
            </span>
          </span>
        </Tooltip>
      );
    }
  };

  const showDeletePhraseOption = (phraseInfo?: phraseInfo) => {
    if (phraseInfo) {
      return (
        <span
          style={{
            position: 'relative',
            cursor: 'pointer',
            display: 'inline-block',
          }}
          onClick={() => deletePhrase(phraseInfo)}
        >
          <span className="annotate-small-icn-container">
            <DeleteOutlineRoundedIcon />
          </span>
        </span>
      );
    }
  };

  const deletePhrase = (phraseInfo: phraseInfo) => {
    const paraId = phraseInfo.paraId;
    const startWordId = phraseInfo.startWordId;
    for (let i = startWordId; i <= phraseInfo.endWordId; i++) {
      const phraseElement = 'p' + paraId + ';w' + i;
      const phraseElementOnDoc = document.getElementById(phraseElement);
      if (phraseElementOnDoc !== undefined && phraseElementOnDoc !== null) {
        phraseElementOnDoc.style.background = 'none';
      }
    }

    if (componentType === 'Event' && eventInAddEdit) {
      const tempEventInAddEdit: eventInfo = eventInAddEdit;
      tempEventInAddEdit.paraId = -1;
      tempEventInAddEdit.sentenceId = -1;
      tempEventInAddEdit.startWordId = -1;
      tempEventInAddEdit.endWordId = -1;
      tempEventInAddEdit.phrase = '';
      tempEventInAddEdit.rowId = -1;
      tempEventInAddEdit.columnId = -1;
      tempEventInAddEdit.phrase = '';

      //delete it from savedHighlightedEvents
      if (
        eventInAddEdit &&
        savedHighlightedEvents &&
        savedHighlightedEvents?.length > -1 &&
        eventInAddEdit.eventHighlightId > -1
      ) {
        const filteredHighlightedEvents: eventInfo[] = deletePhraseFromEventArray(
          eventInAddEdit,
          savedHighlightedEvents
        );
        dispatch(
          saveHighlightedEvents({ savedHighlightedEvents: filteredHighlightedEvents })
        );
      }
      dispatch(saveEventInAddEdit({ eventInAddEdit: tempEventInAddEdit }));
      dispatch(savePhraseEditOption({ phraseEditOptionSelected: true })); //
    } else {
      if (
        phraseInAddMode &&
        phraseInfo.paraId === phraseInAddMode.paraId &&
        phraseInfo.startWordId === phraseInAddMode.startWordId &&
        phraseInfo.endWordId === phraseInAddMode.endWordId
      ) {
        dispatch(saveAddPhrase({ phraseAddStatus: false }));
      }
      const changedPhrases = deletePhraseFromPhraseArray(
        phraseInfo,
        savedHighlightedPhrases
      );
      dispatch(saveHighlightedPhrases({ savedHighlightedPhrases: changedPhrases }));
    }

    dispatch(saveDeletePhrase({ phraseDeleteStatus: false }));
    dispatch(saveHighlightedTableCells({}));
  };

  const getStartWord = (paraIndex: number, wordIndex: number, sentenceIndex: number) => {
    if (getPhraseEditingStatus()) {
      setStartWordId(wordIndex);
      setStartWordParaId(paraIndex);
      setStartSentenceId(sentenceIndex);
    }
  };

  const getEndWord = (paraIndex: number, wordIndex: number, sentenceIndex: number) => {
    if (getPhraseEditingStatus()) {
      setEndWordId(wordIndex);
      setEndWordParaId(paraIndex);
      setEndSentenceId(sentenceIndex);
      setLinkPhraseOn(true);
    }
  };

  const linkPhrase = (paraIndex: number, wordIndex: number) => {
    const startWordIndex = startWordId;
    const startWordParaIndex = startWordParaId;
    const endWordIndex = endWordId;
    const endWordParaIndex = endWordParaId;
    const startSentenceIndex = startSentenceId;
    const endSentenceIndex = endSentenceId;

    if (getPhraseEditingStatus() === true && linkPhraseOn === true) {
      if (
        startWordIndex !== -1 &&
        endWordIndex !== -1 &&
        startWordParaIndex !== -1 &&
        endWordParaIndex !== -1
      ) {
        if (endWordIndex !== null && endWordParaIndex !== null) {
          if (endWordIndex === wordIndex && endWordParaIndex === paraIndex) {
            if (startWordParaIndex === endWordParaIndex) {
              if (
                startWordIndex !== null &&
                endWordIndex !== null &&
                startWordIndex <= endWordIndex
              ) {
                return (
                  <span
                    style={{
                      position: 'relative',
                      cursor: 'pointer',
                      display: 'inline-block',
                    }}
                    onClick={() =>
                      addSelectedPhrase(
                        startWordIndex,
                        startWordParaIndex,
                        endWordIndex,
                        endWordParaIndex,
                        startSentenceIndex,
                        endSentenceIndex
                      )
                    }
                  >
                    <span className="annotate-small-icn-container">
                      <ContentCopyIcon />
                    </span>
                  </span>
                );
              } else {
                setStartWordId(-1);
                setStartWordParaId(-1);
                setEndWordId(-1);
                setEndWordParaId(-1);
                setStartSentenceId(-1);
                setEndSentenceId(-1);
              }
            } else {
              setStartWordId(-1);
              setStartWordParaId(-1);
              setEndWordId(-1);
              setEndWordParaId(-1);
              setStartSentenceId(-1);
              setEndSentenceId(-1);
            }
          }
        }
      }
    }
  };

  const linkOrDeleteTableCell = (
    action: string,
    paraIndex: number,
    rowIndex: number,
    columnIndex: number
  ) => {
    if (action === 'link') {
      const hoveredTableCell = document.getElementById(
        'p' + paraIndex + ';r' + rowIndex + ';c' + columnIndex
      );
      if (
        highlightedIdLocal === paraIndex &&
        highlightedRowIdLocal === rowIndex &&
        highlightedColumnIdLocal === columnIndex
      ) {
        if (hoveredTableCell !== null) {
          hoveredTableCell.style.outline = '3px solid #FBCE2F';
        }
      } else {
        if (hoveredTableCell !== null) {
          hoveredTableCell.style.outline = 'none';
        }
      }
      return (
        <Tooltip title="Link to datapoint" placement="right-end">
          <span
            style={{
              top: '-20px',
              right: '-20px',
              position: 'absolute',
              cursor: 'pointer',
              display:
                highlightedIdLocal === paraIndex &&
                highlightedRowIdLocal === rowIndex &&
                highlightedColumnIdLocal === columnIndex
                  ? 'block'
                  : 'none',
            }}
            onClick={() => {
              /*getHighlightedTableCell(
                "link",
                paraIndex,
                rowIndex,
                columnIndex
              )*/
            }}
          >
            <span className="annotate-icn-container">
              <InsertLinkIcon />
            </span>
          </span>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="Delete linked phrase from the datapoint" placement="right-end">
          <span
            style={{
              top: '-20px',
              right: '-20px',
              position: 'absolute',
              cursor: 'pointer',
              display: 'block',
            }}
            onClick={() =>
              getHighlightedTableCell('delete', paraIndex, rowIndex, columnIndex)
            }
          >
            <span className="annotate-icn-container">
              <DeleteOutlineRoundedIcon />
            </span>
          </span>
        </Tooltip>
      );
    }
  };

  const addSelectedPhrase = (
    startWordIndex: number,
    startWordParaIndex: number,
    endWordIndex: number,
    endWordParaIndex: number,
    startSentenceIndex: number,
    endSentenceIndex: number
  ) => {
    if (
      startWordIndex !== -1 &&
      endWordIndex !== -1 &&
      startWordParaIndex !== -1 &&
      endWordParaIndex !== -1
    ) {
      const startWordElementId: string = 'p' + startWordParaIndex + ';w' + startWordIndex;
      const endWordElementId = 'p' + endWordParaIndex + ';w' + endWordIndex;

      //get selected phrase text
      const startWord = document.getElementById(startWordElementId);
      let phraseText: string | null | undefined = null;
      let endWord: HTMLElement | null | undefined = null;
      let traverseWordElement = '';
      let traverseEndWordIndex = startWordIndex;
      if (startWordElementId === endWordElementId) {
        //same word
        phraseText = document.getElementById(startWordElementId)?.textContent;
      } else {
        if (startWord) {
          phraseText = startWord.textContent;
          if (phraseText && endWordIndex > -1 && startWordIndex > -1) {
            for (let i = startWordIndex; i < endWordIndex; i++) {
              if (traverseEndWordIndex > -1) {
                traverseEndWordIndex += 1;
                traverseWordElement =
                  'p' + startWordParaIndex + ';w' + traverseEndWordIndex;
                endWord = document.getElementById(traverseWordElement);
                if (endWord) {
                  phraseText += endWord?.textContent;
                }
              }
            }
          }
        }
      }

      //set PhraseInfo Json in savedHighlightedPhrases prop
      const newPhraseInfo: phraseInfo = {
        paraId: startWordParaIndex,
        startWordId: startWordIndex,
        endWordId: endWordIndex,
        startSentenceId: startSentenceIndex,
        endSentenceId: endSentenceIndex,
        rowId: -1,
        columnId: -1,
        phrase: phraseText ? phraseText : '',
      };

      if (eventEditingStatus && eventInAddEdit) {
        const tempEventInAddEdit: eventInfo = eventInAddEdit;
        tempEventInAddEdit.paraId = startWordParaIndex;
        tempEventInAddEdit.sentenceId = startSentenceIndex;
        tempEventInAddEdit.startWordId = startWordIndex;
        tempEventInAddEdit.endWordId = endWordIndex;
        tempEventInAddEdit.rowId = -1;
        tempEventInAddEdit.columnId = -1;
        tempEventInAddEdit.phrase =
          phraseText !== undefined && phraseText !== null ? phraseText : '';
        dispatch(saveEventInAddEdit({ eventInAddEdit: tempEventInAddEdit }));

        if (
          savedHighlightedEvents &&
          savedHighlightedEvents.length > 0 &&
          eventInAddEdit.eventHighlightId > -1
        ) {
          const tempHighlightedEvents: eventInfo[] = replaceEventInEventArray(
            tempEventInAddEdit,
            savedHighlightedEvents
          );
          dispatch(
            saveHighlightedEvents({ savedHighlightedEvents: tempHighlightedEvents })
          );
        }
        dispatch(
          saveDeletePhrase({
            phraseDeleteStatus: true,
            phraseInDeleteMode: newPhraseInfo,
          })
        );
        dispatch(saveHighlightedTableCells({}));
      } else {
        dispatch(
          saveAddPhrase({ phraseAddStatus: true, phraseInAddMode: newPhraseInfo })
        );
        dispatch(saveHighlightedTableCells({}));
      }
    }
    dispatch(savePhraseEditOption({ phraseEditOptionSelected: false }));
    setStartWordId(-1);
    setStartWordParaId(-1);
    setEndWordId(-1);
    setEndWordParaId(-1);
    setStartSentenceId(-1);
    setEndSentenceId(-1);
    setLinkPhraseOn(false);
  };

  const getHighlightedDiv = (index: number | null) => {
    if (componentType === 'Start Date') {
      if (highlightedId && index !== null && highlightedId.indexOf(index) === -1) {
        //newly linked para, replace old para
        if (dateInAddEditMode) {
          const dateInEditing = dateInAddEditMode;
          dateInEditing.paraId = index;
          dateInEditing.rowId = -1;
          dateInEditing.columnId = -1;
          if (
            savedHighlightedDates &&
            savedHighlightedDates.length > 0 &&
            dateInAddEditMode.dateId > -1
          ) {
            for (let i = 0; i < savedHighlightedDates.length; i++) {
              if (savedHighlightedDates[i].dateId === dateInAddEditMode.dateId) {
                savedHighlightedDates[i].paraId = index;
                savedHighlightedDates[i].rowId = -1;
                savedHighlightedDates[i].columnId = -1;
              }
            }
          }
          dispatch(saveDateInAddEditMode({ dateInAddEditMode: dateInEditing }));
          dispatch(saveHighlightedId?.({ highlightedId: [index] }));
          dispatch(saveHighlightedTableCells({}));
        }
      } else {
        if (highlightedId && index !== null && highlightedId.indexOf(index) > -1) {
          //para already exists, clicked again to delete the para
          if (dateInAddEditMode) {
            const dateInEditing = dateInAddEditMode;
            dateInEditing.paraId = -1;
            dateInEditing.rowId = -1;
            dateInEditing.columnId = -1;
            if (
              savedHighlightedDates &&
              savedHighlightedDates.length > 0 &&
              dateInAddEditMode.dateId > -1
            ) {
              for (let i = 0; i < savedHighlightedDates.length; i++) {
                if (savedHighlightedDates[i].dateId === dateInAddEditMode.dateId) {
                  savedHighlightedDates[i].paraId = -1;
                  savedHighlightedDates[i].rowId = -1;
                  savedHighlightedDates[i].columnId = -1;
                }
              }
            }
            dispatch(saveDateInAddEditMode({ dateInAddEditMode: dateInEditing }));
            dispatch(saveHighlightedId({}));

            dispatch(saveHighlightedTableCells({}));
          }
        } else {
          if (index !== null) {
            if (dateInAddEditMode) {
              const dateInEditing = dateInAddEditMode;
              dateInEditing.paraId = index;
              dateInEditing.rowId = -1;
              dateInEditing.columnId = -1;
              if (
                savedHighlightedDates &&
                savedHighlightedDates.length > 0 &&
                dateInAddEditMode &&
                dateInAddEditMode.dateId > -1
              ) {
                for (let i = 0; i < savedHighlightedDates.length; i++) {
                  if (savedHighlightedDates[i].dateId === dateInAddEditMode.dateId) {
                    savedHighlightedDates[i].paraId = index;
                    savedHighlightedDates[i].rowId = -1;
                    savedHighlightedDates[i].columnId = -1;
                  }
                }
              }
              dispatch(saveDateInAddEditMode({ dateInAddEditMode: dateInEditing }));
              dispatch(saveHighlightedId({ highlightedId: [index] }));

              dispatch(saveHighlightedTableCells({}));
            }
          }
        }
      }
    } else if (componentType === 'Lock-in Period') {
      if (highlightedId && index !== null && highlightedId.indexOf(index) === -1) {
        //newly linked para
        dispatch(saveHighlightedId({ highlightedId: [index] }));

        dispatch(saveHighlightedTableCells({}));
      } else {
        if (highlightedId && index !== null && highlightedId.indexOf(index) > -1) {
          //para already exists, clicked again to delete the para
          dispatch(saveHighlightedId({}));
        } else {
          if (index !== null) {
            dispatch(saveHighlightedId({ highlightedId: [index] }));

            dispatch(saveHighlightedTableCells({}));
          }
        }
      }
      savedHighlightedTableCells && dispatch(saveHighlightedTableCells({}));
    } else {
      if (highlightedId) {
        let tempId = highlightedId;
        if (index !== null && highlightedId.indexOf(index) === -1) {
          tempId.push(index);
          dispatch(saveHighlightedId({ highlightedId: tempId }));
        } else if (index !== null && highlightedId.indexOf(index) !== -1) {
          //if already present in highlights then remove, edit functionality
          const filtered = tempId.filter((el: any) => {
            return el !== index;
          });
          tempId = filtered;
          dispatch(saveHighlightedId({ highlightedId: tempId }));
        }
      } else {
        if (index !== null && highlightedId === null) {
          if (componentType === 'Lock-in Period') {
            setHighlightedIdLocal(null);
          }
          dispatch(saveHighlightedId({ highlightedId: [index] }));
        }
      }
    }
  };

  const getHighlightedSpan = (
    action: string,
    paraIndex: number | null,
    sentenceIndex: number | null
  ) => {
    if (componentType === 'sentence') {
      if (paraIndex !== null && sentenceIndex !== null) {
        const tempSentenceInfo: sentenceInfo = {
          paraId: paraIndex,
          sentenceId: sentenceIndex,
          rowId: -1,
          columnId: -1,
        };
        if (savedHighlightedSentences) {
          let tempSentences: sentenceInfo[] | null = [
            ...(savedHighlightedSentences || []),
          ];
          if (action === 'link') {
            tempSentences?.push(tempSentenceInfo);
            dispatch(
              saveHighlightedSentences({ savedHighlightedSentences: tempSentences })
            );
          } else {
            const filteredSentences = deleteSentence(
              tempSentences,
              paraIndex,
              sentenceIndex
            );
            tempSentences = filteredSentences;
            dispatch(
              saveHighlightedSentences({ savedHighlightedSentences: tempSentences })
            );
          }
        } else {
          if (savedHighlightedSentences === null) {
            dispatch(
              saveHighlightedSentences({ savedHighlightedSentences: [tempSentenceInfo] })
            );
          }
        }
      }
    } else if (componentType === 'single sentence') {
      if (paraIndex !== null && sentenceIndex !== null) {
        const tempSentenceInfo: sentenceInfo = {
          paraId: paraIndex,
          sentenceId: sentenceIndex,
          rowId: -1,
          columnId: -1,
        };
        if (savedHighlightedSentences) {
          if (savedHighlightedSentences.length > 0) {
            if (
              savedHighlightedSentences[0].paraId === paraIndex &&
              savedHighlightedSentences[0].sentenceId === sentenceIndex
            ) {
              dispatch(saveHighlightedSentences({}));
            } else {
              dispatch(
                saveHighlightedSentences({
                  savedHighlightedSentences: [tempSentenceInfo],
                })
              );
              dispatch(saveHighlightedTableCells({}));
            }
          }
        } else {
          if (savedHighlightedSentences === null) {
            dispatch(
              saveHighlightedSentences({ savedHighlightedSentences: [tempSentenceInfo] })
            );
            dispatch(saveHighlightedTableCells({}));
          }
        }
      }
    }
  };

  const getHighlightedTableCell = (
    action: string,
    paraIndex: number,
    rowIndex: number,
    columnIndex: number
  ) => {
    const tempTableInfo: tableInfo = {
      paraId: paraIndex,
      rowId: rowIndex,
      columnId: columnIndex,
    };
    if (action === 'link') {
      if (paraIndex !== null && rowIndex !== null && columnIndex !== null) {
        if (componentType === 'sentence' || componentType === 'para') {
          if (savedHighlightedTableCells) {
            const tempTableCells: tableInfo[] = [...savedHighlightedTableCells];
            tempTableCells.push(tempTableInfo);
            dispatch(
              saveHighlightedTableCells({ savedHighlightedTableCells: tempTableCells })
            );
          } else {
            dispatch(
              saveHighlightedTableCells({ savedHighlightedTableCells: [tempTableInfo] })
            );
          }
        } else if (
          componentType === 'Lock-in Period' ||
          componentType === 'single sentence'
        ) {
          const linkedTableCell: tableInfo = {
            paraId: paraIndex,
            rowId: rowIndex,
            columnId: columnIndex,
          };
          dispatch(
            saveHighlightedTableCells({ savedHighlightedTableCells: [linkedTableCell] })
          );
          dispatch(saveHighlightedId({}));

          dispatch(saveHighlightedSentences({}));
        } else if (
          (componentType === 'Phrase' ||
            componentType === 'Duration' ||
            componentType === 'Amount' ||
            componentType === 'Nature' ||
            componentType === 'territory' ||
            componentType === 'Date' ||
            componentType === 'Boolean') &&
          phraseEditOptionSelected === true
        ) {
          const tempTableCellId = 'p' + paraIndex + ';r' + rowIndex + ';c' + columnIndex;
          const phraseId = document.getElementById(tempTableCellId);
          const phraseText =
            phraseId !== undefined && phraseId !== null ? phraseId.textContent : '';
          if (phraseId !== undefined && phraseId !== null) {
            phraseId.style.outline = 'none';
          }

          const linkedTableCell: tableInfo = {
            paraId: paraIndex,
            rowId: rowIndex,
            columnId: columnIndex,
          };
          dispatch(
            saveHighlightedTableCells({ savedHighlightedTableCells: [linkedTableCell] })
          );

          const tableCellPhrase: phraseInfo = {
            paraId: paraIndex,
            startSentenceId: -1,
            endSentenceId: -1,
            startWordId: -1,
            endWordId: -1,
            rowId: rowIndex,
            columnId: columnIndex,
            phrase: phraseText !== undefined && phraseText !== null ? phraseText : '',
          };

          dispatch(
            saveAddPhrase({ phraseAddStatus: true, phraseInAddMode: tableCellPhrase })
          );
        } else if (componentType === 'Start Date') {
          const tempTableCells = {
            paraId: paraIndex,
            rowId: rowIndex,
            columnId: columnIndex,
          };
          if (dateInAddEditMode) {
            const dateInEditing = dateInAddEditMode;
            dateInEditing.paraId = paraIndex;
            dateInEditing.rowId = rowIndex;
            dateInEditing.columnId = columnIndex;

            if (
              savedHighlightedDates &&
              savedHighlightedDates.length !== 0 &&
              dateInAddEditMode &&
              dateInAddEditMode.dateId > -1
            ) {
              for (let i = 0; i < savedHighlightedDates.length; i++) {
                if (dateInAddEditMode.dateId === savedHighlightedDates[i].dateId) {
                  savedHighlightedDates[i].paraId = paraIndex;
                  savedHighlightedDates[i].rowId = rowIndex;
                  savedHighlightedDates[i].columnId = columnIndex;
                }
              }
            }
            dispatch(saveDateInAddEditMode({ dateInAddEditMode: dateInEditing }));
            dispatch(
              saveHighlightedTableCells({ savedHighlightedTableCells: [tempTableCells] })
            );
            dispatch(saveHighlightedId({}));
          }
        } else if (componentType === 'Event') {
          if (eventInAddEdit) {
            const tempTableCellId =
              'p' + paraIndex + ';r' + rowIndex + ';c' + columnIndex;
            const phraseId = document.getElementById(tempTableCellId);
            const phraseText =
              phraseId !== undefined && phraseId !== null && phraseId.textContent !== null
                ? phraseId.textContent
                : '';

            const tempEventInAddEdit: eventInfo = eventInAddEdit;
            tempEventInAddEdit.paraId = paraIndex;
            tempEventInAddEdit.rowId = rowIndex;
            tempEventInAddEdit.columnId = columnIndex;
            tempEventInAddEdit.sentenceId = -1;
            tempEventInAddEdit.startWordId = -1;
            tempEventInAddEdit.endWordId = -1;
            tempEventInAddEdit.phrase = phraseText;

            dispatch(saveEventInAddEdit({ eventInAddEdit: tempEventInAddEdit }));
            dispatch(
              saveHighlightedTableCells({ savedHighlightedTableCells: [tempTableInfo] })
            );
            dispatch(saveDeletePhrase({ phraseDeleteStatus: false }));

            if (
              savedHighlightedEvents &&
              savedHighlightedEvents.length > 0 &&
              eventInAddEdit?.eventHighlightId > -1
            ) {
              const tempHighlightedEvents: eventInfo[] = replaceEventInEventArray(
                tempEventInAddEdit,
                savedHighlightedEvents
              );
              dispatch(
                saveHighlightedEvents({
                  savedHighlightedEvents: tempHighlightedEvents,
                })
              );
            }
          }
        }
      }
    } else if (action === 'delete') {
      if (componentType === 'sentence' || componentType === 'para') {
        if (savedHighlightedTableCells) {
          const filteredTableCells = deleteTableCell(
            savedHighlightedTableCells,
            paraIndex,
            rowIndex,
            columnIndex
          );
          dispatch(
            saveHighlightedTableCells({ savedHighlightedTableCells: filteredTableCells })
          );
        }
      } else if (
        componentType === 'Lock-in Period' ||
        componentType === 'single sentence'
      ) {
        dispatch(saveHighlightedTableCells({}));
      } else if (componentType === 'Phrase' || componentType === 'Boolean') {
        let filteredPhrases: phraseInfo[] | null = null;
        if (savedHighlightedPhrases && savedHighlightedPhrases?.length > 0) {
          filteredPhrases = deleteTableCellFromPhraseArray(
            paraIndex,
            rowIndex,
            columnIndex,
            savedHighlightedPhrases
          );
          filteredPhrases =
            filteredPhrases !== null && filteredPhrases.length > 0
              ? filteredPhrases
              : null;
        }
        dispatch(saveHighlightedPhrases({ savedHighlightedPhrases: filteredPhrases })); //deleted phrase
        if (
          phraseInAddMode &&
          phraseInAddMode.paraId === paraIndex &&
          phraseInAddMode.rowId === rowIndex &&
          phraseInAddMode.columnId === columnIndex
        ) {
          dispatch(saveAddPhrase({ phraseAddStatus: false }));
        }
        dispatch(saveDeletePhrase({ phraseDeleteStatus: false }));
        dispatch(saveHighlightedTableCells({}));
        //setState({highlightedRowIdLocal: null, highlightedColumnIdLocal: null});
        stopPhraseEditing();
      } else if (componentType === 'Start Date') {
        if (dateInAddEditMode) {
          const dateInEditing = dateInAddEditMode;
          dateInEditing.paraId = null;
          dateInEditing.rowId = null;
          dateInEditing.columnId = null;

          if (
            savedHighlightedDates &&
            savedHighlightedDates.length !== 0 &&
            dateInAddEditMode.dateId > -1
          ) {
            for (let i = 0; i < savedHighlightedDates.length; i++) {
              if (dateInAddEditMode.dateId === savedHighlightedDates[i].dateId) {
                savedHighlightedDates[i].paraId = null;
                savedHighlightedDates[i].rowId = null;
                savedHighlightedDates[i].columnId = null;
              }
            }
          }
          dispatch(saveDateInAddEditMode({ dateInAddEditMode: dateInEditing }));
          dispatch(saveHighlightedTableCells({}));
          dispatch(saveHighlightedId({}));
        }
      } else if (componentType === 'Event') {
        if (eventInAddEdit) {
          const tempEventInAddEdit: eventInfo = eventInAddEdit;
          tempEventInAddEdit.paraId = -1;
          tempEventInAddEdit.rowId = -1;
          tempEventInAddEdit.columnId = -1;
          tempEventInAddEdit.sentenceId = -1;
          tempEventInAddEdit.phrase = '';
          tempEventInAddEdit.startWordId = -1;
          tempEventInAddEdit.endWordId = -1;
          tempEventInAddEdit.phrase = '';
          dispatch(saveEventInAddEdit({ eventInAddEdit: tempEventInAddEdit }));
          dispatch(saveDeletePhrase({ phraseDeleteStatus: false }));

          if (
            savedHighlightedEvents &&
            savedHighlightedEvents.length &&
            eventInAddEdit.eventHighlightId > -1
          ) {
            const filteredHighlightedEvents: eventInfo[] = replaceEventInEventArray(
              tempEventInAddEdit,
              savedHighlightedEvents
            );
            dispatch(
              saveHighlightedEvents({ savedHighlightedEvents: filteredHighlightedEvents })
            );
          }
        }
      }
    }
  };

  const displayOutline = (index: number) => {
    if (highlightedId === null) {
      return false;
    } else if (highlightedId && highlightedId.indexOf(index) > -1) {
      return true;
    } else {
      return false;
    }
  };

  const showAnnotationMode = () => {
    if (componentType !== '' && editOptionSelected) {
      return true;
    } else {
      return false;
    }
  };

  const getPhraseEditingStatus = () => {
    if (
      (componentType === 'Phrase' ||
        componentType === 'Amount' ||
        componentType === 'Duration' ||
        componentType === 'Date' ||
        componentType === 'Boolean') &&
      phraseEditOptionSelected === true
    ) {
      return true;
    } else if (
      componentType === 'Event' &&
      phraseEditOptionSelected === true &&
      eventEditingStatus === true
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getPhraseDeleteEditingStatus = () => {
    if (
      (phraseDeleteStatus && componentType === 'Phrase') ||
      componentType === 'Boolean'
    ) {
      return true;
    } else if (phraseDeleteStatus && componentType === 'Event') {
      return true;
    } else {
      return false;
    }
  };

  const getTableEditingStatus = () => {
    if (componentType === 'sentence' || componentType === 'para') {
      return true;
    } else if (
      componentType === 'Lock-in Period' ||
      componentType === 'single sentence'
    ) {
      return true;
    } else if (
      (componentType === 'Phrase' ||
        componentType === 'Boolean' ||
        componentType === 'Duration' ||
        componentType === 'Amount' ||
        componentType === 'Nature' ||
        componentType === 'territory' ||
        componentType === 'Date') &&
      phraseEditOptionSelected
    ) {
      return true;
    } else if (
      (componentType === 'Phrase' ||
        componentType === 'Boolean' ||
        componentType === 'Duration' ||
        componentType === 'Amount' ||
        componentType === 'Nature' ||
        componentType === 'territory' ||
        componentType === 'Date') &&
      saveHighlightedTableCells !== null &&
      phraseDeleteStatus === true
    ) {
      return true;
    } else if (componentType === 'Date' && dateEditingStatus === true) {
      return true;
    } else if (componentType === 'Event' && eventEditingStatus === true) {
      return true;
    }
    return false;
  };

  const getDateEditingStatus = () => {
    if (componentType === 'Date' && dateEditingStatus === true) {
      return true;
    } else if (componentType === 'Date' && dateEditingStatus === false) {
      return false;
    }
    return true;
  };

  const stopPhraseEditing = () => {
    dispatch(savePhraseEditOption({ phraseEditOptionSelected: false }));
    setLinkPhraseOn(false);
    setStartWordId(-1);
    setStartWordParaId(-1);
    setEndWordId(-1);
    setEndWordParaId(-1);
    setStartSentenceId(-1);
    setEndSentenceId(-1);
  };

  const renderTable = (table: ITable[], paraIndex: number) => {
    return (
      <Table sx={{ wordBreak: 'break-word', tableLayout: 'fixed' }}>
        <TableBody>
          {table?.map((row, i) => (
            <TableRow key={i} id={'p' + paraIndex + ';r' + i}>
              {row.Row?.map((col) => {
                return (
                  <React.Fragment key={col.Column}>
                    <TableCell
                      key={col.Column}
                      id={'p' + paraIndex + ';r' + col.Row + ';c' + col.Column}
                      style={{
                        outline:
                          showAnnotationMode() &&
                          getTableEditingStatus() &&
                          hasTableCell(
                            paraIndex,
                            col.Row,
                            col.Column,
                            savedHighlightedTableCells
                          ) === true
                            ? '3px solid #FBCE2F'
                            : 'none',
                        position: 'relative',
                      }}
                      onMouseOver={() => {
                        if (
                          showAnnotationMode() &&
                          getTableEditingStatus() &&
                          phraseDeleteStatus !== true
                        ) {
                          setHighlightedRowIdLocal(col.Row);
                          setHighlightedColumnIdLocal(col.Column);
                        }
                      }}
                      onMouseOut={() => {
                        if (
                          showAnnotationMode() &&
                          getTableEditingStatus() &&
                          phraseDeleteStatus !== true
                        ) {
                          setHighlightedRowIdLocal(null);
                          setHighlightedColumnIdLocal(null);
                        }
                      }}
                      onClick={() =>
                        showAnnotationMode() &&
                        getTableEditingStatus() &&
                        getHighlightedTableCell(
                          savedHighlightedTableCells !== null &&
                            hasTableCell(
                              paraIndex,
                              col.Row,
                              col.Column,
                              savedHighlightedTableCells
                            ) === true
                            ? 'delete'
                            : 'link',
                          paraIndex,
                          col.Row,
                          col.Column
                        )
                      }
                    >
                      {col.Content}
                      {showAnnotationMode() &&
                        getTableEditingStatus() &&
                        linkOrDeleteTableCell(
                          savedHighlightedTableCells !== null &&
                            hasTableCell(
                              paraIndex,
                              col.Row,
                              col.Column,
                              savedHighlightedTableCells
                            ) === true
                            ? 'delete'
                            : 'link',
                          paraIndex,
                          col.Row,
                          col.Column
                        )}
                    </TableCell>
                  </React.Fragment>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  const getSentencesFontWeight = (level: string) => {
    switch (level) {
      case 'para': {
        return 'normal';
      }
      case 'heading':
      case 'bullet': {
        return 'bold';
      }
      default: {
        return 'normal';
      }
    }
  };

  const getFontWeight = (isHeading: boolean) => {
    if (isHeading) {
      return 'bold';
    }
  };

  return (
    <Grid container style={{ marginRight: '0px' }}>
      <Grid
        size={{ md: 12 }}
        className={
          showAnnotationMode()
            ? 'sentences-container highlight-sentences'
            : 'sentences-container'
        }
        id="scroll-to"
      >
        {sentenceLoader ? (
          <SentenceLoader />
        ) : (
          sentenceData?.hierarchy?.map((para: any) => (
            <React.Fragment key={'p' + para.index}>
              <Box
                id={'p' + para.index}
                key={para.index}
                sx={{
                  marginBottom: '20px',
                  position: 'relative',
                  outline:
                    (para.table === null || para.table === undefined) &&
                    showAnnotationMode() &&
                    getDateEditingStatus() &&
                    componentType === 'para' &&
                    displayOutline(para.index)
                      ? '3px solid #FBCE2F'
                      : 'none',
                }}
                onMouseOver={() =>
                  showAnnotationMode() && setHighlightedIdLocal(para.index)
                }
                onMouseOut={() => showAnnotationMode() && setHighlightedIdLocal(null)}
                onClick={() => {
                  if (
                    (para.table === null || para.table === undefined) &&
                    showAnnotationMode() &&
                    getDateEditingStatus() &&
                    componentType === 'para'
                  ) {
                    getHighlightedDiv(para.index);
                  }
                }}
              >
                {para.table ? (
                  renderTable(para.table, para.index)
                ) : (
                  <>
                    {para?.sentences?.map((sentence: any, j: any) => (
                      <React.Fragment key={'p' + para.index + ';s' + sentence.index}>
                        <span
                          id={'p' + para.index + ';s' + sentence.index}
                          style={{
                            fontWeight: getSentencesFontWeight(sentence.level),
                            borderBottom:
                              showAnnotationMode() &&
                              (componentType === 'sentence' ||
                                componentType === 'single sentence') &&
                              hasSentence(
                                para.index,
                                sentence.index,
                                savedHighlightedSentences
                              )
                                ? '3px solid #FBCE2F'
                                : 'none',
                          }}
                          onMouseOver={() =>
                            showAnnotationMode() &&
                            (componentType === 'sentence' ||
                              componentType === 'single sentence') &&
                            setHighlightedSentenceIdLocal(sentence.index)
                          }
                          onMouseOut={() =>
                            showAnnotationMode() &&
                            (componentType === 'sentence' ||
                              componentType === 'single sentence') &&
                            setHighlightedSentenceIdLocal(null)
                          }
                          onClick={() =>
                            showAnnotationMode() &&
                            (componentType === 'sentence' ||
                              componentType === 'single sentence') &&
                            getHighlightedSpan(
                              savedHighlightedSentences &&
                                hasSentence(
                                  para.index,
                                  sentence.index,
                                  savedHighlightedSentences
                                )
                                ? 'delete'
                                : 'link',
                              para.index,
                              sentence.index
                            )
                          }
                          key={sentence.index}
                        >
                          {sentence.words?.map((word: any, k: any) => (
                            <React.Fragment key={'p' + para.index + ';w' + word.index}>
                              {
                                <span
                                  id={'p' + para.index + ';w' + word.index}
                                  className={
                                    phraseEditOptionSelected === true
                                      ? 'phrase-selector'
                                      : 'none'
                                  }
                                  style={{
                                    fontWeight: getFontWeight(
                                      para.isHeading === 'Yes' ||
                                        para.role === 'sectionHeading'
                                    ),
                                    borderBottom:
                                      isWordInPhrase(
                                        para.index,
                                        word.index,
                                        phraseInDeleteMode
                                      ) === true &&
                                      (componentType === 'Phrase' ||
                                        componentType === 'Event' ||
                                        componentType === 'Boolean') &&
                                      phraseDeleteStatus
                                        ? '4px solid #FBCE2F'
                                        : 'none',
                                  }}
                                  onMouseDown={() =>
                                    getStartWord(para.index, word.index, sentence.index)
                                  }
                                  onMouseUp={() =>
                                    getEndWord(para.index, word.index, sentence.index)
                                  }
                                  key={word.index}
                                >
                                  {k !== para.sentences[j].words.length - 1 ? (
                                    <>{fetchContractsWord(word.word)}</>
                                  ) : (
                                    <span
                                      className={
                                        phraseEditOptionSelected === true
                                          ? 'phrase-selector'
                                          : 'none'
                                      }
                                    >
                                      {fetchContractsWord(word.word)} &nbsp;
                                    </span>
                                  )}
                                </span>
                              }
                              {linkPhrase(para.index, word.index)}
                              {getPhraseDeleteEditingStatus() &&
                                isEndWordInPhrase(
                                  para.index,
                                  word.index,
                                  phraseInDeleteMode
                                ) &&
                                showDeletePhraseOption(phraseInDeleteMode)}
                            </React.Fragment>
                          ))}
                        </span>
                        {showAnnotationMode() &&
                          (componentType === 'sentence' ||
                            componentType === 'single sentence') &&
                          linkOrDeleteSentence(
                            savedHighlightedSentences &&
                              hasSentence(
                                para.index,
                                sentence.index,
                                savedHighlightedSentences
                              )
                              ? 'delete'
                              : 'link',
                            para.index,
                            sentence.index
                          )}
                      </React.Fragment>
                    ))}
                    {(para.table === null || para.table === undefined) &&
                      showAnnotationMode() &&
                      getDateEditingStatus() &&
                      componentType === 'para' &&
                      linkOrDelete(
                        highlightedId && highlightedId.indexOf(para.index) > -1
                          ? 'delete'
                          : 'link',
                        para.index
                      )}
                  </>
                )}
              </Box>
            </React.Fragment>
          ))
        )}
      </Grid>
    </Grid>
  );
};

export default SentenceRenderer;
