const formControlLabelTheme = {
  styleOverrides: {
    root: {
      padding: 2,
    },
    label: {
      marginLeft: '10px',
    },
  },
};

export default formControlLabelTheme;
