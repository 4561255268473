/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { SentencesData } from '../State/DocumentState';

export const getPara = (
  sentenceData: SentencesData,
  paraID: number,
  sentenceId?: number,
  row = -1,
  col = -1
) => {
  if (col > -1 && row > -1) {
    let para = '';
    const table = sentenceData?.hierarchy?.[paraID]?.table?.slice() || [];
    if (table.length > 0) {
      for (let i = 0; i < table.length; i++) {
        const rowData = table[i]?.Row?.slice() || [];
        if (rowData.length > 0) {
          for (let j = 0; j < rowData.length; j++) {
            if (rowData[j]?.Column === col && rowData[j]?.Row === row) {
              para = rowData[j]?.Content;
            }
          }
        }
      }
    } else {
      para = sentenceData?.hierarchy?.[paraID]?.para;
    }
    return para;
  }

  if (sentenceId && sentenceId > -1) {
    return sentenceData?.hierarchy?.[paraID]?.sentences?.[sentenceId]?.sentence;
  }
  return sentenceData?.hierarchy?.[paraID]?.para;
};

export const deleteSentence = (clauseData: any, deletedData: any) => {
  const filteredData = clauseData?.filter((data: any) => {
    const sentence_id =
      data.sentence_id > -1
        ? data.sentence_id
        : data.start_sentence_id > -1
          ? data.start_sentence_id
          : -1;
    const sentenceId =
      deletedData.sentenceId > -1
        ? deletedData.sentenceId
        : deletedData.start_sentenceId > -1
          ? deletedData.start_sentenceId
          : -1;
    if (deletedData.rowId > -1 && deletedData.columnId > -1) {
      return (
        data.para_id !== deletedData.paraId ||
        data.row_id !== deletedData.rowId ||
        data.column_id !== deletedData.columnId
      );
    } else {
      return data.para_id !== deletedData.paraId || sentence_id !== sentenceId;
    }
  });
  return filteredData;
};

export const deleteClauseSentence = (clauseData: any, deletedData: any) => {
  const filteredData = clauseData?.filter((data: any) => {
    if (
      deletedData.table &&
      deletedData.table.length &&
      data.table &&
      data.table.length
    ) {
      const dataTable = data.table?.[0];
      const table = deletedData.table?.[0];
      return (
        dataTable.row !== table.row ||
        dataTable.column !== table.column ||
        data.para_id !== deletedData.para_id ||
        data.sentence_id !== deletedData.sentence_id
      );
    } else {
      return (
        data.para_id !== deletedData.para_id ||
        data.sentence_id !== deletedData.sentence_id
      );
    }
  });
  return filteredData;
};

export const deletePhrase = (clauseData: any, deletedData: any) => {
  const filteredData = clauseData.filter((data: any) => {
    const sentence_id =
      data.sentence_id > -1
        ? data.sentence_id
        : data.start_sentence_id > -1
          ? data.start_sentence_id
          : -1;
    if (deletedData.rowId > -1 && deletedData.columnId > -1) {
      return (
        data.para_id !== deletedData.paraId ||
        sentence_id !== deletedData.startSentenceId ||
        data.start_word_id !== deletedData.startWordId ||
        data.row_id !== deletedData.rowId ||
        data.column_id !== deletedData.columnId
      );
    } else {
      return (
        data.para_id !== deletedData.paraId ||
        sentence_id !== deletedData.startSentenceId ||
        data.start_word_id !== deletedData.startWordId
      );
    }
  });

  return filteredData;
};

export const deleteClausePhrase = (clauseData: any, deletedData: any) => {
  const filteredData = clauseData.filter((data: any) => {
    if (
      deletedData.table &&
      deletedData.table.length &&
      data.table &&
      data.table.length
    ) {
      const dataTable = data.table?.[0];
      const table = deletedData.table?.[0];
      return (
        dataTable.row !== table.row ||
        dataTable.column !== table.column ||
        data.para_id !== deletedData.para_id ||
        data.sentence_id !== deletedData.sentence_id ||
        data.start_word_id !== deletedData.start_word_id
      );
    } else {
      return (
        data.para_id !== deletedData.para_id ||
        data.sentence_id !== deletedData.sentence_id ||
        data.start_word_id !== deletedData.start_word_id
      );
    }
  });

  return filteredData;
};

export const getClauseDataFormat = (
  action: string,
  type: string,
  addedData: any,
  updatedData: any,
  clauseName: string,
  sentenceData: SentencesData,
  isChild?: boolean
) => {
  switch (type.toLowerCase()) {
    case 'sentence': {
      if (action === 'add') {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        const para = getPara(
          sentenceData,
          addedData.paraId,
          -1,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          para: para,
          sentence: sentence,
          sentence_id: addedData.sentenceId,
        };
        const parentData = updatedData[clauseName]?.slice() || [];
        parentData.push(newAddedData);
        updatedData = {
          ...updatedData,
          [clauseName]: parentData,
        };
        return updatedData;
      }
      if (action === 'remove') {
        const parentData = deleteSentence(updatedData[clauseName], addedData);
        updatedData = {
          ...updatedData,
          [clauseName]: parentData,
        };
        return updatedData;
      }
      return updatedData;
    }
    case 'phrase':
    case 'boolean': {
      if (action === 'add') {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        const para = getPara(
          sentenceData,
          addedData.paraId,
          -1,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          para: para,
          sentence: sentence,
          sentence_id: addedData.startSentenceId,
          value: addedData.phrase,
          original_context: addedData.phrase,
          start_word_id: addedData.startWordId,
          end_word_id: addedData.endWordId,
        };
        const clause = updatedData[clauseName];
        const clauseData = updatedData[clauseName]?.data?.slice() || [];
        clauseData.push(newAddedData);
        updatedData = {
          ...updatedData,
          [clauseName]: {
            ...clause,
            data: clauseData,
          },
        };
        return updatedData;
      }
      if (action === 'remove') {
        const clause = updatedData[clauseName];
        const clauseData = updatedData[clauseName]?.data?.slice() || [];
        const newData = deletePhrase(clauseData, addedData);
        updatedData = {
          ...updatedData,
          [clauseName]: {
            ...clause,
            data: newData,
          },
        };
        return updatedData;
      }
      return updatedData;
    }
    case 'amount': {
      if (action === 'add') {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        const para = getPara(
          sentenceData,
          addedData.paraId,
          -1,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          para: para,
          sentence: sentence,
          sentence_id: addedData.startSentenceId,
          value: addedData.total,
          original_context: addedData.phrase,
          start_word_id: addedData.startWordId,
          end_word_id: addedData.endWordId,
          currency: addedData.currency,
          currencyId: addedData.currencyId,
        };
        const clause = updatedData[clauseName];
        const clauseData = updatedData[clauseName]?.data?.slice() || [];
        clauseData.push(newAddedData);
        updatedData = {
          ...updatedData,
          [clauseName]: {
            ...clause,
            data: clauseData,
          },
        };
        return updatedData;
      }
      if (action === 'remove') {
        const clause = updatedData[clauseName];
        const clauseData = updatedData[clauseName]?.data?.slice() || [];
        const newData = deletePhrase(clauseData, addedData);
        updatedData = {
          ...updatedData,
          [clauseName]: {
            ...clause,
            data: newData,
          },
        };
        return updatedData;
      }
      return updatedData;
    }
    case 'duration': {
      if (action === 'add') {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        const para = getPara(
          sentenceData,
          addedData.paraId,
          -1,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          para: para,
          sentence: sentence,
          sentence_id: addedData.startSentenceId,
          value: addedData.value,
          original_context: addedData.phrase,
          start_word_id: addedData.startWordId,
          end_word_id: addedData.endWordId,
          duration: addedData.duration,
          duration_id: addedData.durationId,
        };
        const clause = updatedData[clauseName];
        const clauseData = updatedData[clauseName]?.data?.slice() || [];
        clauseData.push(newAddedData);
        updatedData = {
          ...updatedData,
          [clauseName]: {
            ...clause,
            data: clauseData,
          },
        };
        return updatedData;
      }
      if (action === 'remove') {
        const clause = updatedData[clauseName];
        const clauseData = updatedData[clauseName]?.data?.slice() || [];
        const newData = deletePhrase(clauseData, addedData);
        updatedData = {
          ...updatedData,
          [clauseName]: {
            ...clause,
            data: newData,
          },
        };
        return updatedData;
      }
      return updatedData;
    }
    case 'date': {
      if (action === 'add') {
        const sentence = getPara(
          sentenceData,
          addedData.paraId,
          addedData.sentenceId,
          addedData?.rowId,
          addedData?.columnId
        );
        const para = getPara(
          sentenceData,
          addedData.paraId,
          -1,
          addedData?.rowId,
          addedData?.columnId
        );
        const newAddedData = {
          table: [{ row: addedData.rowId, column: addedData.columnId }],
          para_id: addedData.paraId,
          para: para,
          sentence: sentence,
          sentence_id: addedData.startSentenceId,
          value: addedData.value,
          original_context: addedData.phrase,
          start_word_id: addedData.startWordId,
          end_word_id: addedData.endWordId,
          date: new Date(addedData.value).toDateString(),
        };
        const clause = updatedData[clauseName];
        const clauseData = updatedData[clauseName]?.data?.slice() || [];
        clauseData.push(newAddedData);
        updatedData = {
          ...updatedData,
          [clauseName]: {
            ...clause,
            data: clauseData,
          },
        };
        return updatedData;
      }
      if (action === 'remove') {
        const clause = updatedData[clauseName];
        const clauseData = updatedData[clauseName]?.data?.slice() || [];
        const newData = deletePhrase(clauseData, addedData);
        updatedData = {
          ...updatedData,
          [clauseName]: {
            ...clause,
            data: newData,
          },
        };
        return updatedData;
      }
      return updatedData;
    }
  }
};
