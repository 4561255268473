/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, FC, SetStateAction } from 'react';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Box, Stack, Typography } from '@mui/material';

interface IProps {
  counter: number;
  setCounter: Dispatch<SetStateAction<number>>;
  clause: any;
  onScroll: (direction: string, data: any) => void;
}

const ChangeClauseCount: FC<IProps> = ({ counter, setCounter, clause, onScroll }) => {
  return (
    <Box sx={{ flex: 1 }}>
      <Stack
        direction="row"
        gap="2px"
        sx={{
          background: '#FFF7FA',
          padding: '4px 12px',
          borderRadius: '80px',
          width: 'fit-content',
        }}
      >
        <ArrowUpwardIcon
          fontSize="small"
          sx={{ color: '#49454F' }}
          onClick={(e) => {
            e.stopPropagation();
            if (counter > 1) {
              setCounter((prev) => prev - 1);
              onScroll('up', clause);
            }
          }}
        />
        <Typography variant="caption" align="center" sx={{ fontWeight: 'bold' }}>
          {`${counter} / ${clause ? clause.length : 0}`}
        </Typography>
        <ArrowDownwardIcon
          fontSize="small"
          sx={{ color: '#49454F' }}
          onClick={(e) => {
            e.stopPropagation();
            if (clause.length > counter) {
              setCounter((prev) => prev + 1);
              onScroll('down', clause);
            }
          }}
        />
      </Stack>
    </Box>
  );
};

export default ChangeClauseCount;
