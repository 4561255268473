import React from 'react';

import { useKeycloak } from '@react-keycloak/web';
import { Navigate, Route, Routes } from 'react-router-dom';

import DraftLayout from './components/layout/DraftLayout';
import Layout from './components/layout/layout';
import Contracts from './Pages/Contracts';
import ViewContract from './Pages/Contracts/ViewContract';
import Dictionary from './Pages/Dictionary';
import DataGrid from './Pages/Dictionary/DataGrid';
import PageNotFound from './Pages/ErrorPages/404Page';
import Filter from './Pages/Filter';
import Reminder from './Pages/Reminder/ReminderComponent';
import Search from './Pages/Search';

function Root() {
  const { keycloak } = useKeycloak();
  const isLoggedIn = keycloak.authenticated;

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="contracts" element={<Contracts />} />
        <Route path="dictionary" element={<Dictionary />} />
        <Route path="dictionary/:id" element={<DataGrid />} />
        <Route path="reminder" element={<Reminder />} />
        <Route path="search/:query" element={<Search />} />
        <Route path="filter" element={<Filter />} />

        {isLoggedIn && <Route path="/" element={<Navigate replace to="/contracts" />} />}
        <Route path="*" element={<PageNotFound />} />
      </Route>
      <Route path="contracts" element={<DraftLayout />}>
        <Route path="view-contract/:id" element={<ViewContract />} />
      </Route>
    </Routes>
  );
}

export default Root;
