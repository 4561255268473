import React from 'react';

import { Skeleton, Stack } from '@mui/material';

export default function SentenceLoader() {
  return (
    <Stack gap={2} my={2}>
      {Array(6)
        .fill(0)
        .map((_, index: number) => (
          <Skeleton key={index} animation="wave" />
        ))}
    </Stack>
  );
}
