import React from 'react';

import { Box, Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';

import Header from './Header';
import SideBar from './SideBar';

const Layout = () => {
  return (
    <Box>
      <Header />
      <Stack direction="row" sx={{ height: '90vh', width: '100%' }}>
        <SideBar />
        <Box sx={{ flex: 1, padding: '10px 15px', overflow: 'auto' }}>
          <main>
            <Outlet />
          </main>
        </Box>
      </Stack>
    </Box>
  );
};

export default Layout;
