/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';

import ViewDocumentDrawer from './ViewDocumentDrawer';

interface Props {
  fileName: string;
  downloadFile: any;
  fileId: string;
}

const OriginalDocuments: React.FC<Props> = ({ fileName, downloadFile, fileId }) => {
  const [viewDocumentId, setViewDocumentId] = useState<string>('');
  const [openViewDrawer, setOpenViewDrawer] = useState<boolean>(false);
  const handleCloseViewDrawer = () => {
    setOpenViewDrawer(false);
    setViewDocumentId('');
  };
  return (
    <React.Fragment>
      <Accordion
        sx={{
          padding: 0,
        }}
        className="according-class"
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography fontSize="14px" fontWeight={600}>
            Original Documents
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack width="100%" spacing={2} padding="0 10px">
            <Stack
              width="100%"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography fontWeight={600}>{fileName}</Typography>
              <Stack spacing={1} direction="row" alignItems="center">
                <Chip label="Master" style={{ background: '#FFECF1' }} />
                <IconButton onClick={() => downloadFile({ contract_id: fileId })}>
                  <FileDownloadOutlinedIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setOpenViewDrawer(true);
                    setViewDocumentId(fileId);
                  }}
                >
                  <VisibilityOffIcon />
                </IconButton>
              </Stack>
            </Stack>
          </Stack>
        </AccordionDetails>
      </Accordion>
      {openViewDrawer && (
        <ViewDocumentDrawer
          open={openViewDrawer}
          onClose={handleCloseViewDrawer}
          documentId={viewDocumentId}
        />
      )}
    </React.Fragment>
  );
};

export default OriginalDocuments;
