/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { Box } from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import ConnectedDocuments from './ConnectedDocuments';
import ContractDetails from './ContractDetails';
import OriginalDocuments from './OriginalDocument';
import { getDownloadUrl } from '../../../axiosClient/contractsApi';
import { download_file } from '../../../hooks/Helper';
import { RootState, useTypedSelector } from '../../../redux/store';

const AboutThisContract = () => {
  const { contract } = useTypedSelector((state: RootState) => state.contract);

  const { mutate: downloadFile } = useMutation({
    mutationFn: getDownloadUrl,
    onSuccess: (response: any) => {
      download_file(response);
    },
  });

  return (
    <Box>
      <ContractDetails contractData={contract} />
      <OriginalDocuments
        fileName={contract?.file_name}
        fileId={contract?.id}
        downloadFile={downloadFile}
      />
      <ConnectedDocuments
        type="Support"
        connectedDocument={contract?.support_document}
        downloadFile={downloadFile}
      />
      <ConnectedDocuments
        type="Amendment"
        connectedDocument={contract?.amendment}
        downloadFile={downloadFile}
      />
    </Box>
  );
};

export default AboutThisContract;
