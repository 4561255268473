import React from 'react';

import { Tab, TabProps, Typography } from '@mui/material';

interface IProps extends TabProps {
  count: string;
}

const StaticTabItem: React.FC<IProps> = ({ count, label, ...props }) => {
  return (
    <Tab
      sx={{
        background: '#88305F14',
        padding: '4px 16px',
        borderRadius: '5px',
        minWidth: '50px !important',
        marginX: '4px',
        alignItems: 'start',
        fontSize: '11px',
        minHeight: '60px',
      }}
      icon={
        <Typography variant="h5" sx={{ marginBottom: 0 }}>
          {count}
        </Typography>
      }
      label={label}
      {...props}
    />
  );
};

export default StaticTabItem;
