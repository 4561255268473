/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction, useState } from 'react';

import { GridColDef, GridSortModel } from '@mui/x-data-grid';
import { useDebouncedCallback } from 'use-debounce';

import DataGridTable from '../../components/dataGrid/DataGridTable';
import { GridCellExpand } from '../../components/dataGrid/GridCellExpand';
import NameAvatar from '../../components/dataGrid/NameAvatar';
import TableHeaderWithSearch from '../../components/dataGrid/TableHeaderWIthSearch';
import TableSearchInputComponent from '../../components/dataGrid/TableSearchInputComponent';
import { IObject } from '../../interfaces/IObject';

interface Props {
  data: any;
  isLoading: boolean;
  pageNumber: number;
  setPageNumberChange: Dispatch<SetStateAction<number>>;
  setFilters: Dispatch<SetStateAction<IObject>>;
  setSorting?: Dispatch<SetStateAction<GridSortModel>>;
  filters?: IObject;
}

const DictionaryTable = (props: Props) => {
  const {
    data,
    isLoading,
    pageNumber,
    setPageNumberChange,
    setFilters,
    setSorting,
    filters,
  } = props;

  const [searchDraft, setSearchDraft] = useState<boolean>(false);
  const [searchContract] = useState<boolean>(false);

  const handleFilterChange = useDebouncedCallback(
    (label: string, value: string | string[]) => {
      setFilters((prev: IObject) => ({ ...prev, [label]: value }));
    },
    1000
  );

  const handleContractNameClick = React.useCallback((id: string) => {
    window.open(`/dictionary/${id}`);
  }, []);

  const columns = React.useMemo<GridColDef<any>[]>(
    () => [
      {
        field: 'display_name',
        headerName: 'Display Name',
        minWidth: 300,
        flex: 1,
        sortable: false,
        renderHeader: () => {
          return searchDraft ? (
            <TableSearchInputComponent
              key="display_name"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, display_name: null }));
                setSearchDraft(false);
              }}
              placeholder="Search Name"
              handleChange={(e: React.BaseSyntheticEvent<HTMLInputElement>) =>
                handleFilterChange('display_name', e.target.value)
              }
            />
          ) : (
            <TableHeaderWithSearch title="Display Name" setIsSearch={setSearchDraft} />
          );
        },
        renderCell: (Params: any) => {
          return (
            <GridCellExpand
              value={Params?.row?.display_name}
              width={Params.colDef.computedWidth}
              onClick={() => handleContractNameClick(Params.row?.id)}
              cellStyle={{
                cursor: 'pointer',
              }}
            />
          );
        },
      },
      {
        field: 'name',
        headerName: 'Contract Type',
        minWidth: 240,
        flex: 1,
        sortable: !searchContract,
      },
      {
        field: 'created_by',
        headerName: 'Created By',
        minWidth: 120,
        flex: 1,
        sortable: false,
        renderCell: (Params: any) => {
          return (
            <NameAvatar
              firstName={Params?.row?.created_by?.first_name}
              lastName={Params?.row?.created_by?.last_name}
            />
          );
        },
      },
    ],
    [
      searchDraft,
      setFilters,
      handleFilterChange,
      handleContractNameClick,
      searchContract,
      filters,
    ]
  );

  return (
    <React.Fragment>
      <DataGridTable
        rows={data?.results || []}
        columns={columns}
        rowCount={data?.count}
        isLoading={isLoading}
        pageNumber={pageNumber}
        setPageNumberChange={setPageNumberChange}
        setSorting={setSorting}
      />
    </React.Fragment>
  );
};

export default DictionaryTable;
