import React from 'react';

import { Box } from '@mui/material';

const LoadingAnim = () => {
  return (
    <Box sx={{ textAlign: 'center', marginTop: '50px' }}>
      <Box className="lds-ripple">
        <Box></Box>
        <Box></Box>
      </Box>
    </Box>
  );
};

export default LoadingAnim;
