export const appOption = [
  {
    name: 'Umbrella',
    id: 'Umbrella',
  },
];

export const status_bg_color: Record<string, string> = {
  'Not Started': '#FFECF1',
  'In Progress': '#FEDDB5',
  Completed: '#CFE7BC',
  'Past Due Date': '#F2B8B5',
};
