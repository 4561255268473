import React, { useState } from 'react';

import RefreshIcon from '@mui/icons-material/Refresh';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, Button, IconButton, Stack } from '@mui/material';
import { GridSortModel } from '@mui/x-data-grid';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import CreateContractForm from './CreateContractForm';
import DictionaryTable from './DictionaryTable';
import { getDictionary } from '../../axiosClient/dictionaryApi';
import { IObject } from '../../interfaces/IObject';

const initialFilter = {
  contractType: null,
};

const AdminDataDictionary = () => {
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [sorting, setSorting] = useState<GridSortModel>([]);
  const [filters, setFilters] = useState<IObject>(initialFilter);
  const [openContractType, setOpenContractType] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['admin-data-dictionary', sorting, pageNumber, filters],
    queryFn: () => {
      let filterParam = '';
      for (const key in filters) {
        if (filters[key]) {
          filterParam = `${filterParam}&${key}=${filters[key]}`;
        }
      }
      let sortingParam = '';
      if (sorting.length > 0) {
        for (let i = 0; i < sorting.length; i++) {
          if (sorting[i].sort === 'asc') {
            sortingParam = `${sortingParam}&ordering=${sorting[i].field}`;
          } else {
            sortingParam = `${sortingParam}&ordering=-${sorting[i].field}`;
          }
        }
      }
      let params = '';
      const page = pageNumber + 1;
      params = `${'?page=' + page}${sortingParam}${filterParam}`;
      return getDictionary(params);
    },
  });

  return (
    <Box mt={6}>
      <Stack gap="16px">
        <Stack sx={{ paddingLeft: '16px' }} gap="16px">
          <Button
            sx={{
              color: 'white',
              backgroundColor: 'riPrimary.500',
              padding: '8px 16px',
              borderRadius: '6px',
              fontSize: '11px',
              width: 'fit-content',
              display: 'flex',
              flexDirection: 'column',
              lineHeight: 1,
              alignItems: 'center',

              '&:hover': {
                backgroundColor: 'riPrimary.500',
              },
            }}
            onClick={() => {
              setOpenContractType(true);
            }}
          >
            <UploadFileIcon fontSize="small" />
            <br />
            Add New Contract Type
          </Button>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: '10px' }}>
              <Stack direction="column" alignItems="start">
                <IconButton
                  onClick={() =>
                    queryClient.invalidateQueries({
                      queryKey: ['admin-data-dictionary'],
                    })
                  }
                >
                  <RefreshIcon />
                </IconButton>
              </Stack>
            </Box>
          </Box>
        </Stack>
        <DictionaryTable
          data={data}
          isLoading={isLoading || isFetching}
          pageNumber={pageNumber}
          setPageNumberChange={setPageNumber}
          setFilters={setFilters}
          setSorting={setSorting}
          filters={filters}
        />
      </Stack>
      <CreateContractForm
        open={openContractType}
        onClose={() => setOpenContractType(false)}
      />
    </Box>
  );
};

export default AdminDataDictionary;
