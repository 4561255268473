export const setLocalStorage = (key: string, value: string) => {
  if (window) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};
export const removeLocalStorage = (key: string) => {
  if (window) {
    localStorage.removeItem(key);
  }
};
export const getLocalStorage = (key: string) => {
  if (window) {
    if (localStorage.getItem(key)) {
      return JSON.parse(localStorage.getItem(key) || `""`);
    } else {
      return null;
    }
  }
};
export const setKeyCloakRealmOnLS = (realm: string) => {
  if (realm) {
    setLocalStorage('kcRealm', realm);
  }
};
export const getKeyCloakRealmFromLS = () => {
  const realmName = getLocalStorage('kcRealm');

  return realmName;
};
export const removeKeyCloakRealmOnLS = () => {
  removeLocalStorage('kcRealm');
};
