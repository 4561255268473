import React, { FC, useCallback } from 'react';

import { Button, Stack } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';

import { postContractType } from '../../axiosClient/dictionaryApi';
import ControlledTextField from '../../components/ControlledTextField';
import CustomModal from '../../components/CustomModal';

interface IProps {
  open: boolean;
  onClose: VoidFunction;
}

const CreateContractForm: FC<IProps> = (props) => {
  const { open, onClose } = props;
  const methods = useForm();
  const { handleSubmit, control } = methods;

  const queryClient = useQueryClient();

  const { mutate: createContractMutation } = useMutation({
    mutationKey: ['create-contract'],
    mutationFn: postContractType,
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries({ queryKey: ['admin-data-dictionary'] });
    },
  });

  const onSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data: any) => {
      createContractMutation({
        name: data.name,
        display_name: data.name,
      });
    },
    [createContractMutation]
  );

  return (
    <CustomModal
      maxWidth="md"
      title="Add Contract Type"
      open={open}
      handleClose={onClose}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack sx={{ minWidth: '400px', padding: '30px 10px' }} gap={2}>
            <ControlledTextField name="name" control={control} placeholder="Name" />
            <Button variant="contained" sx={{ whiteSpace: 'nowrap' }} type="submit">
              Submit
            </Button>
          </Stack>
        </form>
      </FormProvider>
    </CustomModal>
  );
};

export default CreateContractForm;
