import colors from './colors';
const textFieldTheme = {
  styleOverrides: {
    root: {
      '&.input-field': {
        height: '56px',
        borderRadius: '4px',
        '& fieldset': {
          borderWidth: '2px',
        },
      },
      '&.search-field': {
        backgroundColor: colors.riPrimary?.[50],
        color: colors.riNeutralVariant?.[700],
        borderRadius: '50px',
        '& fieldset': {
          border: '0px',
        },
      },
    },
  },
};
export default textFieldTheme;
