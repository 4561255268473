import React, { useState } from 'react';

import './App.css';

import { Box } from '@mui/material';
import { AuthClientTokens } from '@react-keycloak/core';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { RouterProvider } from 'react-router-dom';

import { getCustomKeyCloakByRealm } from './Configuration/keycloak';
import {
  getKeyCloakRealmFromLS,
  setKeyCloakRealmOnLS,
  setLocalStorage,
} from './hooks/authentication';
import PrivateRoute from './Keycloak/privateRoute';
import Login from './Pages/Login';
import { privateRouter } from './routes/route';

const App = () => {
  const keyCloakRealm = getKeyCloakRealmFromLS();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [keycloakConfig, setKeycloakConfig] = useState<any>(
    keyCloakRealm && keyCloakRealm.length > 0
      ? getCustomKeyCloakByRealm(keyCloakRealm)
      : null
  );

  const onKeycloakTokens = (tokens: AuthClientTokens) => {
    if (tokens && tokens.token && tokens.refreshToken) {
      setLocalStorage('accessToken', tokens.token);
      setLocalStorage('refreshToken', tokens.refreshToken);
    }
  };

  const handleKeycloakRealmNameChange = (realmName: string) => {
    setKeyCloakRealmOnLS(realmName.toString());
    setKeycloakConfig(
      realmName && realmName.length > 0 ? getCustomKeyCloakByRealm(realmName) : null
    );
  };

  return (
    <Box>
      {keycloakConfig ? (
        <ReactKeycloakProvider authClient={keycloakConfig} onTokens={onKeycloakTokens}>
          <PrivateRoute handleKeycloakRealmNameChange={handleKeycloakRealmNameChange}>
            <RouterProvider router={privateRouter} />
          </PrivateRoute>
        </ReactKeycloakProvider>
      ) : (
        <Login handleKeycloakRealmNameChange={handleKeycloakRealmNameChange} />
      )}
    </Box>
  );
};

export default App;
