/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import { GridRowId } from '@mui/x-data-grid';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import { fetchUsers } from '../../../axiosClient/authApi';
import { updateContract } from '../../../axiosClient/dictionaryApi';
import CustomChip from '../../../components/CustomChip';
import CustomModal from '../../../components/CustomModal';
import RISelectComponent from '../../../components/SelectComponent';
import { IContract } from '../../../interfaces/IContract';
import { IOptions } from '../../../interfaces/IOptions';
import { IUser } from '../../../interfaces/IUser';

interface IProps {
  open: boolean;
  onClose: VoidFunction;
  contractId: GridRowId[];
  contract_data?: IContract;
}

const AddCoOwner: React.FC<IProps> = ({ open, onClose, contractId, contract_data }) => {
  const { control, handleSubmit, reset, setValue } = useForm();

  useEffect(() => {
    const ownerIds = contract_data?.owners?.map((owner: IUser) => owner?.id) || [];
    const isAlreadyCreator = ownerIds.includes(contract_data?.created_by?.id || '');
    setValue(
      'owners',
      isAlreadyCreator ? ownerIds : [contract_data?.created_by?.id, ...ownerIds]
    );
  }, [contract_data?.owners]);

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { data: assigneeData, isLoading } = useQuery({
    queryKey: ['co-owner-assignee'],
    queryFn: fetchUsers,
    select: (response) => response.results,
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  const { mutate: add_Co_Owner, isPending } = useMutation({
    mutationFn: updateContract,
    onSuccess: () => {
      enqueueSnackbar('Successfully added co-owner!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      handleClose();
      queryClient.invalidateQueries({ queryKey: ['contracts'] });
    },
    onError: () => {
      enqueueSnackbar('Failed to add co-owner!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const onSubmit = (data: any) => {
    const filterUsers = assigneeData?.filter((item: IOptions) =>
      data?.owners?.includes(item?.id)
    );

    const groups = contract_data?.groups as IOptions[];

    filterUsers?.forEach((item: any) => {
      item?.groups?.forEach((group: IOptions) => {
        const index = groups.findIndex((g) => g.id === group.id);
        if (index === -1) {
          groups.push(group);
        }
      });
    });

    const payload = {
      id: contractId?.[0],
      body: { ...data, groups: groups.map((group) => group.id) },
    };
    add_Co_Owner(payload);
  };

  return (
    <CustomModal title="Add Co-owner" open={open} handleClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} width="500px" pt="20px">
          <RISelectComponent
            name="owners"
            control={control}
            label="Select user"
            options={assigneeData || []}
            loading={isLoading}
            labelKey="name"
            isMultiselect={true}
            renderCustomComponent={(value: any) => (
              <CustomChip key={value?.id} label={value?.name} />
            )}
          />
          <Stack direction="row" spacing={1}>
            <LoadingButton loading={isPending} type="submit" variant="contained">
              Add Co-owner
            </LoadingButton>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
          </Stack>
        </Stack>
      </form>
    </CustomModal>
  );
};

export default AddCoOwner;
