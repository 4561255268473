/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { Stack } from '@mui/material';
import { useQueries } from '@tanstack/react-query';
import * as changesets from 'json-diff-ts';

import ContractInsights from './ContractInsights';
import SentenceLoader from './SentenceLoader';
import { getInsights } from '../../../axiosClient/contractsApi';

interface IProps {
  id?: string;
  sections?: any;
  section: string;
}

const SectionList = (props: IProps) => {
  const { sections, id, section } = props;

  const combinedQueries = useQueries({
    queries:
      sections && id
        ? sections.map((data: any) => {
            return {
              queryKey: ['insights-list', data.id],
              queryFn: () => getInsights(data.id),
            };
          })
        : [],
    combine: (results) => {
      return {
        data: results.map((result) => {
          let changeList;
          const contractData: any = result?.data;
          if (contractData) {
            const diff = JSON.parse(JSON.stringify(contractData.edited_content));

            const orig = JSON.parse(JSON.stringify(contractData.raw_data));
            if (diff) {
              const unFlattenChanges = changesets.unflattenChanges(diff.update);
              changeList = JSON.parse(
                JSON.stringify(
                  changesets.applyChangeset(
                    JSON.parse(JSON.stringify(orig)),
                    unFlattenChanges
                  )
                )
              );
            } else {
              changeList = JSON.parse(
                JSON.stringify(
                  changesets.applyChangeset(JSON.parse(JSON.stringify(orig)), diff)
                )
              );
            }
            contractData.changeList = changeList;
          }
          return contractData;
        }),
        pending: results.some((result) => result.isPending),
      };
    },
  });

  return (
    <>
      {combinedQueries.pending ? (
        <Stack style={{ padding: '5px' }}>
          <SentenceLoader />
        </Stack>
      ) : (
        <ContractInsights id={id} section={section} insights={combinedQueries} />
      )}
    </>
  );
};

export default SectionList;
