/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Button, Chip, Stack, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import ReminderConfirmationModal from './ReminderConfirmationModal';
import {
  calculateReminderId,
  handleReminder,
  reminder,
  reminderStatus,
} from './ReminderStatic';
import { fetchUsers } from '../../../../axiosClient/authApi';
import { createReminder, editReminder } from '../../../../axiosClient/reminders';
import ControlledTextField from '../../../../components/ControlledTextField';
import CustomChip from '../../../../components/CustomChip';
import NotepadComponent from '../../../../components/NotepadComponent';
import RISelectComponent from '../../../../components/SelectComponent';
import SelectElement from '../../../../components/SelectElement';
import UploadSupportFile from '../UploadSupportFile';

interface Props {
  onClose: VoidFunction;
  contractData: any;
  reminderEditData?: any;
  setViewOnly?: Dispatch<SetStateAction<boolean>>;
  viewOnly?: boolean;
  user_data?: any;
  sectionName?: string;
  clauseItem?: any;
}

const ReminderForm: React.FC<Props> = ({
  onClose,
  contractData,
  reminderEditData,
  setViewOnly,
  viewOnly = false,
  user_data,
  sectionName,
  clauseItem,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [isShowUploadField, setIsShowUploadField] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [viewDocumentId, setViewDocumentId] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [openViewDrawer, setOpenViewDrawer] = useState<boolean>(false);

  const handleCloseSupportDoc = () => {
    setIsShowUploadField(false);
  };

  const methods = useForm({
    defaultValues: {
      ...reminderEditData,
      users: reminderEditData?.users?.map((user: any) => user?.id),
      support_documents: reminderEditData?.support_documents?.[0]?.id,
      when_to_remind:
        reminderEditData?.due_date && reminderEditData?.when_to_remind
          ? calculateReminderId(
              reminderEditData?.due_date,
              reminderEditData?.when_to_remind
            )
          : '7_days_before',
      note: reminderEditData?.note
        ? reminderEditData?.note
        : reminderEditData?.section_name
          ? `<h2>${reminderEditData.section_name}</h2><p>${reminderEditData.clause}</p>`
          : '',
    },
  });

  const { handleSubmit, control, reset, setValue, watch } = methods;
  const queryClient = useQueryClient();
  const selectedApprovers = watch('users');

  useEffect(() => {
    setValue('contract', contractData?.title);
  }, [contractData, setValue]);

  useEffect(() => {
    if (sectionName) {
      const note = `<h2>${sectionName}</h2><p>${clauseItem.original_context || clauseItem.sentence}</p>`;
      setValue('note', note);
    }
  }, [sectionName, reminderEditData]);

  const { data: approversData, isLoading: approversLoading } = useQuery({
    queryKey: ['users'],
    queryFn: async () => await fetchUsers(),
    select: (response) => response.results as any,
  });

  const { mutate: createReminderMutation } = useMutation({
    mutationKey: ['create_reminder'],
    mutationFn: createReminder,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['get_reminders'],
      });
      onClose();
      reset();
      enqueueSnackbar('Obligation marked successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
    onError: () => {
      enqueueSnackbar('Failed to mark Obligation!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: update_reminder } = useMutation({
    mutationKey: ['update_reminder'],
    mutationFn: editReminder,
    onSuccess: () => {
      enqueueSnackbar('Obligation updated successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({
        queryKey: ['get_reminders'],
      });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to update Obligation!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const handleRefresh = () => {
    queryClient.invalidateQueries({ queryKey: ['get_contract_data_by_id'] });
  };

  const onSubmit = (data: any) => {
    let payload = {
      ...data,
      support_documents: data?.support_documents ? [data?.support_documents] : [],
      contract: contractData?.id,
      app: 'Umbrella',
      when_to_remind:
        data?.when_to_remind === 'Don’t remind me'
          ? 'Don’t remind me'
          : handleReminder(data?.due_date, data?.when_to_remind),
      ...(sectionName && {
        section_name: sectionName,
        clause: clauseItem.sentence,
      }),
    };

    if (reminderEditData?.id) {
      delete payload['id'];
      delete payload['comments'];
      delete payload['created'];
      delete payload['draft'];
      delete payload['external_user'];
      delete payload['checklist'];
      delete payload['draft_item'];
      delete payload['deviation'];

      payload = {
        id: reminderEditData?.id,
        body: {
          ...payload,
        },
      };
      update_reminder(payload);
    } else {
      createReminderMutation(payload);
    }
  };

  const isReadOnly = useMemo(
    () =>
      reminderEditData?.id &&
      (reminderEditData?.created_by?.id !== user_data?.id || viewOnly),
    [reminderEditData, user_data?.id, viewOnly]
  );

  const disabledFields = useMemo(
    () =>
      reminderEditData?.id &&
      (reminderEditData?.created_by?.id !== user_data?.id || viewOnly),
    [reminderEditData, user_data?.id, viewOnly]
  );

  const disableNotes = useMemo(() => {
    if (sectionName || reminderEditData?.section_name) {
      return true;
    } else if (reminderEditData?.note && viewOnly) {
      return true;
    } else {
      return false;
    }
  }, [sectionName, reminderEditData, viewOnly]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} sx={{ width: '700px', padding: '30px 10px 10px' }}>
          <Grid container gap={2}>
            <Grid size={{ sm: 12 }}>
              <ControlledTextField
                name="name"
                control={control}
                label="Obligation Name"
                fullWidth
                required
                disabled={isReadOnly}
              />
            </Grid>
            <Stack width="100%" spacing={2} direction="row">
              <Controller
                name="due_date"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="date"
                    label="Due Date"
                    fullWidth
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isReadOnly}
                    inputProps={{
                      min: new Date().toISOString().split('T')[0],
                    }}
                  />
                )}
              />
              <SelectElement
                fullWidth
                name="when_to_remind"
                control={control}
                label="Obligation"
                options={reminder}
                labelKey="name"
                disabled={isReadOnly}
              />
            </Stack>

            <SelectElement
              fullWidth
              name="status"
              control={control}
              label="Status"
              options={reminderStatus}
              labelKey="name"
              disabled={viewOnly}
            />

            <Stack width="100%" spacing={2}>
              <Typography fontWeight={'700'}>Share this Obligation</Typography>
              <RISelectComponent
                readOnly={isReadOnly}
                name="users"
                control={control}
                label="Select Users"
                options={approversData}
                loading={approversLoading}
                isMultiselect={true}
                renderCustomComponent={(value: any, props) => (
                  <CustomChip
                    icon={<PersonOutlineOutlinedIcon />}
                    key={value?.id}
                    label={value?.name || ''}
                    {...props}
                  />
                )}
                textFieldProps={{
                  helperText: 'you can select multiple users',
                }}
              />
              <NotepadComponent name="note" control={control} readOnly={disableNotes} />
            </Stack>
            {viewOnly && reminderEditData?.support_documents?.length > 0 ? (
              <Stack mt={6} spacing={1} width="100%">
                <Typography fontWeight={'700'}>Support document</Typography>
                {reminderEditData?.support_documents?.map((item: any, index: number) => (
                  <Stack
                    key={index}
                    width="100%"
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography fontWeight={600}>{item?.file_name}</Typography>
                    <Stack spacing={1} direction="row" alignItems="center">
                      {/* <IconButton onClick={() => downloadFile(item?.id)}>
                        <FileDownloadOutlinedIcon />
                      </IconButton> */}
                      {/* <IconButton
                        onClick={() => {
                          setOpenViewDrawer(true);
                          setViewDocumentId(item?.id);
                        }}
                      >
                        <VisibilityOffIcon />
                      </IconButton> */}
                    </Stack>
                  </Stack>
                ))}
              </Stack>
            ) : (
              <Stack mt={6} width="100%" spacing={1}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography fontWeight={'700'} mt={0}>
                    Associated document
                  </Typography>
                  {!viewOnly && (
                    <Button
                      variant="text"
                      sx={{ padding: 0 }}
                      startIcon={<FileUploadOutlinedIcon />}
                      onClick={() => setIsShowUploadField(true)}
                      disabled={disabledFields}
                    >
                      Upload Support document
                    </Button>
                  )}
                </Stack>
                <RISelectComponent
                  readOnly={isReadOnly}
                  name="support_documents"
                  control={control}
                  label="Support document"
                  valueKey="id"
                  labelKey="file_name"
                  options={contractData?.support_document}
                  renderCustomComponent={(value: any, props) => (
                    <Chip
                      style={{
                        background: '#FFECF1',
                        padding: '15px 10px',
                        borderRadius: '5px',
                        color: '#6D264C',
                      }}
                      icon={<FolderOutlinedIcon style={{ color: '#6D264C' }} />}
                      label={value?.title}
                      {...props}
                    />
                  )}
                  textFieldProps={{
                    helperText: 'Select or upload support document',
                  }}
                />
              </Stack>
            )}
            <Stack spacing={0} width="100%">
              <Stack direction="row" justifyContent="space-between" width="100%">
                <Stack direction="row">
                  {!reminderEditData?.id && (
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{ height: '40px', width: 'max-content' }}
                      disabled={!selectedApprovers?.length}
                    >
                      Mark an Obligation
                    </Button>
                  )}

                  {reminderEditData?.id && viewOnly && (
                    <Button
                      variant="contained"
                      sx={{ height: '40px' }}
                      onClick={() => setViewOnly?.(false)}
                    >
                      Edit
                    </Button>
                  )}

                  {reminderEditData?.id && !viewOnly && (
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{ height: '40px' }}
                      disabled={!selectedApprovers?.length}
                    >
                      Save
                    </Button>
                  )}
                  <Button
                    variant="outlined"
                    onClick={onClose}
                    startIcon={<CloseIcon />}
                    sx={{ height: '40px' }}
                  >
                    Cancel
                  </Button>
                </Stack>

                {reminderEditData?.id && (
                  <Stack justifyContent="center" width="100%" alignItems="end">
                    <Button
                      variant="text"
                      sx={{ padding: 0 }}
                      startIcon={<NotificationsIcon />}
                      disabled={disabledFields}
                      onClick={() => setOpenForm(true)}
                    >
                      Send an Obligation now
                    </Button>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Grid>
        </Stack>
      </form>

      {isShowUploadField && (
        <UploadSupportFile
          open={isShowUploadField}
          onClose={handleCloseSupportDoc}
          handleRefresh={handleRefresh}
          allContracts={contractData}
          type="Support Document"
        />
      )}
      {/* {openViewDrawer && (
        <ViewDocumentDrawer
          open={openViewDrawer}
          onClose={handleCloseViewDrawer}
          documentId={viewDocumentId}
        />
      )} */}
      {openForm && (
        <ReminderConfirmationModal
          open={openForm}
          handleClose={() => setOpenForm(false)}
          reminderData={reminderEditData}
          onClose={onClose}
        />
      )}
    </FormProvider>
  );
};

export default ReminderForm;
