/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react';

import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Chip } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import { editReminder } from '../../../../axiosClient/reminders';
import ControlledTextField from '../../../../components/ControlledTextField';
import CustomModal from '../../../../components/CustomModal';

interface ReminderConfirmationModalProps {
  open: boolean;
  handleClose: VoidFunction;
  onClose?: VoidFunction;
  users?: string;
  approvalData?: any;
  propsData?: any;
  draftItemData?: any;
  getUserDetail?: any;
  name?: string;
  checklistReminderData?: any;
  reminderData?: any;
  reminderId?: any;
  handleCloseDialog?: VoidFunction;
  dialogReminderData?: any;
  approverReminderId?: any;
  reminderById?: any;
}

const ReminderConfirmationModal: React.FC<ReminderConfirmationModalProps> = ({
  open,
  handleClose,
  onClose,
  users,
  approvalData,
  propsData,
  draftItemData,
  getUserDetail,
  name,
  checklistReminderData,
  reminderData,
  reminderId,
  handleCloseDialog,
  dialogReminderData,
  approverReminderId,
  reminderById,
}) => {
  const { handleSubmit, control } = useForm();
  const queryClient = useQueryClient();

  const { mutate: update_reminder } = useMutation({
    mutationKey: ['update_reminder'],
    mutationFn: editReminder,
    onSuccess: () => {
      enqueueSnackbar('Obligation Marked successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({
        queryKey: ['get_reminders'],
      });
      handleClose();
      onClose?.();
      handleCloseDialog?.();
    },
    onError: () => {
      enqueueSnackbar('Failed to mark Obligation!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const sendReminder = useCallback(
    (data: any) => {
      const reminderPayload = {
        send_reminder: true,
        comments: data?.note,
      };

      const sendNowReminderPayload = {
        id:
          dialogReminderData?.id ||
          approverReminderId ||
          reminderData?.id ||
          reminderId ||
          checklistReminderData?.results?.[0]?.id ||
          reminderData?.reminder_id,
        body: reminderPayload,
      };

      update_reminder(sendNowReminderPayload);
    },
    [
      checklistReminderData,
      reminderData,
      update_reminder,
      reminderId,
      dialogReminderData,
      approverReminderId,
    ]
  );

  const onSubmit = (data: any) => {
    sendReminder({ ...data, users });
  };

  return (
    <CustomModal
      open={open}
      handleClose={handleClose}
      title="Send a reminder"
      maxWidth="md"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} pt="20px" sx={{ width: '500px' }}>
          <Typography variant="subtitle2" color="#000">
            Are you sure you want to send a reminder to the following:
          </Typography>

          <Stack direction="row" justify-content="start" align-item="center" spacing={1}>
            {dialogReminderData?.external_user?.length > 0 ? (
              dialogReminderData?.external_user?.map((user: any, index: number) => (
                <Chip
                  key={index}
                  sx={{
                    background: '#FFF7FA',
                    borderRadius: '8px',
                  }}
                  icon={<PersonOutlineIcon sx={{ fill: '#88305F', fontSize: '14px' }} />}
                  label={
                    <Typography variant="subtitle2" fontWeight="500">
                      {user?.name}
                    </Typography>
                  }
                  size="small"
                />
              ))
            ) : dialogReminderData?.users?.length > 0 ? (
              dialogReminderData.users.map((user: any, index: number) => (
                <Chip
                  key={index}
                  sx={{
                    background: '#FFF7FA',
                    borderRadius: '8px',
                  }}
                  icon={<PersonOutlineIcon sx={{ fill: '#88305F', fontSize: '14px' }} />}
                  label={
                    <Typography variant="subtitle2" fontWeight="500">
                      {user?.first_name}
                    </Typography>
                  }
                  size="small"
                />
              ))
            ) : reminderById?.users?.length > 0 ? (
              reminderById?.users?.map((user: any, index: number) => (
                <Chip
                  key={index}
                  sx={{
                    background: '#FFF7FA',
                    borderRadius: '8px',
                  }}
                  icon={<PersonOutlineIcon sx={{ fill: '#88305F', fontSize: '14px' }} />}
                  label={
                    <Typography variant="subtitle2" fontWeight="500">
                      {user?.first_name}
                    </Typography>
                  }
                  size="small"
                />
              ))
            ) : reminderData?.users?.length > 0 ? (
              reminderData?.users?.map((user: any, index: number) => (
                <Chip
                  key={index}
                  sx={{
                    background: '#FFF7FA',
                    borderRadius: '8px',
                  }}
                  icon={<PersonOutlineIcon sx={{ fill: '#88305F', fontSize: '14px' }} />}
                  label={
                    <Typography variant="subtitle2" fontWeight="500">
                      {user?.first_name}
                    </Typography>
                  }
                  size="small"
                />
              ))
            ) : reminderData?.external_user?.length > 0 ? (
              reminderData?.external_user?.map((user: any, index: number) => (
                <Chip
                  key={index}
                  sx={{
                    background: '#FFF7FA',
                    borderRadius: '8px',
                  }}
                  icon={<PersonOutlineIcon sx={{ fill: '#88305F', fontSize: '14px' }} />}
                  label={
                    <Typography variant="subtitle2" fontWeight="500">
                      {user?.name}
                    </Typography>
                  }
                  size="small"
                />
              ))
            ) : (
              <Chip
                sx={{
                  background: '#FFF7FA',
                  borderRadius: '8px',
                }}
                icon={<PersonOutlineIcon sx={{ fill: '#88305F', fontSize: '14px' }} />}
                label={
                  <Typography variant="subtitle2" fontWeight="500">
                    {name ||
                      approvalData?.approvers?.first_name ||
                      getUserDetail?.first_name ||
                      draftItemData?.user_name ||
                      propsData?.user_name}
                  </Typography>
                }
                size="small"
              />
            )}
          </Stack>

          <Stack spacing={1}>
            <Typography variant="subtitle2" fontWeight="500">
              (Optional) Note for approver:
            </Typography>
            <ControlledTextField name="note" control={control} label="Note" fullWidth />
          </Stack>
          <Stack alignItems="center" direction="row" marginTop="20px">
            <Button variant="contained" type="submit">
              Yes, send reminder
            </Button>
            <Button variant="outlined" onClick={handleClose}>
              Close
            </Button>
          </Stack>
        </Stack>
      </form>
    </CustomModal>
  );
};

export default ReminderConfirmationModal;
