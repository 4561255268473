/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';

import { RootState, useAppDispatch, useTypedSelector } from '../../../redux/store';
import {
  saveHighlightedComponentType,
  saveHighlightedSentences,
  saveHighlightedTableCells,
} from '../ContractSlice';
import { sentenceInfo, tableInfo } from '../State/DocumentState';

interface Props {
  enableHighlightOption: boolean;
  editDataPoint: VoidFunction;
  enableSaveBtn?: boolean;
  previouslyLinkedSentences?: sentenceInfo[];
  previouslyLinkedTableCells?: tableInfo[];
  onClose: any;
  buttonLoading: boolean;
}

const SaveAndCancelSentence: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const { componentType, savedHighlightedSentences, savedHighlightedTableCells } =
    useTypedSelector((state: RootState) => state.contract);

  const selectedCount = (propsData: any): number => {
    const highlightData = savedHighlightedSentences || [];
    const previouslyLinkedSentences = propsData.previouslyLinkedSentences || [];
    let highlightCount = highlightData.length;
    const highlightTableCount = savedHighlightedTableCells || [];
    for (let i = 0; i < highlightTableCount.length; i++) {
      const dataPresent = highlightData.filter(
        (e: any) => e.paraId === highlightTableCount[i]['paraId']
      );
      if (dataPresent.length === 0) {
        if (
          previouslyLinkedSentences.filter(
            (e: any) => e.paraId === highlightTableCount[i]['paraId']
          ).length === 0
        ) {
          highlightCount = highlightCount + 1;
        }
      }
    }
    return highlightCount;
  };

  const [highlight, setHighlight] = useState(!props.enableHighlightOption);
  const [highlightCount, setHighlightCount] = useState<number>(selectedCount(props));

  useEffect(() => {
    setHighlightCount(selectedCount(props));
    const timeout = setTimeout(() => {
      setHighlightCount(selectedCount(props));
    }, 500);
    return () => clearTimeout(timeout);
  }, [props]);

  useEffect(() => {
    if (props.enableHighlightOption !== highlight) {
      setHighlight(!props.enableHighlightOption);
    }
  }, [props.enableHighlightOption]);

  const onSave = () => {
    props.editDataPoint();
    dispatch(
      saveHighlightedComponentType({ componentType: '', editOptionSelected: false })
    );
    setHighlight(false);
  };

  const editLinkedSentence = () => {
    const { previouslyLinkedSentences, previouslyLinkedTableCells } = props;
    setHighlight(true);
    if (previouslyLinkedSentences) {
      dispatch(
        saveHighlightedSentences({
          savedHighlightedSentences: previouslyLinkedSentences,
        })
      );
    }
    if (previouslyLinkedTableCells) {
      dispatch(
        saveHighlightedTableCells({
          savedHighlightedTableCells: previouslyLinkedTableCells,
        })
      );
    }
    dispatch(
      saveHighlightedComponentType({
        componentType: componentType,
        editOptionSelected: true,
      })
    );
    // saveHighlightedDataPoint(true);
  };

  const saveHighlightedDataPoint = () => {
    setHighlight(true);
    dispatch(
      saveHighlightedComponentType({
        componentType: componentType,
        editOptionSelected: true,
      })
    );

    // saveHighlightedDataPoint(true);
  };

  const cancel = () => {
    props.onClose();
    setHighlight(false);
    dispatch(
      saveHighlightedComponentType({ componentType: '', editOptionSelected: false })
    );

    dispatch(
      saveHighlightedSentences({
        savedHighlightedSentences: null,
      })
    );
    dispatch(
      saveHighlightedTableCells({
        savedHighlightedTableCells: null,
      })
    );
  };

  const showHighlightedCount = () => {
    const {
      enableHighlightOption,
      previouslyLinkedSentences,
      previouslyLinkedTableCells,
    } = props;

    if (previouslyLinkedSentences || previouslyLinkedTableCells) {
      if (
        (previouslyLinkedSentences && previouslyLinkedSentences.length > 0) ||
        (previouslyLinkedTableCells && previouslyLinkedTableCells.length > 0)
      ) {
        if (
          ((savedHighlightedSentences && savedHighlightedSentences.length > 0) ||
            (savedHighlightedTableCells && savedHighlightedTableCells.length > 0)) &&
          highlight
        ) {
          return (
            <Stack direction="row" justifyContent="space-between" width="100%">
              <Typography fontSize="13px" style={{ color: '#C1C1C1' }}>
                Edit linked sentences(s)
              </Typography>
              <Typography fontSize="13px">{highlightCount}</Typography>
            </Stack>
          );
        } else {
          return (
            <Stack direction="row" justifyContent="start" width="100%">
              <Button
                variant="text"
                style={{
                  color: '#88305F',
                  padding: 0,
                }}
                onClick={editLinkedSentence}
              >
                Edit linked sentences
                <span style={{ textTransform: 'lowercase' }}>(s)</span>
              </Button>
            </Stack>
          );
        }
      } else {
        if (
          ((savedHighlightedSentences && savedHighlightedSentences.length > 0) ||
            (savedHighlightedTableCells && savedHighlightedTableCells.length > 0)) &&
          highlight === true
        ) {
          return (
            <Stack direction="row" justifyContent="space-between" width="100%">
              <Typography fontSize="13px" style={{ color: '#C1C1C1' }}>
                Edit linked sentences(s)
              </Typography>
              <Typography fontSize="13px">{highlightCount}</Typography>
            </Stack>
          );
        }
        if (enableHighlightOption) {
          return (
            <Stack alignItems="start" width="100%">
              <Button
                variant="text"
                sx={{
                  padding: 0,
                }}
                style={{
                  color: highlight ? '#C1C1C1' : '#88305F',
                }}
                onClick={saveHighlightedDataPoint}
              >
                Link to sentences
                <span style={{ textTransform: 'lowercase' }}>(s)</span>
              </Button>
            </Stack>
          );
        }
      }
    } else {
      return (
        <Stack direction="row" justifyContent="start" width="100%">
          <Button style={{ color: '#88305F', padding: 0 }} onClick={editLinkedSentence}>
            Edit linked sentences
            <span style={{ textTransform: 'lowercase' }}>(s)</span>
          </Button>
        </Stack>
      );
    }
  };

  return (
    <>
      {componentType === 'sentence' && showHighlightedCount()}
      <Stack direction="row">
        <LoadingButton
          variant="contained"
          startIcon={<CheckIcon />}
          onClick={onSave}
          disabled={!props.enableSaveBtn}
          loading={props.buttonLoading}
        >
          Save
        </LoadingButton>
        <Button variant="outlined" onClick={cancel} startIcon={<CloseIcon />}>
          Cancel
        </Button>
      </Stack>
    </>
  );
};

export default SaveAndCancelSentence;
