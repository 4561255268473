/* eslint-disable @typescript-eslint/no-explicit-any */
import { WebViewerInstance } from '@pdftron/webviewer';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  dateInfo,
  eventInfo,
  phraseInfo,
  sentenceInfo,
  tableInfo,
} from './State/DocumentState';
import { IDraft } from '../../interfaces/IDraft';
import { contractApi } from '../../redux/services/contractApi';

const initialState = {
  fileLink: null,
  contract: null,
  instance: null,
  sentenceData: null,
  sentenceLoader: false,
  componentType: '',
  editOptionSelected: false,
  phraseEditOptionSelected: false,
  phraseDeleteStatus: false,
  phraseAddStatus: false,
  eventEditingStatus: false,
  dateEditingStatus: false,
  currencyList: [],
} as {
  fileLink: string | null;
  contract: IDraft | null;
  instance: WebViewerInstance | null;
  sentenceData: any;
  sentenceLoader: boolean;
  componentType: string;
  editOptionSelected: boolean;
  highlightedId?: number[] | null;
  savedHighlightedSentences?: sentenceInfo[] | null;
  savedHighlightedPhrases?: phraseInfo[] | null;
  phraseEditOptionSelected: boolean;
  phraseDeleteStatus: boolean;
  phraseInDeleteMode?: phraseInfo | null;
  phraseAddStatus: boolean;
  phraseInAddMode?: phraseInfo | null;
  savedHighlightedTableCells?: tableInfo[] | null;
  savedHighlightedDates?: dateInfo[] | null;
  dateInAddEditMode?: dateInfo | null;
  dateEditingStatus: boolean;
  savedHighlightedEvents?: eventInfo[] | null;
  eventInAddEdit: eventInfo;
  eventEditingStatus: boolean;
  currencyList: any;
  insightsSection: any;
};

const contractSlice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    setInstance: (state, action) => {
      state.instance = action.payload;
    },
    saveHighlightedComponentType: (
      state,
      {
        payload,
      }: PayloadAction<{
        componentType: string;
        editOptionSelected: boolean;
      }>
    ) => {
      state.componentType = payload.componentType;
      state.editOptionSelected = payload.editOptionSelected;
    },
    saveHighlightedId: (
      state,
      {
        payload,
      }: PayloadAction<{
        highlightedId?: number[] | null;
      }>
    ) => {
      state.highlightedId = payload.highlightedId;
    },
    saveHighlightedSentences: (
      state,
      { payload }: PayloadAction<{ savedHighlightedSentences?: sentenceInfo[] | null }>
    ) => {
      state.savedHighlightedSentences = payload.savedHighlightedSentences;
    },
    saveHighlightedPhrases: (
      state,
      { payload }: PayloadAction<{ savedHighlightedPhrases: phraseInfo[] | null }>
    ) => {
      state.savedHighlightedPhrases = payload.savedHighlightedPhrases;
    },
    savePhraseEditOption: (
      state,
      { payload }: PayloadAction<{ phraseEditOptionSelected: boolean }>
    ) => {
      state.phraseEditOptionSelected = payload.phraseEditOptionSelected;
    },
    saveDeletePhrase: (
      state,
      {
        payload,
      }: PayloadAction<{
        phraseDeleteStatus: boolean;
        phraseInDeleteMode?: phraseInfo | null;
      }>
    ) => {
      state.phraseDeleteStatus = payload.phraseDeleteStatus;
      state.phraseInDeleteMode = payload.phraseInDeleteMode;
    },
    saveAddPhrase: (
      state,
      {
        payload,
      }: PayloadAction<{
        phraseAddStatus: boolean;
        phraseInAddMode?: phraseInfo | null;
      }>
    ) => {
      state.phraseAddStatus = payload.phraseAddStatus;
      state.phraseInAddMode = payload.phraseInAddMode;
    },
    saveHighlightedTableCells: (
      state,
      { payload }: PayloadAction<{ savedHighlightedTableCells?: tableInfo[] | null }>
    ) => {
      state.savedHighlightedTableCells = payload.savedHighlightedTableCells;
    },
    saveHighlightedDates: (
      state,
      { payload }: PayloadAction<{ savedHighlightedDates?: dateInfo[] | null }>
    ) => {
      state.savedHighlightedDates = payload.savedHighlightedDates;
    },
    saveDateInAddEditMode: (
      state,
      { payload }: PayloadAction<{ dateInAddEditMode?: dateInfo | null }>
    ) => {
      state.dateInAddEditMode = payload.dateInAddEditMode;
    },
    saveDateEditingStatus: (
      state,
      { payload }: PayloadAction<{ dateEditingStatus: boolean }>
    ) => {
      state.dateEditingStatus = payload.dateEditingStatus;
    },
    saveHighlightedEvents: (
      state,
      { payload }: PayloadAction<{ savedHighlightedEvents?: eventInfo[] | null }>
    ) => {
      state.savedHighlightedEvents = payload.savedHighlightedEvents;
    },
    saveEventInAddEdit: (
      state,
      { payload }: PayloadAction<{ eventInAddEdit: eventInfo }>
    ) => {
      state.eventInAddEdit = payload.eventInAddEdit;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      contractApi.endpoints.getDocument.matchFulfilled,
      (state, { payload }) => {
        state.fileLink = payload.presigned_url;
      }
    );
    builder.addMatcher(
      contractApi.endpoints.getContract.matchFulfilled,
      (state, { payload }) => {
        state.contract = payload;
      }
    );
    builder.addMatcher(contractApi.endpoints.getExtractedData.matchPending, (state) => {
      state.sentenceLoader = true;
    }),
      builder.addMatcher(
        contractApi.endpoints.getExtractedData.matchFulfilled,
        (state, { payload }) => {
          state.sentenceData = payload.raw_content;
          state.sentenceLoader = false;
        }
      ),
      builder.addMatcher(
        contractApi.endpoints.getCurrencyList.matchFulfilled,
        (state, { payload }) => {
          state.currencyList = payload.results;
        }
      ),
      builder.addMatcher(
        contractApi.endpoints.getInsightsSection.matchFulfilled,
        (state, { payload }) => {
          state.insightsSection = payload;
        }
      );
  },
});

export const {
  setInstance,
  saveHighlightedComponentType,
  saveAddPhrase,
  saveDeletePhrase,
  saveEventInAddEdit,
  saveHighlightedEvents,
  saveHighlightedId,
  saveHighlightedPhrases,
  saveHighlightedSentences,
  saveHighlightedTableCells,
  savePhraseEditOption,
  saveHighlightedDates,
  saveDateEditingStatus,
  saveDateInAddEditMode,
} = contractSlice.actions;

export default contractSlice.reducer;
