/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { LoadingButton } from '@mui/lab';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import * as changesets from 'json-diff-ts';

import { RootState, useAppDispatch, useTypedSelector } from '../../../redux/store';
import {
  saveAddPhrase,
  saveDeletePhrase,
  saveHighlightedComponentType,
  saveHighlightedPhrases,
  saveHighlightedTableCells,
  savePhraseEditOption,
} from '../ContractSlice';
import {
  editedPhrases,
  LinkPhraseRequest,
  phraseInfo,
  tableInfo,
} from '../State/DocumentState';
import { getClauseDataFormat } from '../Utils/ClauseTypeUtils';
import {
  filterPhrasesFromPhrasesArray,
  filterTableCellsFromPhraseArray,
  getPhrasesFromChild,
  isTableCell,
} from '../Utils/docUtils';

interface Props {
  clauseName: string;
  clauseDisplayName: string;
  savedInsight: any;
  onClose: VoidFunction;
  id: string;
  updateContract: any;
  updatedClauseData: any;
  clauseData?: any;
  parentClauseType?: any;
  rawContent: any;
  sectionType: string;
  buttonLoading: boolean;
}

const EditPhrase: React.FC<Props> = (props) => {
  const {
    rawContent,
    id,
    onClose,
    savedInsight,
    clauseDisplayName,
    updateContract,
    clauseName,
    updatedClauseData,
    sectionType,
    buttonLoading,
  } = props;
  const dispatch = useAppDispatch();
  const {
    sentenceData,
    savedHighlightedPhrases,
    componentType,
    phraseInAddMode,
    phraseEditOptionSelected,
  } = useTypedSelector((state: RootState) => state.contract);

  const [isAddingPhrase, setIsAddingPhrase] = useState(false);
  const [currentEditingPhrase, setCurrentEditingPhrase] = useState<phraseInfo | null>(
    null
  );

  useEffect(() => {
    const savedPhrase = savedInsight.filter(
      (data: any) => data.value && data.value !== 'cannot_determine'
    );
    const oldPhrases = getPhrasesFromChild(savedPhrase, componentType);
    dispatch(saveHighlightedPhrases({ savedHighlightedPhrases: oldPhrases }));
  }, []);

  useEffect(() => {
    if (phraseInAddMode) {
      setIsAddingPhrase(false);
    }
  }, [phraseInAddMode]);

  const getAddedAndDeletedPhrases = (
    previousLinkedPhrases: phraseInfo[],
    changedLinkedPhrases: phraseInfo[]
  ): editedPhrases => {
    const addedPhrases: phraseInfo[] = [];
    const deletedPhrases: phraseInfo[] = [];
    if (previousLinkedPhrases.length) {
      if (changedLinkedPhrases && changedLinkedPhrases.length) {
        //get newly added phrases
        for (let i = 0; i < changedLinkedPhrases.length; i++) {
          let exists = false;
          for (let j = 0; j < previousLinkedPhrases.length; j++) {
            if (changedLinkedPhrases[i].paraId === previousLinkedPhrases[j].paraId) {
              if (
                changedLinkedPhrases[i].startWordId ===
                  previousLinkedPhrases[j].startWordId &&
                changedLinkedPhrases[i].endWordId === previousLinkedPhrases[j].endWordId
              ) {
                exists = true;
                break;
              }
            }
          }
          if (exists === false) {
            addedPhrases.push(changedLinkedPhrases[i]);
          }
        }

        //get Deleted phrases
        for (let i = 0; i < previousLinkedPhrases.length; i++) {
          let exists = false;
          for (let j = 0; j < changedLinkedPhrases.length; j++) {
            if (previousLinkedPhrases[i].paraId === changedLinkedPhrases[j].paraId) {
              if (
                previousLinkedPhrases[i].startWordId ===
                  changedLinkedPhrases[j].startWordId &&
                previousLinkedPhrases[i].endWordId === changedLinkedPhrases[j].endWordId
              ) {
                exists = true;
                break;
              }
            }
          }
          if (exists === false) {
            deletedPhrases.push(previousLinkedPhrases[i]);
          }
        }
        //all deleted
      } else if (changedLinkedPhrases.length === 0) {
        for (let i = 0; i < previousLinkedPhrases.length; i++) {
          deletedPhrases.push(previousLinkedPhrases[i]);
        }
      }
    } else {
      //newly added
      if (changedLinkedPhrases && changedLinkedPhrases.length) {
        for (let i = 0; i < changedLinkedPhrases.length; i++) {
          addedPhrases.push(changedLinkedPhrases[i]);
        }
      }
    }
    const biType = clauseDisplayName;

    const tempEditedPhrases: editedPhrases = {
      upsert: addedPhrases,
      deleted: deletedPhrases,
      bi: biType,
    };
    return tempEditedPhrases;
  };

  const getEditedTableCellPhrases = (
    previousLinkedTableCells: phraseInfo[],
    changedLinkedTableCells: phraseInfo[]
  ) => {
    const addedTableCells: phraseInfo[] = [];
    const deletedTableCells: phraseInfo[] = [];
    if (previousLinkedTableCells.length > 0) {
      if (changedLinkedTableCells.length > 0) {
        //newly added
        for (let i = 0; i < changedLinkedTableCells.length; i++) {
          let addedCellExists = false;
          for (let j = 0; j < previousLinkedTableCells.length; j++) {
            if (
              changedLinkedTableCells[i].paraId === previousLinkedTableCells[j].paraId &&
              changedLinkedTableCells[i].rowId === previousLinkedTableCells[j].rowId &&
              changedLinkedTableCells[i].columnId === previousLinkedTableCells[j].columnId
            ) {
              addedCellExists = true;
              break;
            }
          }
          if (addedCellExists === false) {
            addedTableCells.push(changedLinkedTableCells[i]);
          }
        }

        //deleted elements
        for (let i = 0; i < previousLinkedTableCells.length; i++) {
          let deletedCellExists = false;
          for (let j = 0; j < changedLinkedTableCells.length; j++) {
            if (
              previousLinkedTableCells[i].paraId === changedLinkedTableCells[j].paraId &&
              previousLinkedTableCells[i].rowId === changedLinkedTableCells[j].rowId &&
              previousLinkedTableCells[i].columnId === changedLinkedTableCells[j].columnId
            ) {
              deletedCellExists = true;
              break;
            }
          }
          if (deletedCellExists === false) {
            deletedTableCells.push(previousLinkedTableCells[i]);
          }
        }
      } else {
        //previous deleted
        for (let i = 0; i < previousLinkedTableCells.length; i++) {
          deletedTableCells.push(previousLinkedTableCells[i]);
        }
      }
    } else {
      //all newly added
      if (changedLinkedTableCells.length > 0) {
        for (let i = 0; i < changedLinkedTableCells.length; i++) {
          addedTableCells.push(changedLinkedTableCells[i]);
        }
      }
    }

    const editedTableCells: editedPhrases = {
      upsert: addedTableCells,
      deleted: deletedTableCells,
      bi: '',
    };
    return editedTableCells;
  };

  const mergePhrases = (
    firstEditedPhraseArray: editedPhrases,
    secondEditedPhraseArray: editedPhrases
  ) => {
    const upsertPhrases: phraseInfo[] = firstEditedPhraseArray.upsert.concat(
      secondEditedPhraseArray.upsert
    );
    const deletedPhrases: phraseInfo[] = firstEditedPhraseArray.deleted.concat(
      secondEditedPhraseArray.deleted
    );
    const biType = clauseDisplayName;
    const mergedPhrases: editedPhrases = {
      upsert: upsertPhrases,
      deleted: deletedPhrases,
      bi: biType,
    };
    return mergedPhrases;
  };

  const linkToPhrase = () => {
    dispatch(savePhraseEditOption({ phraseEditOptionSelected: true }));
    setIsAddingPhrase(true);
    setCurrentEditingPhrase(null);
    dispatch(saveDeletePhrase({ phraseDeleteStatus: false, phraseInDeleteMode: null }));
    dispatch(saveHighlightedTableCells({ savedHighlightedTableCells: null }));
    dispatch(
      saveHighlightedComponentType({
        componentType: componentType,
        editOptionSelected: true,
      })
    );
  };

  const editLinkedPhraseOnDoc = useCallback(
    (phraseInfo: phraseInfo) => {
      let deletePhraseElement = '';
      if (phraseInfo && isTableCell(phraseInfo)) {
        const tempTableCell: tableInfo = {
          paraId: phraseInfo.paraId,
          rowId: phraseInfo.rowId ? phraseInfo.rowId : -1,
          columnId: phraseInfo.columnId ? phraseInfo.columnId : -1,
        };
        deletePhraseElement =
          'p' + phraseInfo.paraId + ';r' + phraseInfo.rowId + ';c' + phraseInfo.columnId;
        dispatch(
          saveHighlightedTableCells({ savedHighlightedTableCells: [tempTableCell] })
        );
      } else {
        deletePhraseElement = 'p' + phraseInfo.paraId + ';w' + phraseInfo.startWordId;
        dispatch(saveHighlightedTableCells({ savedHighlightedTableCells: null }));
      }
      const phraseElement = document.getElementById(deletePhraseElement);
      phraseElement && phraseElement.scrollIntoView({ block: 'center' }); //scroll to linked phrase
      document.documentElement.style.scrollBehavior = 'smooth';

      dispatch(
        saveDeletePhrase({ phraseDeleteStatus: true, phraseInDeleteMode: phraseInfo })
      );
      setIsAddingPhrase(false);
      setCurrentEditingPhrase(phraseInfo);
      dispatch(
        saveHighlightedComponentType({
          componentType: componentType,
          editOptionSelected: true,
        })
      );
    },
    [componentType]
  );

  const linkPhraseOnDoc = useCallback(
    (phraseInfo?: phraseInfo | null) => {
      if (phraseInfo) {
        return (
          <Stack
            direction="row"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <Button
              sx={{
                color: currentEditingPhrase === phraseInfo ? '#C1C1C1' : '#88305F',
                padding: 0,
              }}
              onClick={() => editLinkedPhraseOnDoc(phraseInfo)}
            >
              Edit linked phrase
            </Button>
            <Typography fontSize="14px">{phraseInfo === null ? 0 : 1}</Typography>
          </Stack>
        );
      } else {
        return (
          <Stack alignItems="start" spacing={1} width="100%">
            <Button
              sx={{
                color: !isAddingPhrase
                  ? '#88305F'
                  : phraseEditOptionSelected
                    ? '#C1C1C1'
                    : '#88305F',
                padding: 0,
              }}
              onClick={() => linkToPhrase()}
            >
              Link phrase
            </Button>
          </Stack>
        );
      }
    },
    [isAddingPhrase, linkToPhrase, editLinkedPhraseOnDoc]
  );

  const addOrRemovePhrase = (action: string, phraseInfo: phraseInfo | null) => {
    if (action === 'add') {
      dispatch(saveAddPhrase({ phraseAddStatus: false, phraseInAddMode: null }));
      dispatch(saveDeletePhrase({ phraseDeleteStatus: false, phraseInDeleteMode: null }));
      dispatch(saveHighlightedTableCells({ savedHighlightedTableCells: null }));
      const tempPhrases = [...savedHighlightedPhrases];
      if (tempPhrases && phraseInfo) {
        tempPhrases.push(phraseInfo);
        dispatch(saveHighlightedPhrases({ savedHighlightedPhrases: tempPhrases }));
      } else {
        if (phraseInfo === null) {
          dispatch(saveHighlightedPhrases({ savedHighlightedPhrases: phraseInfo }));
        } else {
          dispatch(saveHighlightedPhrases({ savedHighlightedPhrases: [phraseInfo] }));
        }
      }
      setIsAddingPhrase(false);
      setCurrentEditingPhrase(null);
    } else if (action === 'remove') {
      if (phraseInfo) {
        const tempPhrases =
          savedHighlightedPhrases?.filter(
            (data: any) =>
              data.paraId !== phraseInfo.paraId ||
              data.startSentenceId !== phraseInfo.startSentenceId ||
              data.startWordId !== phraseInfo.startWordId
          ) || [];
        dispatch(
          saveHighlightedPhrases({
            savedHighlightedPhrases: tempPhrases?.length === 0 ? null : tempPhrases,
          })
        );
      } else {
        dispatch(saveHighlightedPhrases({ savedHighlightedPhrases: null }));
      }
      dispatch(saveDeletePhrase({ phraseDeleteStatus: false, phraseInDeleteMode: null }));
      dispatch(saveHighlightedTableCells({ savedHighlightedTableCells: null }));
    }
    dispatch(
      saveHighlightedComponentType({
        componentType: componentType,
        editOptionSelected: false,
      })
    );
  };

  const getPhraseEdit = useCallback(() => {
    if (savedHighlightedPhrases && savedHighlightedPhrases?.length > 0) {
      return (
        <>
          {savedHighlightedPhrases.map((phraseIter: any, index: number) => (
            <Stack key={index} width="100%" spacing={1}>
              <Stack
                direction="row"
                justifyContent="space-between"
                width="100%"
                spacing={1}
              >
                <textarea
                  rows={2}
                  cols={50}
                  name="text"
                  maxLength={50}
                  className="tag-input"
                  value={phraseIter.phrase}
                  style={{
                    minHeight: '131px',
                    lineHeight: '15px',
                    width: '80%',
                  }}
                  readOnly
                />
                <IconButton
                  sx={{ padding: 0 }}
                  onClick={() => addOrRemovePhrase('remove', phraseIter)}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </Stack>

              {linkPhraseOnDoc(phraseIter)}
            </Stack>
          ))}
          <Stack width="100%" spacing={1}>
            <Stack
              direction="row"
              justifyContent="space-between"
              width="100%"
              spacing={1}
            >
              <textarea
                rows={2}
                cols={50}
                name="text"
                maxLength={50}
                className="tag-input"
                readOnly
                value={phraseInAddMode ? phraseInAddMode.phrase : ''}
                style={{
                  minHeight: '131px',
                  lineHeight: '15px',
                  width: '80%',
                }}
              />
              <IconButton
                disabled={!phraseInAddMode}
                sx={{ padding: 0 }}
                onClick={() => addOrRemovePhrase('add', phraseInAddMode)}
              >
                <AddIcon />
              </IconButton>
            </Stack>
            {linkPhraseOnDoc(phraseInAddMode)}
          </Stack>
        </>
      );
    } else {
      return (
        <Stack spacing={1} width="100%">
          <Stack direction="row" justifyContent="space-between" width="100%" spacing={1}>
            <textarea
              rows={3}
              cols={70}
              name="text"
              maxLength={50}
              className="tag-input"
              value={phraseInAddMode ? phraseInAddMode.phrase : ''}
              style={{ minHeight: '131px', lineHeight: '15px', width: '80%' }}
            />
            <IconButton
              disabled={!phraseInAddMode}
              sx={{ padding: 0 }}
              onClick={() => addOrRemovePhrase('add', phraseInAddMode)}
            >
              <AddIcon />
            </IconButton>
          </Stack>
          {linkPhraseOnDoc(phraseInAddMode)}
        </Stack>
      );
    }
  }, [savedHighlightedPhrases, phraseInAddMode]);

  const onCancel = () => {
    onClose();
    dispatch(saveDeletePhrase({ phraseDeleteStatus: false, phraseInDeleteMode: null }));
    dispatch(saveAddPhrase({ phraseAddStatus: false, phraseInAddMode: null }));
    dispatch(saveHighlightedPhrases({ savedHighlightedPhrases: null }));

    dispatch(savePhraseEditOption({ phraseEditOptionSelected: false }));
    dispatch(
      saveHighlightedComponentType({
        componentType: '',
        editOptionSelected: false,
      })
    );
    dispatch(saveHighlightedTableCells({ savedHighlightedTableCells: null }));
  };

  const getTempPhrase = () => {
    let tempPhraseRequest: LinkPhraseRequest = {
      mode: '',
      editedPhrases: { upsert: [], deleted: [], bi: '' },
    };
    const savedPhrase = savedInsight.filter(
      (data: any) => data.value && data.value !== 'cannot_determine'
    );

    const oldPhrasesArray = getPhrasesFromChild(savedPhrase, componentType);

    const oldPhrases = filterPhrasesFromPhrasesArray(oldPhrasesArray);
    const oldTableCells = filterTableCellsFromPhraseArray(oldPhrasesArray);
    const changedPhrasesArray = savedHighlightedPhrases
      ? [...savedHighlightedPhrases]
      : [];
    if (phraseInAddMode) {
      changedPhrasesArray.push(phraseInAddMode);
    }
    let addedDeletedPhrases: editedPhrases = {
      upsert: [],
      deleted: [],
      bi: '',
    };

    const changedPhrases = filterPhrasesFromPhrasesArray(changedPhrasesArray);
    const changedTableCells = filterTableCellsFromPhraseArray(changedPhrasesArray);

    const editedPhrases = getAddedAndDeletedPhrases(oldPhrases, changedPhrases);
    const editedTableCells = getEditedTableCellPhrases(oldTableCells, changedTableCells);
    addedDeletedPhrases = mergePhrases(editedPhrases, editedTableCells);

    tempPhraseRequest = {
      editedPhrases: addedDeletedPhrases,
      mode: 'manual',
    };

    return tempPhraseRequest;
  };

  const onSave = () => {
    const tempPhraseRequest = getTempPhrase();

    let newData = updatedClauseData;

    const addedData = tempPhraseRequest.editedPhrases.upsert;
    const deletedData = tempPhraseRequest.editedPhrases.deleted;

    if (addedData.length > 0) {
      for (let i = 0; i < addedData.length; i++) {
        if (addedData[i].startSentenceId === addedData[i].endSentenceId) {
          newData = getClauseDataFormat(
            'add',
            componentType,
            addedData[i],
            newData,
            clauseName,
            sentenceData
          );
        }
      }
    }
    if (deletedData.length > 0) {
      for (let i = 0; i < deletedData.length; i++) {
        newData = getClauseDataFormat(
          'add',
          componentType,
          addedData[i],
          newData,
          clauseName,
          sentenceData
        );
      }
    }

    const diff = changesets.diff(rawContent, newData, {
      children: '$index',
    });

    if (diff.length > -1) {
      updateContract({ id, diff, updatedClauseData: newData, type: sectionType });
    }

    dispatch(saveDeletePhrase({ phraseDeleteStatus: false, phraseInDeleteMode: null }));
    dispatch(saveAddPhrase({ phraseAddStatus: false, phraseInAddMode: null }));
    dispatch(savePhraseEditOption({ phraseEditOptionSelected: false }));
    dispatch(saveHighlightedPhrases({ savedHighlightedPhrases: null }));

    dispatch(
      saveHighlightedComponentType({
        componentType: '',
        editOptionSelected: false,
      })
    );
    onClose();
  };

  return (
    <Box
      sx={{
        background: '#FFECF1',
        boxShadow: 'none',
        borderRadius: '15px',
        padding: '10px 16px',
      }}
    >
      <Typography fontWeight={600}>Add/edit {clauseDisplayName}</Typography>
      <Stack className="edit-clause-select" spacing={2}>
        <Stack spacing={2} alignItems="center" sx={{ paddingRight: '20px' }}>
          {getPhraseEdit()}
        </Stack>
        <Stack width="100%">
          <Typography fontSize="14px" fontWeight={700}>
            How to link a phrase ?
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            1). Click on `&quot;`Link Phrase`&quot;`.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            2). Hover over the text in the contract on left.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            3). Select and copy the desired phrase.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            4). Click on the copy icon.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            5). Click on the + icon besides the box where text is copied.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            6). Confirm your selection by clicking on the Save button below.
          </Typography>
        </Stack>
        <Stack direction="row">
          <LoadingButton
            variant="contained"
            startIcon={<CheckIcon />}
            onClick={onSave}
            loading={buttonLoading}
          >
            Save
          </LoadingButton>
          <Button variant="outlined" onClick={onCancel} startIcon={<CloseIcon />}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default EditPhrase;
