/* eslint-disable @typescript-eslint/no-explicit-any */
import colors from './colors';

interface variantProps {
  props: any;
  style: any;
}

interface tabProps {
  styleOverrides: any;
  variants: variantProps[];
}

const tabTheme: tabProps = {
  styleOverrides: {
    root: {
      minWidth: 'auto',
      fontSize: '14px',
      fontWeight: '500',
      padding: '0px 14px',
    },
  },
  variants: [
    {
      props: { variant: 'text' },
      style: {
        margin: '0px 5px',
      },
    },
    {
      props: { variant: 'custom' },
      style: {
        padding: '0px 14px',
        minHeight: '32px',
        '&.Mui-selected': {
          backgroundColor: colors.riPrimary?.[20],
          color: colors.riPrimary?.[500],
          borderRadius: '8px',
        },
      },
    },

    {
      props: { variant: 'contained' },
      style: {
        fontWeight: '500',
        margin: '0px 5px',
        backgroundColor: colors.riPrimary?.[20],
        borderRadius: '8px',
        '&.Mui-selected': {
          backgroundColor: colors.riPrimary?.[500],
          color: colors.riPrimary?.[10],
        },
      },
    },
  ],
};

export default tabTheme;
