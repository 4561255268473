import React, { useMemo } from 'react';

import { ArticleOutlined } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Box, Divider, Link, Stack, Toolbar, Typography } from '@mui/material';
import { useDownloadExcel } from 'react-export-table-to-excel';

import { IMenuOption } from '../../interfaces/IMenuOption';
import { RootState, useTypedSelector } from '../../redux/store';
import DropdownButton from '../DropdownButton';

const downloadMenu: IMenuOption[] = [
  {
    value: 'insights',
    label: 'Insights as Excel',
    startIcon: <ArticleOutlined />,
  },
];

interface Props {
  canDownload?: boolean;
  goBackUrl?: string;
  renderAction?: () => React.ReactNode;
}

const DraftHeader: React.FC<Props> = ({
  canDownload = false,
  renderAction,
  goBackUrl,
}) => {
  const { contract } = useTypedSelector((state: RootState) => state.contract);

  const insightsTable = document.getElementById('table-to-xls-insights');

  const fileName = useMemo(() => {
    const file_name_array = contract?.title?.split('.');
    return file_name_array?.[0] || '';
  }, [contract]);

  const { onDownload } = useDownloadExcel({
    currentTableRef: insightsTable,
    filename: `${fileName}-insights`,
    sheet: 'Insights',
  });

  const handleDownload = React.useCallback(
    async (value: string) => {
      if (value === 'insights') {
        if (insightsTable) {
          onDownload();
        }
      }
    },
    [insightsTable, onDownload]
  );

  return (
    <Box>
      <Toolbar>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
            {goBackUrl && (
              <Link
                href={goBackUrl}
                color="riPrimary.500"
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <ArrowBackIcon fontSize="small" sx={{ marginRight: '2px' }} />
                Close
              </Link>
            )}
          </Box>
          <Stack direction="row" alignItems="center" flex={1} justifyContent="center">
            <Typography
              marginRight={4}
              fontSize="16px"
              fontWeight="500"
              whiteSpace="nowrap"
            >
              {fileName}
            </Typography>
          </Stack>
          <Stack direction="row" flex={1} justifyContent="flex-end">
            {canDownload && (
              <DropdownButton
                options={downloadMenu}
                startIcon={<SaveAltIcon />}
                menuHeader="Download as:"
                buttonName="Download"
                handleDropdownChange={handleDownload}
              />
            )}
            {renderAction?.()}
          </Stack>
        </Stack>
      </Toolbar>
      <Divider />
    </Box>
  );
};

export default DraftHeader;
