/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { ChipProps, Stack } from '@mui/material';
import { Control } from 'react-hook-form';

import AutocompleteElement from '../../components/AutoComplete';
import { FormInputProps } from '../../components/interface/FormInputProps';

interface IProps extends FormInputProps {
  isMultiselect?: boolean;
  name: string;
  control: Control;
  renderCustomComponent?: (value: string, props?: ChipProps) => React.ReactElement;
  loading?: boolean;
  showCheckbox?: boolean;
  labelKey?: string;
  valueKey?: string;
  style?: any;
}

const FilterInput: React.FC<IProps> = ({
  isMultiselect = false,
  name,
  control,
  renderCustomComponent,
  loading = false,
  showCheckbox = true,
  label,
  options,
  style,
  ...props
}) => {
  return (
    <AutocompleteElement
      label={label}
      name={name}
      multiple={isMultiselect}
      options={options || []}
      matchId
      showCheckbox={showCheckbox}
      control={control}
      loading={loading}
      autocompleteProps={{
        renderTags: (value: any, getTagProps: any) =>
          renderCustomComponent ? (
            <Stack
              direction="row"
              sx={{
                overflow: 'scroll',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}
              gap={1}
            >
              {value.map((option: any, index: number) =>
                renderCustomComponent(option, { ...getTagProps({ index }) })
              )}
            </Stack>
          ) : null,
        sx: { minWidth: '250px', maxWidth: '280px', ...style },
      }}
      {...props}
    />
  );
};

export default FilterInput;
