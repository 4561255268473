/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from 'react';

import { Box, Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import LoadingInsights from './LoadingInsights';
import SectionList from './SectionList';
import SentenceLoader from './SentenceLoader';
import { WEBSOCKET_API } from '../../../Configuration/global';
import { getLocalStorage } from '../../../hooks/authentication';
import { RootState, useTypedSelector } from '../../../redux/store';

interface IProps {
  isLoading: boolean;
}

const Sections: FC<IProps> = ({ isLoading }) => {
  const queryClient = useQueryClient();
  const { id } = useParams();

  const [insightsStatus, setInsightsStatus] = useState<any>();

  const { insightsSection } = useTypedSelector((state: RootState) => state.contract);

  useEffect(() => {
    const token = getLocalStorage('accessToken');
    let ws = new WebSocket(`${WEBSOCKET_API}ws/contract_status/?token=${token}`);

    ws.onmessage = (event) => {
      const insights_status = JSON.parse(event.data)['message'];
      insights_status?.map((data: any) => {
        if (data.id === id) {
          setInsightsStatus({
            status: data.status,
            progress: parseInt(data.percentage),
          });
          if (data.percentage === 100) {
            queryClient.invalidateQueries({
              queryKey: ['sections'],
            });
          }
        }
      });
    };

    ws.onclose = () => {
      ws = new WebSocket(`${WEBSOCKET_API}ws/draft_status/?token=${token}`);
      setInsightsStatus({
        status: 'Done',
        progress: 100,
      });
    };
  }, []);

  if (!insightsStatus && isLoading) {
    return (
      <Stack style={{ justifyContent: 'center', padding: '20px' }}>
        <SentenceLoader />
      </Stack>
    );
  }
  if (
    (insightsStatus && insightsStatus.progress < 100) ||
    Object.keys(insightsSection || {}).length === 0
  ) {
    return (
      <Stack
        sx={{
          height: '90%',
          overflowY: 'auto',
          borderRadius: '24px',
          padding: '8px',
          marginTop: '8px',
        }}
        gap="5px"
      >
        <LoadingInsights
          value={parseInt(insightsStatus?.progress || 0)}
          label={`Automatically computing Insights for the draft. They will be displayed shortly.`}
        />
      </Stack>
    );
  }
  return (
    <Stack style={{ padding: '10px' }}>
      {Object.entries(insightsSection || {})?.map((section: any, key) => {
        return (
          <Box key={key}>
            <SectionList id={id} sections={section[1]} section={section[0]} />
          </Box>
        );
      })}
    </Stack>
  );
};

export default Sections;
