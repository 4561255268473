/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography } from '@mui/material';
import * as changesets from 'json-diff-ts';

import { RootState, useAppDispatch, useTypedSelector } from '../../../redux/store';
import {
  saveAddPhrase,
  saveDateEditingStatus,
  saveDeletePhrase,
  saveHighlightedComponentType,
  saveHighlightedPhrases,
  saveHighlightedTableCells,
  savePhraseEditOption,
} from '../ContractSlice';
import {
  editedPhrases,
  LinkPhraseRequest,
  phraseInfo,
  tableInfo,
} from '../State/DocumentState';
import { getClauseDataFormat } from '../Utils/ClauseTypeUtils';
import { isTableCell } from '../Utils/docUtils';

interface Props {
  clauseName: string;
  clauseDisplayName: string;
  savedInsight: any;
  onClose: VoidFunction;
  id: string;
  updateContract: any;
  updatedClauseData: any;
  clauseData?: any;
  parentClauseType?: any;
  rawContent: any;
  sectionType: string;
  buttonLoading: boolean;
}

const EditDate: React.FC<Props> = (props) => {
  const {
    rawContent,
    id,
    onClose,
    clauseDisplayName,
    updateContract,
    clauseName,
    updatedClauseData,
    sectionType,
    buttonLoading,
  } = props;

  const dispatch = useAppDispatch();

  const { sentenceData, componentType, phraseEditOptionSelected, phraseInAddMode } =
    useTypedSelector((state: RootState) => state.contract);

  const [defaultDate, setDefaultDate] = useState<string>('');
  const [isAddingPhrase, setIsAddingPhrase] = useState<boolean>(false);
  const [currentEditingPhrase, setCurrentEditingPhrase] = useState<phraseInfo | null>(
    null
  );

  useEffect(() => {
    if (phraseInAddMode) {
      setIsAddingPhrase(false);
    }
  }, [phraseInAddMode]);

  const selectDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setDefaultDate(event.currentTarget.value);
    dispatch(saveDateEditingStatus({ dateEditingStatus: false }));
  };

  const linkToPhrase = () => {
    dispatch(savePhraseEditOption({ phraseEditOptionSelected: true }));
    setIsAddingPhrase(true);
    setCurrentEditingPhrase(null);
    dispatch(saveDeletePhrase({ phraseDeleteStatus: false, phraseInDeleteMode: null }));
    dispatch(saveHighlightedTableCells({ savedHighlightedTableCells: null }));
    dispatch(
      saveHighlightedComponentType({
        componentType: componentType,
        editOptionSelected: true,
      })
    );
    dispatch(saveDateEditingStatus({ dateEditingStatus: true }));
  };

  const editLinkedPhraseOnDoc = (phraseInfo: phraseInfo) => {
    let deletePhraseElement = '';
    if (isTableCell(phraseInfo)) {
      const tempTableCell: tableInfo = {
        paraId: phraseInfo.paraId,
        rowId: phraseInfo.rowId !== null ? phraseInfo.rowId : -1,
        columnId: phraseInfo.columnId !== null ? phraseInfo.columnId : -1,
      };
      deletePhraseElement =
        'p' + phraseInfo.paraId + ';r' + phraseInfo.rowId + ';c' + phraseInfo.columnId;
      dispatch(
        saveHighlightedTableCells({ savedHighlightedTableCells: [tempTableCell] })
      );
    } else {
      deletePhraseElement = 'p' + phraseInfo.paraId + ';w' + phraseInfo.startWordId;
      dispatch(saveHighlightedTableCells({ savedHighlightedTableCells: null }));
    }
    const phraseElement = document.getElementById(deletePhraseElement);
    phraseElement !== undefined &&
      phraseElement !== null &&
      phraseElement.scrollIntoView({ block: 'center' }); //scroll to linked phrase
    document.documentElement.style.scrollBehavior = 'smooth';

    dispatch(
      saveDeletePhrase({ phraseDeleteStatus: true, phraseInDeleteMode: phraseInfo })
    );
    setIsAddingPhrase(false);
    setCurrentEditingPhrase(phraseInfo);
    dispatch(
      saveHighlightedComponentType({
        componentType: componentType,
        editOptionSelected: true,
      })
    );
    dispatch(savePhraseEditOption({ phraseEditOptionSelected: true }));
    dispatch(saveDateEditingStatus({ dateEditingStatus: true }));
  };

  const linkPhraseOnDoc = useCallback(
    (phraseInfo?: phraseInfo | null) => {
      if (phraseInfo) {
        return (
          <Stack
            direction="row"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <Button
              sx={{
                color: currentEditingPhrase === phraseInfo ? '#C1C1C1' : '#88305F',
                padding: 0,
              }}
              onClick={() => editLinkedPhraseOnDoc(phraseInfo)}
            >
              Edit linked phrase
            </Button>
            <Typography fontSize="14px">{phraseInfo === null ? 0 : 1}</Typography>
          </Stack>
        );
      } else {
        return (
          <Stack alignItems="start" spacing={1} width="100%">
            <Button
              sx={{
                color: !isAddingPhrase
                  ? '#88305F'
                  : phraseEditOptionSelected
                    ? '#C1C1C1'
                    : '#88305F',
                padding: 0,
              }}
              onClick={() => linkToPhrase()}
            >
              Link phrase
            </Button>
          </Stack>
        );
      }
    },
    [isAddingPhrase, linkToPhrase, editLinkedPhraseOnDoc]
  );

  const getStartDates = () => {
    if (phraseInAddMode && phraseInAddMode.length > 0) {
      return (
        <>
          {phraseInAddMode.map(
            (date: any, i: number) =>
              i === 0 && (
                <Stack key={i} width="100%" alignItems="start" spacing={1}>
                  <input
                    type="date"
                    className="tag-input"
                    placeholder="Enter the date"
                    onChange={(e) => selectDate(e)}
                    style={{ width: '100%' }}
                    defaultValue={defaultDate}
                  />
                  {linkPhraseOnDoc(phraseInAddMode)}
                </Stack>
              )
          )}
        </>
      );
    } else {
      return (
        <Stack alignItems="start" width="100%" spacing={1}>
          <input
            type="date"
            className="tag-input"
            placeholder="Enter text"
            onChange={(e) => selectDate(e)}
            style={{ width: '100%' }}
            defaultValue={defaultDate}
          />
          {linkPhraseOnDoc(phraseInAddMode)}
        </Stack>
      );
    }
  };

  const onCancel = () => {
    dispatch(saveAddPhrase({ phraseAddStatus: false, phraseInAddMode: null }));
    dispatch(savePhraseEditOption({ phraseEditOptionSelected: false }));
    dispatch(saveHighlightedPhrases({ savedHighlightedPhrases: null }));
    dispatch(saveDeletePhrase({ phraseDeleteStatus: false, phraseInDeleteMode: null }));
    dispatch(saveHighlightedTableCells({ savedHighlightedTableCells: null }));

    dispatch(
      saveHighlightedComponentType({
        componentType: '',
        editOptionSelected: false,
      })
    );
    setIsAddingPhrase(false);
    setCurrentEditingPhrase(null);
    setDefaultDate('');
    onClose();
    dispatch(saveDateEditingStatus({ dateEditingStatus: true }));
  };

  const getSaveStatus = () => {
    let isSavedDatesLinked = false;

    if (phraseInAddMode) {
      if (phraseInAddMode.paraId && phraseInAddMode.paraId !== -1 && defaultDate) {
        isSavedDatesLinked = true;
      }
    }
    return isSavedDatesLinked;
  };

  const getTempDate = () => {
    let tempPhraseRequest: LinkPhraseRequest = {
      mode: '',
      editedPhrases: { upsert: [], deleted: [], bi: '' },
    };
    let addedDeletedPhrases: editedPhrases = {
      upsert: [],
      deleted: [],
      bi: '',
    };
    const newPhrase: phraseInfo =
      phraseInAddMode === null
        ? {
            paraId: -1,
            startWordId: -1,
            endWordId: -1,
            startSentenceId: -1,
            endSentenceId: -1,
            rowId: -1,
            columnId: -1,
            phrase: '',
            value: -1,
            durationId: -1,
            duration: '',
          }
        : phraseInAddMode;

    addedDeletedPhrases = {
      upsert: [
        {
          paraId: newPhrase.paraId,
          startWordId: newPhrase.startWordId,
          endWordId: newPhrase.endWordId,
          startSentenceId: newPhrase.startSentenceId,
          endSentenceId: newPhrase.endSentenceId,
          rowId: newPhrase.rowId,
          columnId: newPhrase.columnId,
          phrase: newPhrase.phrase,
          value: defaultDate,
        },
      ],
      deleted: [],
      bi: clauseName,
    };

    tempPhraseRequest = {
      editedPhrases: addedDeletedPhrases,
      mode: 'manual',
    };
    return tempPhraseRequest;
  };

  const onSave = () => {
    const tempDateRequest = getTempDate();

    let newData = updatedClauseData;
    const addedData = tempDateRequest.editedPhrases.upsert;

    for (let i = 0; i < addedData.length; i++) {
      newData = getClauseDataFormat(
        'add',
        componentType,
        addedData[i],
        newData,
        clauseName,
        sentenceData
      );
    }

    const diff = changesets.diff(rawContent, newData, {
      children: '$index',
    });

    if (diff.length > -1) {
      updateContract({ id, diff, updatedClauseData: newData, type: sectionType });
    }

    dispatch(saveDeletePhrase({ phraseDeleteStatus: false, phraseInDeleteMode: null }));
    dispatch(saveAddPhrase({ phraseAddStatus: false, phraseInAddMode: null }));
    dispatch(savePhraseEditOption({ phraseEditOptionSelected: false }));
    dispatch(saveHighlightedPhrases({ savedHighlightedPhrases: null }));
    dispatch(
      saveHighlightedComponentType({
        componentType: '',
        editOptionSelected: false,
      })
    );
    dispatch(saveHighlightedTableCells({ savedHighlightedTableCells: null }));
    dispatch(saveDateEditingStatus({ dateEditingStatus: true }));
    setIsAddingPhrase(false);
    setCurrentEditingPhrase(null);
    setDefaultDate('');
    onClose();
  };

  return (
    <Box
      sx={{
        background: '#FFECF1',
        boxShadow: 'none',
        borderRadius: '15px',
        padding: '10px 16px',
      }}
    >
      <Typography fontWeight={600} mb={1}>
        Add / Edit {clauseDisplayName}
      </Typography>

      <Stack spacing={2} m="0 10px">
        {getStartDates()}
        <Stack width="100%">
          <Typography fontSize="14px" fontWeight={700}>
            How to add a {clauseDisplayName}?
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            1). Input the date using the field above.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            2). Click `&quot;`Link Phrase`&quot;` button to link a text from the contract.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            3). Hover over the text in the contract on left.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            4). Click on the desired paragraph that contains {clauseDisplayName}.
          </Typography>
          <Typography fontSize="14px" fontWeight={700}>
            5). Confirm your selection using the Save button below.
          </Typography>
        </Stack>
        <Stack direction="row">
          <LoadingButton
            variant="contained"
            type="submit"
            startIcon={<CheckIcon />}
            onClick={() => onSave()}
            disabled={!getSaveStatus()}
            loading={buttonLoading}
          >
            Save
          </LoadingButton>
          <Button variant="outlined" onClick={() => onCancel()} startIcon={<CloseIcon />}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default EditDate;
