import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, Button, Divider, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { IMenuOption } from '../interfaces/IMenuOption';

interface Props {
  options?: IMenuOption[];
  buttonName?: string;
  changeButtonText?: boolean;
  startIcon?: React.ReactElement;
  menuHeader?: string;
  setValue?: Dispatch<SetStateAction<string | undefined>>;
  handleDropdownChange?: (value: string) => void;
  disabled?: boolean;
}

const DropdownButton: React.FC<Props> = ({
  options,
  buttonName,
  changeButtonText = false,
  startIcon,
  menuHeader,
  disabled = false,
  setValue,
  handleDropdownChange,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>();
  const [buttonText, setButtonText] = useState<string | undefined>(options?.[0].label);

  useEffect(() => {
    setButtonText(buttonName);
  }, [buttonName]);

  const iconToDisplay = options
    ? changeButtonText
      ? buttonText === options[0]?.label
        ? options[0]?.startIcon
        : options[1]?.startIcon
      : startIcon
    : null; // Handle options being undefined

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (text: string, value: string) => {
    setValue?.(text);
    handleDropdownChange?.(value);
    if (changeButtonText) {
      setButtonText(text);
    }
    handleClose();
  };

  return (
    <>
      <Button
        onClick={handleClick}
        startIcon={iconToDisplay}
        endIcon={<ArrowDropDownIcon />}
        disabled={disabled}
      >
        {buttonText}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Box width="200px">
          <Typography margin={1}>{menuHeader}</Typography>
          {options?.map((option, index) => (
            <React.Fragment key={index}>
              <MenuItem
                key={option.value}
                onClick={() => handleMenuItemClick(option.label, option.value)}
              >
                {option.startIcon}
                <Typography marginLeft={2}> {option.label}</Typography>
              </MenuItem>
              {index < options.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </Box>
      </Menu>
    </>
  );
};
export default DropdownButton;
