import React from 'react';

import { Avatar, AvatarProps } from '@mui/material';

import { IObject } from '../../interfaces/IObject';

interface IProps extends AvatarProps {
  firstName: string;
  lastName: string;
  avatarStyle?: IObject;
}

const NameAvatar = ({ firstName, lastName, avatarStyle, ...props }: IProps) => {
  return (
    <Avatar
      alt={`${firstName}" "${lastName}`}
      sx={{
        width: 24,
        height: 24,
        fontSize: '10px',
        backgroundColor: 'riPrimary.500',
        ...avatarStyle,
      }}
      {...props}
    >
      {firstName?.charAt(0).toUpperCase()}
      {lastName?.charAt(0).toUpperCase()}
    </Avatar>
  );
};

export default NameAvatar;
